// Global settings for animations.
import { spring } from 'react-motion';

export const slideConfig = { stiffness: 300, damping: 25 };
export const slide = {
    atEnter: {
        transitionIndex: 0,
        translateX: 100,
        opacity: 0,
    },
    atLeave: {
        translateX: spring(-100, slideConfig),
        opacity: spring(0, slideConfig),
        transitionIndex: 2,
    },
    atActive: {
        translateX: spring(0, slideConfig),
        opacity: 1,
        transitionIndex: 1,
    },
    mapStyles: (styles) => {
        let computedStyles = {
            position: styles.transitionIndex <= 1 ? 'relative' : 'absolute',
            width: '100%',
            height: '100%',
            opacity: styles.opacity,
        };
        if (styles.translateX > 0) {
            computedStyles.transform = `translateX(${styles.translateX}%)`;
        }
        if (styles.translateY > 0) {
            computedStyles.transform = `translateY(${styles.translateY}%)`;
        }
        return computedStyles;
    },
};

export const vanishConfig = { stiffness: 250, damping: 20 };
export const vanish = {
    atEnter: {
        translateY: 50,
        opacity: 0,
    },
    atLeave: {
        translateY: spring(-40, vanishConfig),
        opacity: spring(0, vanishConfig),
    },
    atActive: {
        translateY: spring(0, vanishConfig),
        opacity: 1,
    },
    mapStyles: (styles) => {
        let computedStyles = {
            position: styles.transitionIndex <= 1 ? 'relative' : 'absolute',
            width: '100%',
            height: '100%',
            opacity: styles.opacity,
        };
        if (styles.translateY > 0) {
            computedStyles.transform = `translateY(${styles.translateY}%)`;
        }
        return computedStyles;
    },
};
