import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { kycChooseChips } from '../actions';

// Text input field for KYC
class KycText extends Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
        let { inputObj } = this.props;
        if (!inputObj.answer || inputObj.answer.length === 0) {
            let inputCopy = { ...inputObj };
            inputCopy.answer = [
                {
                    parentQuestionId: inputObj.id,
                    text: '',
                },
            ];
            this.state = {
                inputObj: inputCopy,
            };
        } else {
            this.state = {
                inputObj: inputObj,
            };
        }
    }

    validateField(event) {
        let { inputObj } = this.state;
        if (inputObj.meta.model.validation.mandatory && !event.target.value) {
            this.setState((state) => {
                state.isValid = false;
                return state;
            });
        } else if (inputObj.meta.model.validation.mandatory && event.target.value) {
            this.setState((state) => {
                state.isValid = true;
                return state;
            });
        }
    }

    handleChange(event) {
        let textVal = event.target.value;
        this.setState((state) => {
            state.inputObj.answer[0].text = textVal;
            return state;
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isValid !== nextProps.inputObj.isValid) {
            this.setState((state) => {
                state.isValid = nextProps.inputObj.isValid;
                return state;
            });
        }
    }

    componentDidMount() {
        let { inputObj } = this.props;
        const keyDown = fromEvent(this.inputField.current, 'keydown');
        const debounce = keyDown.pipe(debounceTime(1000));
        this.subscriber = debounce.subscribe((val) => {
            let option = {
                parentQuestionId: inputObj.id,
                text: this.inputField.current.value,
            };
            this.props.kycChooseChips(inputObj, option);
        });
    }

    componentWillUnmount() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    render() {
        let { inputObj, isValid } = this.state;
        let { kyc } = this.props;
        let inputClasses = () => {
            let className = 'input';
            if (typeof isValid !== 'undefined' && !isValid) {
                className += ' input--error';
            }
            if (kyc.completed) {
                className += ' input--disabled';
            }
            return className;
        };
        return (
            <div className="kyc__radio">
                <div className="form">
                    <p className="kyc__label">{inputObj.question}</p>
                    <div className={inputClasses()}>
                        <label className="input__label">
                            <input
                                disabled={kyc.completed}
                                onBlur={this.validateField.bind(this)}
                                ref={this.inputField}
                                value={inputObj.answer[0].text}
                                onChange={this.handleChange.bind(this)}
                                type={inputObj.meta.model.type.toLowerCase()}
                                placeholder="Svar"
                                className="input__field"
                            />
                            {typeof this.state.isValid !== 'undefined' && !this.state.isValid ? (
                                <div className="input__required-information">Fältet är obligatoriskt</div>
                            ) : null}
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        kyc: state.kyc,
    };
};

KycText = connect(mapStateToProps, {
    kycChooseChips,
})(KycText);

export default KycText;
