// Just a long list of objects for countries that country picker uses as a source for autocomplete search function.
const countries = [
    {
        code: 'AF',
        name: 'Afghanistan',
    },
    {
        code: 'AL',
        name: 'Albanien',
    },
    {
        code: 'DZ',
        name: 'Algeriet',
    },
    {
        code: 'VI',
        name: 'Amerikanska Jungfru\u00f6arna',
    },
    {
        code: 'AS',
        name: 'Amerikanska Samoa',
    },
    {
        code: 'AD',
        name: 'Andorra',
    },
    {
        code: 'AO',
        name: 'Angola',
    },
    {
        code: 'AI',
        name: 'Anguilla',
    },
    {
        code: 'AQ',
        name: 'Antarktis',
    },
    {
        code: 'AG',
        name: 'Antigua och Barbuda',
    },
    {
        code: 'AR',
        name: 'Argentina',
    },
    {
        code: 'AM',
        name: 'Armenien',
    },
    {
        code: 'AW',
        name: 'Aruba',
    },
    {
        code: 'AC',
        name: 'Ascension',
    },
    {
        code: 'AU',
        name: 'Australien',
    },
    {
        code: 'AZ',
        name: 'Azerbajdzjan',
    },
    {
        code: 'BS',
        name: 'Bahamas',
    },
    {
        code: 'BH',
        name: 'Bahrain',
    },
    {
        code: 'BD',
        name: 'Bangladesh',
    },
    {
        code: 'BB',
        name: 'Barbados',
    },
    {
        code: 'BE',
        name: 'Belgien',
    },
    {
        code: 'BZ',
        name: 'Belize',
    },
    {
        code: 'BJ',
        name: 'Benin',
    },
    {
        code: 'BM',
        name: 'Bermuda',
    },
    {
        code: 'BT',
        name: 'Bhutan',
    },
    {
        code: 'BO',
        name: 'Bolivia',
    },
    {
        code: 'BA',
        name: 'Bosnien och Hercegovina',
    },
    {
        code: 'BW',
        name: 'Botswana',
    },
    {
        code: 'BR',
        name: 'Brasilien',
    },
    {
        code: 'VG',
        name: 'Brittiska Jungfru\u00f6arna',
    },
    {
        code: 'IO',
        name: 'Brittiska territoriet i Indiska oceanen',
    },
    {
        code: 'BN',
        name: 'Brunei',
    },
    {
        code: 'BG',
        name: 'Bulgarien',
    },
    {
        code: 'BF',
        name: 'Burkina Faso',
    },
    {
        code: 'BI',
        name: 'Burundi',
    },
    {
        code: 'KY',
        name: 'Cayman\u00f6arna',
    },
    {
        code: 'CF',
        name: 'Centralafrikanska republiken',
    },
    {
        code: 'EA',
        name: 'Ceuta och Melilla',
    },
    {
        code: 'CL',
        name: 'Chile',
    },
    {
        code: 'CO',
        name: 'Colombia',
    },
    {
        code: 'CK',
        name: 'Cook\u00f6arna',
    },
    {
        code: 'CR',
        name: 'Costa Rica',
    },
    {
        code: 'CW',
        name: 'Cura\u00e7ao',
    },
    {
        code: 'CY',
        name: 'Cypern',
    },
    {
        code: 'DK',
        name: 'Danmark',
    },
    {
        code: 'DG',
        name: 'Diego Garcia',
    },
    {
        code: 'DJ',
        name: 'Djibouti',
    },
    {
        code: 'DM',
        name: 'Dominica',
    },
    {
        code: 'DO',
        name: 'Dominikanska republiken',
    },
    {
        code: 'EC',
        name: 'Ecuador',
    },
    {
        code: 'EG',
        name: 'Egypten',
    },
    {
        code: 'GQ',
        name: 'Ekvatorialguinea',
    },
    {
        code: 'SV',
        name: 'El Salvador',
    },
    {
        code: 'CI',
        name: 'Elfenbenskusten',
    },
    {
        code: 'ER',
        name: 'Eritrea',
    },
    {
        code: 'EE',
        name: 'Estland',
    },
    {
        code: 'ET',
        name: 'Etiopien',
    },
    {
        code: 'EZ',
        name: 'Eurozone',
    },
    {
        code: 'FK',
        name: 'Falklands\u00f6arna',
    },
    {
        code: 'FJ',
        name: 'Fiji',
    },
    {
        code: 'PH',
        name: 'Filippinerna',
    },
    {
        code: 'FI',
        name: 'Finland',
    },
    {
        code: 'FR',
        name: 'Frankrike',
    },
    {
        code: 'GF',
        name: 'Franska Guyana',
    },
    {
        code: 'PF',
        name: 'Franska Polynesien',
    },
    {
        code: 'TF',
        name: 'Franska sydterritorierna',
    },
    {
        code: 'FO',
        name: 'F\u00e4r\u00f6arna',
    },
    {
        code: 'AE',
        name: 'F\u00f6renade Arabemiraten',
    },
    {
        code: 'UN',
        name: 'F\u00f6renta Nationerna',
    },
    {
        code: 'GA',
        name: 'Gabon',
    },
    {
        code: 'GM',
        name: 'Gambia',
    },
    {
        code: 'GE',
        name: 'Georgien',
    },
    {
        code: 'GH',
        name: 'Ghana',
    },
    {
        code: 'GI',
        name: 'Gibraltar',
    },
    {
        code: 'GR',
        name: 'Grekland',
    },
    {
        code: 'GD',
        name: 'Grenada',
    },
    {
        code: 'GL',
        name: 'Gr\u00f6nland',
    },
    {
        code: 'GP',
        name: 'Guadeloupe',
    },
    {
        code: 'GU',
        name: 'Guam',
    },
    {
        code: 'GT',
        name: 'Guatemala',
    },
    {
        code: 'GG',
        name: 'Guernsey',
    },
    {
        code: 'GN',
        name: 'Guinea',
    },
    {
        code: 'GW',
        name: 'Guinea-Bissau',
    },
    {
        code: 'GY',
        name: 'Guyana',
    },
    {
        code: 'HT',
        name: 'Haiti',
    },
    {
        code: 'HN',
        name: 'Honduras',
    },
    {
        code: 'HK',
        name: 'Hongkong, S.A.R. Kina',
    },
    {
        code: 'IN',
        name: 'Indien',
    },
    {
        code: 'ID',
        name: 'Indonesien',
    },
    {
        code: 'IQ',
        name: 'Irak',
    },
    {
        code: 'IR',
        name: 'Iran',
    },
    {
        code: 'IE',
        name: 'Irland',
    },
    {
        code: 'IS',
        name: 'Island',
    },
    {
        code: 'IM',
        name: 'Isle of Man',
    },
    {
        code: 'IL',
        name: 'Israel',
    },
    {
        code: 'IT',
        name: 'Italien',
    },
    {
        code: 'JM',
        name: 'Jamaica',
    },
    {
        code: 'JP',
        name: 'Japan',
    },
    {
        code: 'YE',
        name: 'Jemen',
    },
    {
        code: 'JE',
        name: 'Jersey',
    },
    {
        code: 'JO',
        name: 'Jordanien',
    },
    {
        code: 'CX',
        name: 'Jul\u00f6n',
    },
    {
        code: 'KH',
        name: 'Kambodja',
    },
    {
        code: 'CM',
        name: 'Kamerun',
    },
    {
        code: 'CA',
        name: 'Kanada',
    },
    {
        code: 'IC',
        name: 'Kanarie\u00f6arna',
    },
    {
        code: 'CV',
        name: 'Kap Verde',
    },
    {
        code: 'BQ',
        name: 'Karibiska Nederl\u00e4nderna',
    },
    {
        code: 'KZ',
        name: 'Kazakstan',
    },
    {
        code: 'KE',
        name: 'Kenya',
    },
    {
        code: 'CN',
        name: 'Kina',
    },
    {
        code: 'KG',
        name: 'Kirgizistan',
    },
    {
        code: 'KI',
        name: 'Kiribati',
    },
    {
        code: 'CC',
        name: 'Kokos\u00f6arna',
    },
    {
        code: 'KM',
        name: 'Komorerna',
    },
    {
        code: 'CG',
        name: 'Kongo-Brazzaville',
    },
    {
        code: 'CD',
        name: 'Kongo-Kinshasa',
    },
    {
        code: 'XK',
        name: 'Kosovo',
    },
    {
        code: 'HR',
        name: 'Kroatien',
    },
    {
        code: 'CU',
        name: 'Kuba',
    },
    {
        code: 'KW',
        name: 'Kuwait',
    },
    {
        code: 'LA',
        name: 'Laos',
    },
    {
        code: 'LS',
        name: 'Lesotho',
    },
    {
        code: 'LV',
        name: 'Lettland',
    },
    {
        code: 'LB',
        name: 'Libanon',
    },
    {
        code: 'LR',
        name: 'Liberia',
    },
    {
        code: 'LY',
        name: 'Libyen',
    },
    {
        code: 'LI',
        name: 'Liechtenstein',
    },
    {
        code: 'LT',
        name: 'Litauen',
    },
    {
        code: 'LU',
        name: 'Luxemburg',
    },
    {
        code: 'MO',
        name: 'Macao, S.A.R. Kina',
    },
    {
        code: 'MG',
        name: 'Madagaskar',
    },
    {
        code: 'MK',
        name: 'Makedonien',
    },
    {
        code: 'MW',
        name: 'Malawi',
    },
    {
        code: 'MY',
        name: 'Malaysia',
    },
    {
        code: 'MV',
        name: 'Maldiverna',
    },
    {
        code: 'ML',
        name: 'Mali',
    },
    {
        code: 'MT',
        name: 'Malta',
    },
    {
        code: 'MA',
        name: 'Marocko',
    },
    {
        code: 'MH',
        name: 'Marshall\u00f6arna',
    },
    {
        code: 'MQ',
        name: 'Martinique',
    },
    {
        code: 'MR',
        name: 'Mauretanien',
    },
    {
        code: 'MU',
        name: 'Mauritius',
    },
    {
        code: 'YT',
        name: 'Mayotte',
    },
    {
        code: 'MX',
        name: 'Mexiko',
    },
    {
        code: 'FM',
        name: 'Mikronesien',
    },
    {
        code: 'MZ',
        name: 'Mo\u00e7ambique',
    },
    {
        code: 'MD',
        name: 'Moldavien',
    },
    {
        code: 'MC',
        name: 'Monaco',
    },
    {
        code: 'MN',
        name: 'Mongoliet',
    },
    {
        code: 'ME',
        name: 'Montenegro',
    },
    {
        code: 'MS',
        name: 'Montserrat',
    },
    {
        code: 'MM',
        name: 'Myanmar (Burma)',
    },
    {
        code: 'NA',
        name: 'Namibia',
    },
    {
        code: 'NR',
        name: 'Nauru',
    },
    {
        code: 'NL',
        name: 'Nederl\u00e4nderna',
    },
    {
        code: 'NP',
        name: 'Nepal',
    },
    {
        code: 'NI',
        name: 'Nicaragua',
    },
    {
        code: 'NE',
        name: 'Niger',
    },
    {
        code: 'NG',
        name: 'Nigeria',
    },
    {
        code: 'NU',
        name: 'Niue',
    },
    {
        code: 'KP',
        name: 'Nordkorea',
    },
    {
        code: 'MP',
        name: 'Nordmarianerna',
    },
    {
        code: 'NF',
        name: 'Norfolk\u00f6n',
    },
    {
        code: 'NO',
        name: 'Norge',
    },
    {
        code: 'NC',
        name: 'Nya Kaledonien',
    },
    {
        code: 'NZ',
        name: 'Nya Zeeland',
    },
    {
        code: 'OM',
        name: 'Oman',
    },
    {
        code: 'PK',
        name: 'Pakistan',
    },
    {
        code: 'PW',
        name: 'Palau',
    },
    {
        code: 'PS',
        name: 'Palestinska territorierna',
    },
    {
        code: 'PA',
        name: 'Panama',
    },
    {
        code: 'PG',
        name: 'Papua Nya Guinea',
    },
    {
        code: 'PY',
        name: 'Paraguay',
    },
    {
        code: 'PE',
        name: 'Peru',
    },
    {
        code: 'PN',
        name: 'Pitcairn\u00f6arna',
    },
    {
        code: 'PL',
        name: 'Polen',
    },
    {
        code: 'PT',
        name: 'Portugal',
    },
    {
        code: 'PR',
        name: 'Puerto Rico',
    },
    {
        code: 'QA',
        name: 'Qatar',
    },
    {
        code: 'RE',
        name: 'R\u00e9union',
    },
    {
        code: 'RO',
        name: 'Rum\u00e4nien',
    },
    {
        code: 'RW',
        name: 'Rwanda',
    },
    {
        code: 'RU',
        name: 'Ryssland',
    },
    {
        code: 'BL',
        name: 'S:t Barth\u00e9lemy',
    },
    {
        code: 'SH',
        name: 'S:t Helena',
    },
    {
        code: 'KN',
        name: 'S:t Kitts och Nevis',
    },
    {
        code: 'LC',
        name: 'S:t Lucia',
    },
    {
        code: 'PM',
        name: 'S:t Pierre och Miquelon',
    },
    {
        code: 'VC',
        name: 'S:t Vincent och Grenadinerna',
    },
    {
        code: 'MF',
        name: 'Saint-Martin',
    },
    {
        code: 'SB',
        name: 'Salomon\u00f6arna',
    },
    {
        code: 'WS',
        name: 'Samoa',
    },
    {
        code: 'SM',
        name: 'San Marino',
    },
    {
        code: 'ST',
        name: 'S\u00e3o Tom\u00e9 och Pr\u00edncipe',
    },
    {
        code: 'SA',
        name: 'Saudiarabien',
    },
    {
        code: 'CH',
        name: 'Schweiz',
    },
    {
        code: 'SN',
        name: 'Senegal',
    },
    {
        code: 'RS',
        name: 'Serbien',
    },
    {
        code: 'SC',
        name: 'Seychellerna',
    },
    {
        code: 'SL',
        name: 'Sierra Leone',
    },
    {
        code: 'SG',
        name: 'Singapore',
    },
    {
        code: 'SX',
        name: 'Sint Maarten',
    },
    {
        code: 'SK',
        name: 'Slovakien',
    },
    {
        code: 'SI',
        name: 'Slovenien',
    },
    {
        code: 'SO',
        name: 'Somalia',
    },
    {
        code: 'ES',
        name: 'Spanien',
    },
    {
        code: 'LK',
        name: 'Sri Lanka',
    },
    {
        code: 'GB',
        name: 'Storbritannien',
    },
    {
        code: 'SD',
        name: 'Sudan',
    },
    {
        code: 'SR',
        name: 'Surinam',
    },
    {
        code: 'SJ',
        name: 'Svalbard och Jan Mayen',
    },
    {
        code: 'SE',
        name: 'Sverige',
    },
    {
        code: 'SZ',
        name: 'Swaziland',
    },
    {
        code: 'ZA',
        name: 'Sydafrika',
    },
    {
        code: 'GS',
        name: 'Sydgeorgien och Sydsandwich\u00f6arna',
    },
    {
        code: 'KR',
        name: 'Sydkorea',
    },
    {
        code: 'SS',
        name: 'Sydsudan',
    },
    {
        code: 'SY',
        name: 'Syrien',
    },
    {
        code: 'TJ',
        name: 'Tadzjikistan',
    },
    {
        code: 'TW',
        name: 'Taiwan',
    },
    {
        code: 'TZ',
        name: 'Tanzania',
    },
    {
        code: 'TD',
        name: 'Tchad',
    },
    {
        code: 'TH',
        name: 'Thailand',
    },
    {
        code: 'CZ',
        name: 'Tjeckien',
    },
    {
        code: 'TG',
        name: 'Togo',
    },
    {
        code: 'TK',
        name: 'Tokelau',
    },
    {
        code: 'TO',
        name: 'Tonga',
    },
    {
        code: 'TT',
        name: 'Trinidad och Tobago',
    },
    {
        code: 'TA',
        name: 'Tristan da Cunha',
    },
    {
        code: 'TN',
        name: 'Tunisien',
    },
    {
        code: 'TR',
        name: 'Turkiet',
    },
    {
        code: 'TM',
        name: 'Turkmenistan',
    },
    {
        code: 'TC',
        name: 'Turks- och Caicos\u00f6arna',
    },
    {
        code: 'TV',
        name: 'Tuvalu',
    },
    {
        code: 'DE',
        name: 'Tyskland',
    },
    {
        code: 'UG',
        name: 'Uganda',
    },
    {
        code: 'UA',
        name: 'Ukraina',
    },
    {
        code: 'HU',
        name: 'Ungern',
    },
    {
        code: 'UY',
        name: 'Uruguay',
    },
    {
        code: 'US',
        name: 'USA',
    },
    {
        code: 'UM',
        name: 'USA:s yttre \u00f6ar',
    },
    {
        code: 'UZ',
        name: 'Uzbekistan',
    },
    {
        code: 'VU',
        name: 'Vanuatu',
    },
    {
        code: 'VA',
        name: 'Vatikanstaten',
    },
    {
        code: 'VE',
        name: 'Venezuela',
    },
    {
        code: 'VN',
        name: 'Vietnam',
    },
    {
        code: 'BY',
        name: 'Vitryssland',
    },
    {
        code: 'EH',
        name: 'V\u00e4stsahara',
    },
    {
        code: 'WF',
        name: 'Wallis- och Futuna\u00f6arna',
    },
    {
        code: 'ZM',
        name: 'Zambia',
    },
    {
        code: 'ZW',
        name: 'Zimbabwe',
    },
    {
        code: 'AX',
        name: '\u00c5land',
    },
    {
        code: 'AT',
        name: '\u00d6sterrike',
    },
    {
        code: 'TL',
        name: '\u00d6sttimor',
    },
];

export default countries;
