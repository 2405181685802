import { of, concat, interval } from 'rxjs';
import { catchError, mergeMap, map, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
    VALIDATE_ACCOUNT_POLL_START,
    VALIDATE_ACCOUNT_POLL_STOP,
    VALIDATE_ACCOUNT_CHOOSE_ACCOUNT,
    VALIDATE_ACCOUNT_GET_CHOSEN_ACCOUNT,
    validateAccountStatus,
    validateAccountPollStop,
    validateAccountFailure,
    backDoorInit,
} from '../actions';

const endpoint = process.env.REACT_APP_API_URL;

let numberOfAttempts = 0;
export const validateAccountPollEpic = (action$, store) =>
    action$.ofType(VALIDATE_ACCOUNT_POLL_START).pipe(
        mergeMap((action) => {
            // Starts a poll that asks each second for updated from backend, who in turn is waiting for thirdparty
            return concat(
                of(
                    validateAccountStatus({
                        sessionId: action.payload,
                        accounts: [],
                        error: null,
                        waitedSince: new Date(),
                        isLoading: true,
                    })
                ),
                interval(1000).pipe(
                    takeUntil(action$.ofType(VALIDATE_ACCOUNT_POLL_STOP)),
                    mergeMap((d) => {
                        numberOfAttempts++;
                        // Don't try more than 300 times, i.e. 300 seconds
                        if (numberOfAttempts > 300) {
                            numberOfAttempts = 0;
                            return concat(
                                of(validateAccountPollStop(action.payload)),
                                of(
                                    validateAccountFailure(
                                        'Vi lyckades inte hitta några bankkonton. Detta kan bero på ett tillfälligt fel i våra system, eller att vår partner inte hunnit skicka någon information till oss från er bank. Vänligen försök igen.'
                                    )
                                )
                            );
                        }
                        let sessionId = action.payload;
                        return ajax
                            .get(`${endpoint}api/bankAccount/getBankAccounts/${sessionId}`, {
                                'Content-Type': 'application/json',
                            })
                            .pipe(
                                map((data) => data.response),
                                mergeMap((accountResponse) => {
                                    if (accountResponse) {
                                        // Reset number of attempts, because we've got it! We've found the information needed.
                                        numberOfAttempts = 0;
                                        if (store.value.backDoor && store.value.backDoor.id) {
                                            return concat(
                                                of(
                                                    validateAccountStatus({
                                                        accounts: accountResponse.accounts,
                                                        missingOrganisationNumber: accountResponse.missingOrganisationNumber,
                                                        error: null,
                                                        isLoading: false,
                                                    })
                                                ),
                                                of(validateAccountPollStop(action.payload)),
                                                of(backDoorInit(store.value.backDoor.id))
                                            );
                                        }
                                        return concat(
                                            of(
                                                validateAccountStatus({
                                                    accounts: accountResponse.accounts,
                                                    missingOrganisationNumber: accountResponse.missingOrganisationNumber,
                                                    error: null,
                                                    isLoading: false,
                                                })
                                            ),
                                            of(validateAccountPollStop(action.payload))
                                        );
                                    } else {
                                        return of(
                                            validateAccountStatus({
                                                accounts: null,
                                                error: null,
                                                isLoading: true,
                                            })
                                        );
                                    }
                                }),
                                catchError((error) => {
                                    numberOfAttempts = 0;
                                    return concat(
                                        of(validateAccountPollStop(action.payload)),
                                        of(
                                            validateAccountFailure(
                                                'Kunde inte hämta data från Factoringgruppen. Ladda om sidan och försök igen. Om felet kvarstår, vänligen kontakta oss.'
                                            )
                                        )
                                    );
                                })
                            );
                    })
                )
            );
        })
    );

export const validateAccountChooseAccount = (action$, store) =>
    action$.ofType(VALIDATE_ACCOUNT_CHOOSE_ACCOUNT).pipe(
        mergeMap((action) => {
            // Tells backend what account has been chosen
            return ajax.post(`${endpoint}api/bankAccount/selectBankAccount`, action.payload.account, { 'Content-Type': 'application/json' }).pipe(
                map((data) => {
                    if (data && data.status === 202) {
                        return validateAccountStatus({
                            accounts: [],
                            missingOrganisationNumber: true,
                            error: null,
                            isLoading: false,
                        });
                    }
                    return validateAccountStatus({
                        account: action.payload.account,
                    });
                }),
                catchError((error) => {
                    return of(validateAccountFailure('Något gick fel när vi skickade information till Factoringgruppen.'));
                })
            );
        })
    );

export const validateAccountGetChosenAccount = (action$, store) =>
    action$.ofType(VALIDATE_ACCOUNT_GET_CHOSEN_ACCOUNT).pipe(
        mergeMap((action) => {
            // Ask about what account has been chosen, if any.
            return concat(
                of(
                    validateAccountStatus({
                        isLoading: true,
                    })
                ),
                ajax
                    .get(`${endpoint}api/bankAccount/getSelectedBankAccount/${action.payload.organisationNumber}/${action.payload.id}`, {
                        'Content-Type': 'application/json',
                    })
                    .pipe(
                        map((request) => request.response),
                        map((data) => {
                            return validateAccountStatus({ isLoading: false, account: data });
                        }),
                        catchError((error) => {
                            return of(validateAccountFailure('Något gick fel när vi skickade information till Factoringgruppen.'));
                        })
                    )
            );
        })
    );
