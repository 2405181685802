import { KYC_FETCH, KYC_INIT, KYC_UPDATE, KYC_COMPLETE, KYC_FETCH_FAIL } from '../actions';

const initialState = {
    questions: [],
    published: false,
    publishedAt: null,
    cultureCode: 'sv',
    isLoading: false,
    error: null,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case KYC_FETCH:
            return {
                ...initialState,
                isLoading: true,
                error: null,
            };
        case KYC_UPDATE:
        case KYC_COMPLETE:
            return {
                ...action.payload,
                isLoading: false,
                error: null,
            };
        case KYC_FETCH_FAIL:
            return {
                ...action.payload,
                isLoading: false,
                error: action.payload.error,
            };
        case KYC_INIT:
            return { ...action.payload };
        default:
            return state;
    }
}
