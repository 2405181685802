import { of, concat, interval, empty, merge } from 'rxjs';
import { switchMap, catchError, takeUntil, mergeMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { TASKS_REFRESH, TASKS_POLL, TASKS_POLL_STOP, tasksPollStart, tasksPollStop, tasksUpdate, getChosenAccount } from '../actions';

const endpoint = process.env.REACT_APP_API_URL;

export const tasksRefresh = (action$, store) =>
    action$.ofType(TASKS_REFRESH).pipe(
        switchMap((action) => {
            // Gets company tasks for a logged in user
            return concat(
                of(tasksUpdate({ isLoading: true })),
                ajax
                    .post(
                        `${endpoint}api/company/companyTasks`,
                        {
                            type: 'InvoicePurchase',
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        mergeMap((result) => {
                            if (result.response.completed) {
                                return of(tasksUpdate({ isLoading: false, ...result.response }));
                            }
                            return concat(of(tasksUpdate({ isLoading: false, ...result.response })), of(tasksPollStart()));
                        }),
                        catchError((error) => {
                            return concat(of(tasksUpdate({ isLoading: false, error: error, tasks: [] })));
                        })
                    )
            );
        })
    );

export const tasksPollEpic = (action$, store) =>
    action$.ofType(TASKS_POLL).pipe(
        mergeMap((d) => {
            // Polls for changes in company tasks in order to detect updates
            return interval(2000).pipe(
                takeUntil(merge(action$.ofType(TASKS_POLL_STOP), action$.ofType(TASKS_POLL))),
                switchMap((d) => {
                    if (!store.value.oidc.user || !store.value.organisation.organisationNumber) {
                        return of(tasksPollStop());
                    }
                    return ajax
                        .post(
                            `${endpoint}api/company/companyTasks`,
                            {
                                type: 'InvoicePurchase',
                            },
                            {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                                'organisation-number': store.value.organisation.organisationNumber,
                            }
                        )
                        .pipe(
                            mergeMap((result) => {
                                let currentTasks = store.value.tasks;
                                let updatedTasks = { ...result.response };

                                let hasChanged = (currentTasks, updatedTasks) => {
                                    let shouldContinue = false;
                                    if (currentTasks.completed !== updatedTasks.completed) {
                                        shouldContinue = true;
                                    }
                                    currentTasks.tasks.map((otask) => {
                                        updatedTasks.tasks.map((ntask) => {
                                            if (
                                                otask.id === ntask.id &&
                                                (otask.completed !== ntask.completed || otask.needsManualHandling !== ntask.needsManualHandling)
                                            ) {
                                                shouldContinue = true;
                                            }
                                            return ntask;
                                        });
                                        return otask;
                                    });

                                    return shouldContinue;
                                };

                                if (!hasChanged(currentTasks, updatedTasks)) {
                                    return empty();
                                }
                                // Keeps track of all observables to return in order to return different action depending on what's changed
                                let observablesToReturn = [of(tasksUpdate({ ...result.response }))];
                                window.dataLayer = window.dataLayer || [];
                                updatedTasks.tasks.map((newTask) => {
                                    currentTasks.tasks.map((oldTask) => {
                                        // Find and compare new task and old task
                                        if (newTask.id === oldTask.id && newTask.completed !== oldTask.completed) {
                                            // Avoid undefined errors
                                            // Build event name based on task type
                                            let eventName = `${newTask.type}Complete`;
                                            // If no gtm event has been created...
                                            if (window.dataLayer.findIndex((d) => d.event === eventName) === -1) {
                                                // Push a new event
                                                let dataLayerObj = {
                                                    event: eventName,
                                                };
                                                window.dataLayer.push(dataLayerObj);
                                            }
                                            if (oldTask.type === 'BankAccountValidation' && newTask.type === 'BankAccountValidation') {
                                                // BankAccountValidation has changed, forcefully fetch bankaccount.
                                                observablesToReturn.push(of(getChosenAccount(newTask.dataId, store.value.organisation.organisationNumber)));
                                            }
                                        }
                                        return oldTask;
                                    });

                                    return newTask;
                                });
                                // If overall completion has changed and is set to true
                                if (currentTasks.completed !== updatedTasks.completed && updatedTasks.completed) {
                                    // Tell GTM that onboarding is finished.
                                    let dataLayerObj = {
                                        event: 'OnboardingComplete',
                                    };
                                    window.dataLayer.push(dataLayerObj);
                                }

                                return concat(...observablesToReturn);
                            }),
                            catchError((error) => {
                                return of(tasksPollStop());
                            })
                        );
                }),
                catchError((error) => {
                    return of(tasksPollStop());
                })
            );
        })
    );
