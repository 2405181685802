// Components
import Login from './components/Login';
import ValidateAccount from './components/ValidateAccount';
import Kyc from './components/Kyc';
import Sign from './components/Sign';
import BackDoor from './components/BackDoor';
import StartPage from './components/StartPage';
import LoanApplication from './components/LoanApplication';
import Offerlandingpage from './components/OfferLandingpage';
import ExitView from './components/ExitView';

export const routes = [
    {
        name: '/',
        linkText: 'Logga in',
        isProtected: false,
        includeInSteps: false,
        alignment: 'left',
        size: 'half',
        isHidden: false,
        allowAnonymous: false,
        isExact: true,
        imgAlt: 'Ett tangentbord i grönska',
        imgUrlSource: require('./img/fg-office.jpg'),
        component: Login,
    },
    {
        name: '/hem',
        linkText: 'Startsida',
        isProtected: true,
        includeInSteps: true,
        alignment: 'left',
        size: 'half',
        isHidden: false,
        allowAnonymous: true,
        imgAlt: 'Ett tangentbord i grönska',
        imgUrlSource: require('./img/fg-office.jpg'),
        component: StartPage,
    },
    {
        name: '/välkommen/:id',
        linkText: '',
        isProtected: false,
        includeInSteps: false,
        alignment: 'left',
        isHidden: true,
        allowAnonymous: true,
        isExact: true,
        imgAlt: 'Ett tangentbord i grönska',
        imgUrlSource: require('./img/fg-office.jpg'),
        component: BackDoor,
    },
    {
        name: '/kontrollfrågor',
        linkText: 'Frågor',
        isProtected: true,
        includeInSteps: true,
        alignment: 'left',
        size: 'half',
        isHidden: false,
        allowAnonymous: true,
        imgAlt: 'Olika sorters controllers till TV och spelkonsoll',
        imgUrlSource: require('./img/controller.jpg'),
        thumbUrlSource: require('./img/controller-mini.jpg'),
        taskType: 'Kyc',
        component: Kyc,
    },
    {
        name: '/signera-avtal',
        linkText: 'Avtal',
        isProtected: true,
        includeInSteps: true,
        alignment: 'left',
        isHidden: false,
        allowAnonymous: true,
        isExact: true,
        imgAlt: 'En person som signerar',
        imgUrlSource: require('./img/sign.jpg'),
        thumbUrlSource: require('./img/sign-mini.jpg'),
        taskType: 'AgreementSigning',
        component: Sign,
    },
    {
        name: '/validera-konto',
        linkText: 'Utbetalningskonto',
        isProtected: true,
        includeInSteps: true,
        alignment: 'left',
        size: 'half',
        isHidden: false,
        allowAnonymous: true,
        imgAlt: 'En telefon, ett kreditkort, och ett block med penna',
        imgUrlSource: require('./img/account.jpg'),
        thumbUrlSource: require('./img/account-mini.jpg'),
        taskType: 'BankAccountValidation',
        component: ValidateAccount,
    },
    {
        name: '/lån/:promoCode?',
        linkText: 'Lån',
        isProtected: false,
        alignment: 'left',
        size: 'half',
        isHidden: false,
        includeInSteps: false,
        allowAnonymous: true,
        imgAlt: 'Lån',
        imgUrlSource: require('./img/fg-office.jpg'),
        component: LoanApplication,
    },
    {
        name: '/erbjudande',
        linkText: 'Erbjudande',
        isProtected: true,
        alignment: 'left',
        size: 'half',
        isHidden: true,
        includeInSteps: false,
        allowAnonymous: false,
        imgAlt: 'Erbjudande',
        imgUrlSource: require('./img/fg-office.jpg'),
        component: Offerlandingpage,
    },
    {
        name: '/klart',
        linkText: 'Klart',
        isProtected: true,
        alignment: 'left',
        size: 'half',
        isHidden: true,
        includeInSteps: false,
        allowAnonymous: false,
        imgAlt: 'Klart',
        imgUrlSource: require('./img/fg-office.jpg'),
        component: ExitView,
    },
];
