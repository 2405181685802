import { TASKS_UPDATE } from '../actions/index';

const initialState = {
    callbackLinkText: null,
    callbackUrl: null,
    tasks: [],
    contactDetailsSaved: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case TASKS_UPDATE:
            return { ...state, ...action.payload, contactDetailsSaved: action.payload.contactDetailsSaved };
        default:
            return { ...state };
    }
}
