import {
    FETCH_PENDING_CUSTOMER_BANK_DETAILS,
    pendingCustomerBankDetailsSaved,
    updatePendingCustomerBankDetails,
    SAVE_PENDING_CUSTOMER_BANK_DETAILS,
} from '../actions';
import { catchError, switchMap, map } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

export const fetchPendingCustomerBankDetailsEpic = (action$, store) => {
    return action$.ofType(FETCH_PENDING_CUSTOMER_BANK_DETAILS).pipe(
        switchMap((action) => {
            let { organisation } = store.value;
            let { oidc } = store.value;
            let url = `${process.env.REACT_APP_API_URL}api/company/getPendingCustomer-by-taskId`;
            let headers = {
                Accept: 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',
                'organisation-number': organisation.organisationNumber,
            };

            let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));

            if (!ssData) {
                url = `${process.env.REACT_APP_API_URL}api/company/getPendingCustomer`;
                headers = {
                    Accept: 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'organisation-number': organisation.organisationNumber,
                    Authorization: `Bearer ${oidc.user.access_token}`,
                };
            }

            return ajax
                .post(
                    url,
                    {
                        companyTaskId: action.payload.taskId,
                    },
                    headers
                )
                .pipe(
                    map((response) => {
                        return updatePendingCustomerBankDetails({ customerBankDetails: response.response });
                    }),
                    catchError(() => {})
                );
        })
    );
};

export const savePendingCustomerBankDetailsEpic = (action$, store) => {
    return action$.ofType(SAVE_PENDING_CUSTOMER_BANK_DETAILS).pipe(
        switchMap(() => {
            let { organisation, bankDetails } = store.value;
            let { oidc } = store.value;

            let url = `${process.env.REACT_APP_API_URL}api/company/addBankAccount-by-taskId`;
            let headers = {
                'Content-Type': 'application/json',
                'organisation-number': organisation.organisationNumber,
            };
            let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));

            if (!ssData) {
                url = `${process.env.REACT_APP_API_URL}api/company/addBankAccount`;
                headers = {
                    Accept: 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'organisation-number': organisation.organisationNumber,
                    Authorization: `Bearer ${oidc.user.access_token}`,
                };
            }

            return ajax
                .post(
                    url,
                    {
                        ...bankDetails,
                    },
                    headers
                )
                .pipe(
                    map((response) => {
                        return pendingCustomerBankDetailsSaved({ responseStatus: response.status });
                    }),
                    catchError((error) => {
                        return of(pendingCustomerBankDetailsSaved({ responseStatus: 500 }));
                    })
                );
        })
    );
};
