import React, { Component } from 'react';
import { connect } from 'react-redux';

import { backDoorInit } from '../actions';

import Spinner from './Spinner.js';

// this component aims to get backdoor information based on invite link param.
class BackDoor extends Component {
    constructor(props) {
        super(props);
        if (!this.props.organisation.organisationNumber && !this.props.backDoor.error) {
            this.props.backDoorInit(this.props.match.params);
        }
    }

    render() {
        return (
            <div className="content__wrapper">
                <div className="content__body content__body--half">
                    {!this.props.organisation.organisationNumber && !this.props.backDoor.error ? (
                        <Spinner />
                    ) : (
                        <React.Fragment>
                            {!this.props.backDoor.error && this.props.tasks.tasks && this.props.tasks.tasks.length === 0 ? (
                                <React.Fragment>
                                    <h1 className="title title--h1">Du verkar redan ha registrerat dig</h1>
                                    <p>
                                        Detta kan bero på att systemet inte svarar korrekt eller att du redan gemonfört din registrering på annat sätt. Om du
                                        misstänker att det är ett fel i systemet kan du höra av dig till oss så löser vi det.
                                    </p>
                                    <p>Du kan prova att ladda om sidan, kvarstår problemet kan du kontakta oss för att få en ny länk.</p>
                                </React.Fragment>
                            ) : null}
                            {this.props.backDoor.error ? (
                                <React.Fragment>
                                    <h1 className="title title--h1">Kunde inte hitta information</h1>
                                    <p>
                                        Kunde inte hitta den data som krävs för att starta flödet för att bli ny kund. Detta kan inträffa om länken du använt
                                        för att komma hit inte är korrekt, inte längre giltig eller om något gått fel i våra system.
                                    </p>
                                    <p>Du kan prova att ladda om sidan, kvarstår problemet kan du kontakta oss för att få en ny länk.</p>
                                </React.Fragment>
                            ) : null}
                        </React.Fragment>
                    )}
                </div>
                <div className="contact-info contact-info--alt">
                    <div className="contact-info__panel">
                        <p className="paragraph">
                            <strong>Organisationsnummer</strong>
                        </p>
                        <p className="paragraph">556481-1833</p>
                    </div>
                    <div className="contact-info__panel">
                        <p className="paragraph">
                            <strong>Telefon</strong>
                        </p>
                        <p className="paragraph">031-719 99 20</p>
                    </div>
                    <div className="contact-info__panel">
                        <p className="paragraph">
                            <strong>Mail</strong>
                        </p>
                        <p className="paragraph">onboarding@fg.se</p>
                    </div>
                    <div className="contact-info__panel">
                        <p className="paragraph">
                            <strong>Adress</strong>
                        </p>
                        <p className="paragraph">Factoringgruppen</p>
                        <p className="paragraph">Lyckans väg 2</p>
                        <p className="paragraph">412 56 Göteborg</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        router: state.router,
        backDoor: state.backDoor,
        tasks: state.tasks,
        organisation: state.organisation,
    };
};

BackDoor = connect(mapStateToProps, {
    backDoorInit,
})(BackDoor);

export default BackDoor;
