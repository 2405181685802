import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {
    render() {
        const { router } = this.props;
        // Won't show navigation if we are at login page.
        return router.location.pathname !== '/hem' ? (
            <div className="navigation">
                <NavLink className="navigation__back" to="/hem">
                    <img className="navigation__arrow" src={require('../img/arrow-left.svg').default} alt="pil som pekar åt vänster" /> Startsida
                </NavLink>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => {
    return {
        router: state.router,
    };
};

Navigation = connect(mapStateToProps)(Navigation);

export default Navigation;
