import { of, concat } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, switchMap, mergeMap, debounceTime, delay, finalize } from 'rxjs/operators';

import scrollTo from '../helpers/scrollTo';

import {
    KYC_FETCH,
    KYC_COMPLETE,
    KYC_UPDATE,
    KYC_PERSON_ADD,
    KYC_PERSON_REMOVE,
    KYC_PERSON_UPDATE,
    KYC_CHIPS_CHOOSE,
    KYC_CHIPS_DESELECT,
    kycInit,
    kycUpdate,
    kycFailure,
    loadingUpdate,
} from '../actions';

// Private
const endpoint = process.env.REACT_APP_API_URL;

// Creates a flattened list of questions instead of a nested one. Easier to work with.
const findAnswers = (kyc) => {
    let answers = [];
    const recFindAnswers = (questions) => {
        questions.map((q) => {
            if (q.answer && q.answer.length > 0) {
                answers = [...answers, ...q.answer];
            }
            if (q.meta.options) {
                q.meta.options.map((option) => {
                    if (option.questions && option.questions.length > 0) {
                        recFindAnswers(option.questions);
                    }
                    return option;
                });
            }

            return q;
        });
    };
    recFindAnswers(kyc.questions);

    return {
        id: kyc.id,
        isFinished: false, //This will be set to true when user chooses to click submit and the set is validated
        answers: answers,
    };
};

// Applies correct validation depending on KYC type on a single question/answer.
const validateSingleAnswer = (q, isValid) => {
    let isMandatory = q.meta.model.validation.mandatory;
    switch (q.meta.model.type) {
        case 'Text':
            if (isMandatory && q.answer[0] && !q.answer[0].text) {
                isValid = false;
                q.isValid = isValid;
            }
            break;
        case 'Person':
            if (isMandatory && q.answer.length > 0) {
                isValid = validatePerson(q);
                q.isValid = isValid;
            }
            break;
        case 'Select':
            if (isMandatory && !q.answer[0].id) {
                isValid = false;
                q.isValid = isValid;
            }
            break;
        default:
            if (isMandatory && q.answer && q.answer.length === 0) {
                isValid = false;
                q.isValid = isValid;
            }
            break;
    }

    return isValid;
};

// Applies correct validation depending on KYC type on all questions/answers.
const validateAnswers = (kyc) => {
    let isValid = true;
    const recValidateAnswers = (questions) => {
        questions.map((q) => {
            if (q.meta.options) {
                let isMandatory = q.meta.model.validation.mandatory;

                switch (q.meta.model.type) {
                    case 'Text':
                        if (isMandatory && q.answer[0] && !q.answer[0].text) {
                            isValid = false;
                            q.isValid = isValid;
                        }
                        break;
                    case 'Person':
                        if (isMandatory && q.answer.length > 0) {
                            isValid = validatePerson(q);
                            q.isValid = isValid;
                        }
                        break;
                    case 'Select':
                        if (isMandatory && !q.answer[0].id) {
                            isValid = false;
                            q.isValid = isValid;
                        }
                        break;
                    default:
                        if (isMandatory && q.answer && q.answer.length === 0) {
                            isValid = false;
                            q.isValid = isValid;
                        }
                        break;
                }

                q.meta.options.map((option) => {
                    if (option.questions && option.questions.length > 0) {
                        if (q.answer.findIndex((a) => a.id === option.id) > -1) {
                            recValidateAnswers(option.questions);
                        }
                    }
                    return option;
                });
            }
            return q;
        });
    };
    recValidateAnswers(kyc.questions);

    return isValid;
};

const validatePersonField = (isValid, option, propName) => {
    // Validate person specific information such as name, country, ssn, etc.
    if (propName === 'socialSecurityNumber') {
        let ssnRegExp = new RegExp(/^(19|20)(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/);
        isValid = isValid && ssnRegExp.test(option.person[propName]);
    } else {
        isValid = isValid && typeof option.person[propName] !== 'undefined' && option.person[propName].length > 0;
    }

    return { isValid: isValid, option: option };
};

const validatePerson = (question) => {
    let isValid = true;
    let personAnswerFields = question.meta.model.personAnswerFields;
    // Sometimes some properties are optional. This object keeps track of what's mandatory
    let shouldValidate = {
        country: personAnswerFields.askForCountry && question.meta.model.validation.mandatory,
        firstName: personAnswerFields.askForFirstName && question.meta.model.validation.mandatory,
        lastName: personAnswerFields.askForLastName && question.meta.model.validation.mandatory,
        ownershipPercentage: personAnswerFields.askForOwnershipPercentage && question.meta.model.validation.mandatory,
        socialSecurityNumber: personAnswerFields.askForSocialSecurityNumber && question.meta.model.validation.mandatory,
    };
    question.answer.map((option, index) => {
        for (let name in shouldValidate) {
            // Do this only if this field should be validated
            if (shouldValidate[name] && option.person.hasOwnProperty(name)) {
                let validation = validatePersonField(isValid, option, name);
                option = validation.option;
                isValid = isValid && validation.isValid;
            }
        }
        return option;
    });
    return isValid;
};

// this is only used for question types that allows single answer only, such as selectbox and radios.
const handleSingleAnswerChange = (question) => {
    // Create recursive function to find all answers and remove them
    const recRemoveAnswers = (questions) => {
        questions.map((q) => {
            // If there is an answer
            if ((q.answer && q.answer.length > 0) || q.answer) {
                q.answer = [];
            }
            if (q.meta.options) {
                // Remove chosen state and restart recursive function
                q.meta.options.map((option) => {
                    option.isChosen = null;
                    if (option.questions && option.questions.length > 0) {
                        recRemoveAnswers(option.questions);
                    }
                    return option;
                });
            }

            return q;
        });
    };
    // Find affected options and remove chosen state
    question.meta.options.forEach((o) => {
        o.isChosen = false;
        if (o.questions && o.questions.length > 0) {
            // Remove answers from follow-up questions
            recRemoveAnswers(o.questions);
        }
        return o;
    });
};

// Public
export const kycFetchEpic = (action$, store) => {
    return action$.ofType(KYC_FETCH).pipe(
        switchMap(() => {
            let { oidc, kyc, tasks, organisation } = store.value;
            let taskId;
            tasks.tasks.map((task) => {
                if (task.type === 'Kyc') {
                    taskId = task.dataId;
                }

                return task;
            });
            let asyncCall;
            // Fetch using access token or bid
            if (oidc.user) {
                asyncCall = ajax
                    .post(
                        `${endpoint}api/kyc/get`,
                        {
                            type: 'Kyc',
                            id: taskId,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${oidc.user.access_token}`,
                            'organisation-number': organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        map((data) => data.response),
                        map((data) => {
                            return kycInit({ ...data, isLoading: false });
                        }),
                        catchError((error) => of(kycFailure(kyc, error)))
                    );
            } else {
                asyncCall = ajax
                    .post(
                        `${endpoint}api/kyc/get-with-invitation`,
                        {
                            type: 'Kyc',
                            id: taskId,
                        },
                        {
                            'Content-Type': 'application/json',
                            'organisation-number': organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        map((data) => data.response),
                        map((data) => {
                            return kycInit({ ...data, isLoading: false });
                        }),
                        catchError((error) => of(kycFailure(kyc, error)))
                    );
            }
            return concat(of(kycInit({ isLoading: true })), of(loadingUpdate({ isLoading: true })), asyncCall, of(loadingUpdate({ isLoading: false })));
        })
    );
};

export const kycCompleteEpic = (action$, store) => {
    return action$.ofType(KYC_COMPLETE).pipe(
        switchMap(() => {
            let { oidc, kyc, tasks, organisation } = store.value;
            // We need to assure the form has been answered correctly before allowing save
            if (validateAnswers(kyc)) {
                // Find taskID
                let taskId;
                tasks.tasks.map((task) => {
                    if (task.type === 'Kyc') {
                        taskId = task.id;
                    }

                    return task;
                });
                // Single out all answers.
                let ajaxObj = findAnswers(kyc);
                ajaxObj.isFinished = true; // User has clicked submit; set as finished.
                ajaxObj.taskId = taskId;
                if (oidc.user) {
                    return ajax
                        .post(`${endpoint}api/kyc/save`, ajaxObj, {
                            'Content-Type': 'application/json',
                            'organisation-number': organisation.organisationNumber,
                            Authorization: `Bearer ${oidc.user.access_token}`,
                        })
                        .pipe(
                            map((data) => {
                                kyc.completed = true;
                                return kycInit(kyc);
                            }),
                            catchError((error) => {
                                return kycFailure(kyc, error);
                            })
                        );
                } else {
                    return ajax
                        .post(`${endpoint}api/kyc/save-with-invitation`, ajaxObj, {
                            'Content-Type': 'application/json',
                            'organisation-number': organisation.organisationNumber,
                        })
                        .pipe(
                            map((data) => {
                                kyc.completed = true;
                                return kycInit(kyc);
                            }),
                            catchError((error) => {
                                return kycFailure(kyc, error);
                            })
                        );
                }
            } else {
                // Some error was detected.
                let switchWrapper = document.querySelector('.content__main .switch-wrapper');
                let contentWrapper = document.querySelector('.content');
                if (!switchWrapper || !contentWrapper) {
                    // User navigated somewhere or KYC wasn't rendered. Re-init.
                    return of(kycInit(kyc));
                }
                let scrollEl;
                if (switchWrapper.scrollHeight > switchWrapper.clientHeight) {
                    scrollEl = switchWrapper;
                } else if (contentWrapper.scrollHeight > contentWrapper.clientHeight) {
                    scrollEl = contentWrapper;
                }
                return of(kycInit(kyc)).pipe(
                    delay(10),
                    finalize(() => {
                        if (scrollEl && scrollEl.querySelector('.input--error')) {
                            // Scroll to element that has errors.
                            let elRect = document.querySelector('.input--error').getClientRects()[0];
                            let elPos = elRect.top - elRect.height + scrollEl.scrollTop - window.innerHeight * 0.2;
                            scrollTo(scrollEl, elPos, 500);
                        }
                    })
                );
            }
        })
    );
};

// Auto save progress.
export const kycHandleUpdate = (action$, store) =>
    action$.ofType(KYC_UPDATE).pipe(
        debounceTime(500),
        mergeMap((d) => {
            let { oidc, kyc, organisation, tasks } = store.value;
            let taskId;
            tasks.tasks.map((task) => {
                if (task.type === 'Kyc') {
                    taskId = task.id;
                }
                return task;
            });
            let ajaxObj = findAnswers(kyc);
            ajaxObj.taskId = taskId;
            if (oidc.user) {
                return ajax
                    .post(`${endpoint}api/kyc/save`, ajaxObj, {
                        'Content-Type': 'application/json',
                        'organisation-number': organisation.organisationNumber,
                        Authorization: `Bearer ${oidc.user.access_token}`,
                    })
                    .pipe(
                        map((data) => {
                            return kycInit(kyc);
                        }),
                        catchError((error) => of(kycFailure(kyc, error)))
                    );
            } else {
                return ajax
                    .post(`${endpoint}api/kyc/save-with-invitation`, ajaxObj, {
                        'Content-Type': 'application/json',
                        'organisation-number': organisation.organisationNumber,
                    })
                    .pipe(
                        map((data) => {
                            return kycInit(kyc);
                        }),
                        catchError((error) => of(kycFailure(kyc, error)))
                    );
            }
        })
    );

// Find and set person object to proper value(s)
export const kycPersonUpdate = (action$, store) =>
    action$.ofType(KYC_PERSON_UPDATE).pipe(
        debounceTime(200),
        map((action) => action.payload),
        map((payload) => {
            let { inputObj, person, personIndex } = payload;
            let kyc = store.value.kyc;
            const recFindPersonObj = (questions) => {
                if (questions && questions.length > 0) {
                    questions.map((question) => {
                        if (question.id === inputObj.id) {
                            question.answer[personIndex].person = person;
                        }
                        if (question.meta.options) {
                            // Remove chosen state and restart recursive function
                            question.meta.options.map((option) => {
                                if (option.questions && option.questions.length > 0) {
                                    recFindPersonObj(option.questions);
                                }
                                return option;
                            });
                        }

                        return question;
                    });
                }
            };
            recFindPersonObj(kyc.questions);
            return kycUpdate(kyc);
        })
    );

// Create new person object in order to allow user to add another one
export const kycPersonAddEpic = (action$, store) =>
    action$.ofType(KYC_PERSON_ADD).pipe(
        map((d) => {
            let kyc = store.value.kyc;
            const recFindPersonObj = (questions) => {
                if (questions && questions.length > 0) {
                    questions.map((question) => {
                        if (question.id === d.payload.id && question.meta.model.personAnswerFields.allowMultiple) {
                            let person = {
                                country: '',
                                firstName: '',
                                lastName: '',
                                ownershipPercentage: '',
                                socialSecurityNumber: '',
                            };
                            let option = {
                                parentQuestionId: question.id,
                                person: person,
                            };
                            if (question.answer) {
                                question.answer.push(option);
                            } else {
                                question.answer = [option];
                            }
                        }
                        if (question.meta.options) {
                            // Remove chosen state and restart recursive function
                            question.meta.options.map((option) => {
                                if (option.questions && option.questions.length > 0) {
                                    recFindPersonObj(option.questions);
                                }
                                return option;
                            });
                        }

                        return question;
                    });
                }
            };
            recFindPersonObj(kyc.questions);
            return kycInit(kyc);
        })
    );

// Delete person in list of persons
export const kycPersonRemoveEpic = (action$, store) =>
    action$.ofType(KYC_PERSON_REMOVE).pipe(
        map((d) => {
            let kyc = store.value.kyc;
            const recFindPersonObj = (questions) => {
                if (questions && questions.length > 0) {
                    questions.map((question) => {
                        if (question.id === d.payload.inputObj.id && question.meta.model.personAnswerFields.allowMultiple) {
                            question.answer.splice(d.payload.index, 1);
                        }
                        if (question.meta.options) {
                            // Remove chosen state and restart recursive function
                            question.meta.options.map((option) => {
                                if (option.questions && option.questions.length > 0) {
                                    recFindPersonObj(option.questions);
                                }
                                return option;
                            });
                        }

                        return question;
                    });
                }
            };
            recFindPersonObj(kyc.questions);
            return kycUpdate(kyc);
        })
    );

export const kycChipsChoose = (action$, store) =>
    action$.ofType(KYC_CHIPS_CHOOSE).pipe(
        map((action) => action.payload),
        map((payload) => {
            let { inputObj, option } = payload;
            let optionCopy = { ...option };
            optionCopy.questions = null;
            let kyc = store.value.kyc;
            // Create a recursive function in order to find all questions
            let recKyc = (questions) => {
                if (questions && questions.length > 0) {
                    for (let question of questions) {
                        if (question.id === inputObj.id) {
                            // Update answer
                            if (
                                question.answer &&
                                question.meta.model.type !== 'Text' &&
                                question.meta.model.type !== 'Radio' &&
                                question.meta.model.type !== 'Select'
                            ) {
                                // Find index for updating existing answer
                                // .map() is used since we are searching for a modified object, which means that indexOf() cannot be used.
                                let index;
                                question.answer.map((data, i) => {
                                    if (data.id === optionCopy.id) {
                                        index = i;
                                    }
                                    return data;
                                });
                                if (typeof index !== 'undefined') {
                                    question.answer[index] = optionCopy;
                                } else {
                                    // If no index if found, this would mean that there is no matching field so we can safely push it to the array
                                    question.answer.push(optionCopy);
                                }
                            } else {
                                // No array exists, or this is a radio or text. Create new answer array with selected option
                                question.answer = [optionCopy];
                            }
                            if (question.meta.model.type === 'Radio' || question.meta.model.type === 'Select') {
                                // Handle Radio change state
                                handleSingleAnswerChange(question);
                            }
                            if (question.meta.options.length > 0 && question.meta.options.indexOf(option) > -1) {
                                question.meta.options[question.meta.options.indexOf(option)].isChosen = true;
                            }
                            question.isValid = validateSingleAnswer(question, question.answer.length > 0);
                            return;
                        }
                        // Look for questions in this obj
                        if (question.meta.options) {
                            for (let option of question.meta.options) {
                                if (option.questions) {
                                    // Question found. Go recursive again
                                    recKyc(option.questions);
                                }
                            }
                        }
                    }
                }
            };
            recKyc(kyc.questions);
            return kycUpdate(kyc);
        })
    );

export const kycChipsDeselect = (action$, store) =>
    action$.ofType(KYC_CHIPS_DESELECT).pipe(
        map((action) => action.payload),
        map((payload) => {
            let { inputObj, option } = payload;
            if (option.isFreeText) {
                option.text = '';
            }
            let kyc = store.value.kyc;
            // Create a recursive function in order to find all questions
            let recKyc = (questions) => {
                if (questions && questions.length > 0) {
                    for (let question of questions) {
                        if (question.id === inputObj.id) {
                            let answerIndex = question.answer.findIndex((a) => option.id === a.id);
                            question.answer.splice(answerIndex, 1);
                            let index;
                            question.meta.options.map((o, i) => {
                                if (option.id === o.id) {
                                    index = i;
                                }
                                return o;
                            });
                            question.meta.options[index].isChosen = false;
                        }
                        if (question.meta.options) {
                            for (let option of question.meta.options) {
                                if (option.questions) {
                                    recKyc(option.questions);
                                }
                            }
                        }
                    }
                }
            };
            recKyc(kyc.questions);
            return kycUpdate(kyc);
        })
    );
