import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalTrigger, organisationGetCommitments, organisationChooseCommitment } from '../../actions';
import Spinner from '../Spinner';
import UserHandler from '../UserHandler';
import AddContactInformation from './AddContactInformation';
import { createLoanApplication } from '../../actions';
import { SendEventToGoogleTagMngr } from '../../helpers/googleTagManager';

class ChooseCommitment extends Component {
    constructor(props) {
        super(props);
        if (props.oidc.user && props.organisation.list.length === 0) {
            props.organisationGetCommitments();
        }

        let loanapplicationString = sessionStorage.getItem('loanapplication') || '';
        if (loanapplicationString !== '') {
            let loanApplicationObj = JSON.parse(loanapplicationString);
            this.props.createLoanApplication(loanApplicationObj);
        }

        this.state = {
            anyActive: false,
            commitment: null,
        };
    }

    closeModal(event) {
        event.preventDefault();
        this.props.modalTrigger(false);
    }

    chooseCommitment(commitment, event) {
        event.preventDefault();
        this.setState({ commitment: commitment });
        if (commitment.status === 'NotYetCustomer') {
            SendEventToGoogleTagMngr('onboarding_pick_new', { organisationNumber: commitment.organisationNumber });
        } else {
            SendEventToGoogleTagMngr('onboarding_pick_existing', { organisationNumber: commitment.organisationNumber });
        }

        if (this.props.loan.isLoanApplication != null) {
            commitment.isLoanApplication = this.props.loan.isLoanApplication;
            if (commitment.isLoanApplication) {
                SendEventToGoogleTagMngr('onboarding_loan');
            }
        }
        if (
            commitment.status === 'IncompleteOnboarding' ||
            commitment.status === 'Active' ||
            commitment.status === 'NotYetCustomer' ||
            commitment.status === 'CurrentlyOnboarding' ||
            commitment.status === 'CompletedOnboarding'
        ) {
            this.props.organisationChooseCommitment({ commitment: commitment, anyActive: this.state.anyActive });
        }
    }

    componentDidMount() {
        //if backdoor link auto select organisation
        if (this.props.organisation.name !== '' || this.props.organisation.name !== null) {
            if (this.state.commitment === null) {
                this.chooseCommitment.bind(this, {
                    commitment: {
                        name: this.props.organisation.name,
                        isLoanApplication: false,
                        organisationNumber: this.props.organisation.organisationNumber,
                        organizationType: this.props.organisation.organizationType,
                        status: 'CurrentlyOnboarding',
                        productType: 'Unknown',
                    },
                    anyActive: false,
                });
            }
        }
        SendEventToGoogleTagMngr('onboarding_login');
    }

    componentDidUpdate(prevProps, prevState) {
        if (process.env.REACT_APP_ENV_STATE === 'prod') {
            if (this.props.organisation.list.length !== prevProps.organisation.list.length) {
                let anyActive = this.props.organisation.list.some((s) => s.status === 'Active' || s.status === 'CompletedOnboarding');
                this.setState((state) => {
                    state.anyActive = anyActive;
                    return state;
                });
            }
        }
    }

    setCommitmentClass(commitment) {
        let classDisabled = 'commitment-picker__item commitment-picker__item--disabled';
        let classEnabled = 'commitment-picker__item';
        if (this.state.anyActive && commitment.status === 'NotYetCustomer') {
            return classDisabled;
        }
        return commitment.status === 'NonActive' ? classDisabled : classEnabled;
    }

    render() {
        let { organisation } = this.props;
        return (
            <React.Fragment>
                {this.props.oidc.user ? (
                    <div className="commitment-picker">
                        {!this.props.loan.isLoanApplication ? <UserHandler></UserHandler> : null}
                        {organisation.showContactInfoModal ? (
                            <AddContactInformation></AddContactInformation>
                        ) : (
                            <React.Fragment>
                                {organisation.list.length > 0 ? (
                                    <React.Fragment>
                                        <h2 className="title title--h2">
                                            {this.props.loan.isLoanApplication
                                                ? 'Vilket företag vill du representera?'
                                                : 'Vilket företag vill du bli kund med?'}
                                            {organisation.chooseCommitmentIsLoading ? (
                                                <div className="commitment-picker__inline-spinner">
                                                    <Spinner />
                                                </div>
                                            ) : null}
                                        </h2>
                                        <div className="commitment-picker__list">
                                            {organisation.list.map((commitment, index) => {
                                                return (
                                                    <button
                                                        disabled={organisation.chooseCommitmentIsLoading}
                                                        className={this.setCommitmentClass(commitment)}
                                                        key={index}
                                                        onClick={this.chooseCommitment.bind(this, commitment)}
                                                    >
                                                        <div className="commitment-picker__panel">
                                                            <p className="paragraph">
                                                                <strong>{commitment.name}</strong>
                                                            </p>
                                                            <p className="caption">{commitment.organisationNumber}</p>
                                                        </div>
                                                        <div className="commitment-picker__panel">
                                                            {(() => {
                                                                switch (commitment.status) {
                                                                    case 'NotYetCustomer':
                                                                        return (
                                                                            <p className="caption">
                                                                                {this.state.anyActive
                                                                                    ? 'Kontakta oss för att bli kund med detta företag'
                                                                                    : 'Ej kund ännu'}
                                                                            </p>
                                                                        );
                                                                    case 'CompletedOnboarding':
                                                                        return <p className="caption">Har genomfört registrering</p>;
                                                                    case 'IncompleteOnboarding':
                                                                        return <p className="caption">Har genomfört registrering</p>;
                                                                    case 'CurrentlyOnboarding':
                                                                        return <p className="caption">Under registrering</p>;
                                                                    case 'NonActive':
                                                                        return <p className="caption">Är kund, ej aktiv</p>;
                                                                    case 'Active':
                                                                        return <p className="caption">Är redan kund</p>;
                                                                    case 'CantBecomeCustomer':
                                                                        return <p className="caption">Kan inte bli kund</p>;
                                                                    default:
                                                                        return <p className="caption">Ej kund ännu</p>;
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className="commitment-picker__panel">
                                                            {commitment.status === 'IncompleteOnboarding' ||
                                                            (commitment.status === 'NotYetCustomer' && !this.state.anyActive) ||
                                                            commitment.status === 'CurrentlyOnboarding' ||
                                                            commitment.status === 'CompletedOnboarding' ? (
                                                                <img
                                                                    className="commitment-picker__arrow"
                                                                    src={require('../../img/right-white.svg').default}
                                                                    alt="pil som pekar åt höger"
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    </React.Fragment>
                                ) : (!organisation.isLoading && organisation.error && organisation.error.type === 'FETCHFAIL') ||
                                  (!organisation.isLoading && organisation.list.length === 0) ? (
                                    <React.Fragment>
                                        <h2 className="title title--h2">Hm, du verkar inte vara kopplad till något företag.</h2>
                                        <p className="paragraph">
                                            Om det företag du är aktiv inom inte syns här kan det bero på att det är nystartat eller att våra system inte kunde
                                            hitta det. Kontakta{' '}
                                            <a className="link" href="mailto:onboarding@fg.se">
                                                onboarding@fg.se
                                            </a>{' '}
                                            för personlig support.
                                        </p>
                                    </React.Fragment>
                                ) : null}
                            </React.Fragment>
                        )}

                        {organisation.error && organisation.error.message && organisation.error.type === 'GENERIC' ? (
                            <p className="title title--h4">{organisation.error.message}</p>
                        ) : null}
                        {organisation.isLoading && !organisation.organisationNumber ? (
                            <React.Fragment>
                                <h2 className="title title--h2">Hämtar företag</h2>
                                <Spinner />
                            </React.Fragment>
                        ) : null}
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        validateAccount: state.validateAccount,
        organisation: state.organisation,
        oidc: state.oidc,
        loan: state.loan,
    };
};

ChooseCommitment = connect(mapStateToProps, {
    modalTrigger,
    organisationGetCommitments,
    organisationChooseCommitment,
    createLoanApplication,
})(ChooseCommitment);

export default ChooseCommitment;
