export const UPDATE_OFFER = 'UPDATE_OFFER';
export const FETCH_OFFER = 'FETCH_OFFER';
export const ADD_TURNOVER_MONTH = 'ADD_TURNOVER_MONTH';

export const updateOffer = (state) => ({
    type: UPDATE_OFFER,
    payload: state,
});

export const fetchOffer = () => ({
    type: FETCH_OFFER,
});

export const addTurnoverMonth = (turnover) => ({
    type: ADD_TURNOVER_MONTH,
    payload: turnover,
});
