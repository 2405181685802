import React, { Component } from 'react';

// The inner content of a faq
class FaqQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openedIndex: -1,
        };
    }

    showAnswer(index) {
        this.setState((state) => {
            state.openedIndex = state.openedIndex === index ? -1 : index;
            return state;
        });
    }

    render() {
        let faq = this.props.faq;
        return faq.map((question, index) => {
            return (
                <React.Fragment key={index}>
                    <div className={this.state.openedIndex === index ? 'faq__question faq__question--active' : 'faq__question'} key={question.id}>
                        <button className="faq__click-handler" onClick={this.showAnswer.bind(this, index)}>
                            <div className="paragraph paragraph--question">
                                <div
                                    dangerouslySetInnerHTML={(() => {
                                        let dangerousHtml = {
                                            __html: question.question,
                                        };
                                        return dangerousHtml;
                                    })()}
                                ></div>
                            </div>
                            <img src={require('../img/right.svg').default} alt="Pil till höger" className="faq__icon" />
                        </button>
                        {this.state.openedIndex === index ? (
                            <div className="faq__answer">
                                <div
                                    className="paragraph"
                                    dangerouslySetInnerHTML={(() => {
                                        let dangerousHtml = {
                                            __html: question.answer,
                                        };
                                        return dangerousHtml;
                                    })()}
                                ></div>
                            </div>
                        ) : null}
                    </div>
                </React.Fragment>
            );
        });
    }
}

export default FaqQuestion;
