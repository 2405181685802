import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { kycPersonAdd, kycPersonUpdate, kycPersonRemove } from '../actions';
import countries from '../helpers/countries';

// this renders person specific input fields for for KYC.
class KycPerson extends Component {
    constructor(props) {
        super(props);
        let { inputObj } = this.props;

        if (!inputObj.answer || inputObj.answer.length === 0) {
            this.props.kycPersonAdd(inputObj);
        }
        for (let option of inputObj.answer) {
            if (!option.person) {
                option.person = {};
            }
        }
        this.state = {
            countryMatches: [],
            inputObj: inputObj,
        };
        this.autocompleteWrapper = React.createRef();
        this.countrySearchInput = React.createRef();
        this.updateSubject = new Subject().pipe(debounceTime(500));
        this.updateSubject.subscribe(({ inputObj, option, index }) => {
            this.props.kycPersonUpdate(inputObj, option.person, index);
        });
        let personAnswerFields = inputObj.meta.model.personAnswerFields;

        this.shouldValidate = {
            country: personAnswerFields.askForCountry && this.state.inputObj.meta.model.validation.mandatory,
            firstName: personAnswerFields.askForFirstName && this.state.inputObj.meta.model.validation.mandatory,
            lastName: personAnswerFields.askForLastName && this.state.inputObj.meta.model.validation.mandatory,
            ownershipPercentage: personAnswerFields.askForOwnershipPercentage && this.state.inputObj.meta.model.validation.mandatory,
            socialSecurityNumber: personAnswerFields.askForSocialSecurityNumber && this.state.inputObj.meta.model.validation.mandatory,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isValid !== nextProps.inputObj.isValid) {
            this.validatePerson();
            this.setState((state) => {
                state.isValid = nextProps.inputObj.isValid;
                return state;
            });
        }
    }

    validateInput(isValid, option, name, input) {
        // Validation is a bit finicky when it comes to personal information
        if (name === 'socialSecurityNumber') {
            // is this a valid SSN?
            let ssnRegExp = new RegExp(/^(19|20)(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/);
            isValid = isValid && ssnRegExp.test(option.person[name]);
            option.person[name + 'Valid'] = ssnRegExp.test(option.person[name]);
        } else if (name === 'ownershipPercentage') {
            // Ownership percentage must be above 25% and below 100%
            if (!option.person.ownershipPercentage) {
                option.person.ownershipPercentage = undefined;
            } else if (option.person.ownershipPercentage > 100) {
                option.person.ownershipPercentage = '100';
            } else if (option.person.ownershipPercentage < 25) {
                option.person.ownershipPercentage = '25';
            }

            if (input) {
                input.value = option.person.ownershipPercentage;
            }

            isValid = isValid && option.person[name] && option.person[name].length > 0;
            option.person[name + 'Valid'] = typeof option.person[name] !== 'undefined';
        } else {
            isValid = isValid && option.person[name] && option.person[name].length > 0;
            option.person[name + 'Valid'] = typeof option.person[name] !== 'undefined' && option.person[name].length > 0;
        }

        return { isValid, option };
    }

    validateField(option, event) {
        let isValid = true;
        let name = event.currentTarget.name;
        let input = event.target;
        let index = this.state.inputObj.answer.indexOf(option);

        if (this.shouldValidate[name]) {
            let validation = this.validateInput(isValid, option, name, input);
            isValid = validation.isValid;

            this.setState((state) => {
                state.inputObj.answer[index] = option;
                return state;
            });
        }
    }

    validatePerson() {
        let { inputObj } = this.state;
        let isValid = true;

        inputObj.answer.map((option, index) => {
            for (let name in this.shouldValidate) {
                if (this.shouldValidate[name] && option.person.hasOwnProperty(name)) {
                    let validation = this.validateInput(isValid, option, name, null);
                    option = validation.option;
                    isValid = validation.isValid;
                }
            }
            return option;
        });

        this.setState((state) => {
            state.inputObj = inputObj;
            return state;
        });
        return isValid;
    }

    handlePersonAdd(event) {
        event.preventDefault();
        let { inputObj } = this.state;
        let personIsValid = this.validatePerson();
        // Make sure the persons are valid before allowing to add a new person
        if (personIsValid) {
            this.props.kycPersonAdd(inputObj);
        }
    }

    handlePersonRemove(index, event) {
        event.preventDefault();
        let { inputObj } = this.state;
        // Tell store to remove person object
        this.props.kycPersonRemove(inputObj, index);
    }

    handleChange(option, index, event) {
        event.preventDefault();
        let { inputObj } = this.state;
        if (event.target.getAttribute('name')) {
            option.person[event.target.getAttribute('name')] = event.target.value;
            this.setState((state) => {
                state.inputObj.answer[index] = option;
                return state;
            });
        }
        this.updateSubject.next({ inputObj, option, index });
    }

    handleKeyDown(event) {
        if (event.altKey || event.ctrlKey || event.metaKey || (event.keyCode !== 40 && event.keyCode !== 38)) {
            return;
        }
        if (this.autocompleteWrapper.current) {
            event.preventDefault();
            let buttons = Array.from(this.autocompleteWrapper.current.querySelectorAll('button'));
            let directionValue = 0;
            if (event.keyCode === 40) {
                // Down
                directionValue = 1;
            }
            if (event.keyCode === 38) {
                // Up
                directionValue = -1;
            }
            let checkedIndex = buttons.indexOf(document.activeElement);
            if (checkedIndex + directionValue === -1) {
                this.countrySearchInput.current.focus();
                return;
            }
            for (let button of buttons) {
                let index = buttons.indexOf(button);
                if (index === checkedIndex + directionValue) {
                    button.focus();
                    continue;
                }
            }
        }
    }

    searchCountries(person, index, event) {
        let searchString = event.target.value;

        this.setState((state, props) => {
            let filteredCountries = [];
            if (searchString) {
                let currentHit = 0;
                filteredCountries = countries.filter((country) => {
                    if (currentHit > 4) {
                        return false;
                    }
                    let matchIndex = country.name.toLowerCase().indexOf(searchString.toLowerCase());
                    if (matchIndex === 0) {
                        currentHit++;
                        return true;
                    }
                    return false;
                });
            }
            person.country = searchString;
            state.inputObj.answer[index].person = person;
            state.countryMatches = filteredCountries;
            state.optionIndex = index;
            return state;
        });
    }

    chooseCountry(country, person, index, event) {
        event.currentTarget.closest('.input').querySelector('input').value = country.name;
        this.setState((state) => {
            state.countryMatches = [];
            person.country = country.name;
            state.inputObj.answer[index].person = person;
            return state;
        });
        this.handleChange(this.state.inputObj.answer[index], index, event);
        this.countrySearchInput.current.focus();
    }

    render() {
        let { inputObj } = this.state;
        let { kyc } = this.props;
        let inputClasses = (valueIsValid) => {
            let className = 'input';
            if (typeof valueIsValid !== 'undefined' && !valueIsValid) {
                className += ' input--error';
            }
            if (kyc.completed) {
                className += ' input--disabled';
            }
            return className;
        };

        return (
            <div className="kyc__person">
                {inputObj.answer
                    ? inputObj.answer.map((option, index) => {
                          return (
                              <div key={index} className="form">
                                  {inputObj.answer.length > 1 ? (
                                      <button className="close close--small" onClick={this.handlePersonRemove.bind(this, index)} type="button">
                                          <span className="close__icon"></span>
                                      </button>
                                  ) : null}
                                  <p className="kyc__label">{inputObj.question}</p>
                                  {inputObj.meta.model.personAnswerFields.askForFirstName ? (
                                      <div className={inputClasses(option.person.firstNameValid)}>
                                          <label className="input__label" htmlFor={`firstName_${index}`}>
                                              Förnamn
                                          </label>
                                          <input
                                              autoComplete="off"
                                              disabled={kyc.completed}
                                              onBlur={this.validateField.bind(this, option)}
                                              onChange={this.handleChange.bind(this, option, index)}
                                              value={option.person.firstName || ''}
                                              name="firstName"
                                              placeholder="Förnamn"
                                              id={`firstName_${index}`}
                                              type="text"
                                              className="input__field"
                                          />
                                          {typeof option.person.firstNameValid !== 'undefined' && !option.person.firstNameValid ? (
                                              <div className="input__required-information">Fältet är obligatoriskt</div>
                                          ) : null}
                                      </div>
                                  ) : null}
                                  {inputObj.meta.model.personAnswerFields.askForLastName ? (
                                      <div className={inputClasses(option.person.lastNameValid)}>
                                          <label className="input__label" htmlFor={`lastName_${index}`}>
                                              Efternamn
                                          </label>
                                          <input
                                              autoComplete="off"
                                              disabled={kyc.completed}
                                              onBlur={this.validateField.bind(this, option)}
                                              onChange={this.handleChange.bind(this, option, index)}
                                              value={option.person.lastName || ''}
                                              name="lastName"
                                              placeholder="Efternamn"
                                              id={`lastName_${index}`}
                                              type="text"
                                              className="input__field"
                                          />
                                          {typeof option.person.lastNameValid !== 'undefined' && !option.person.lastNameValid ? (
                                              <div className="input__required-information">Fältet är obligatoriskt</div>
                                          ) : null}
                                      </div>
                                  ) : null}
                                  {inputObj.meta.model.personAnswerFields.askForSocialSecurityNumber ? (
                                      <div className={inputClasses(option.person.socialSecurityNumberValid)}>
                                          <label className="input__label" htmlFor={`socialSecurityNumber_${index}`}>
                                              Personnummer
                                          </label>
                                          <input
                                              autoComplete="off"
                                              disabled={kyc.completed}
                                              onBlur={this.validateField.bind(this, option)}
                                              onChange={this.handleChange.bind(this, option, index)}
                                              value={option.person.socialSecurityNumber || ''}
                                              name="socialSecurityNumber"
                                              placeholder="ÅÅÅÅMMDDNNNN"
                                              id={`socialSecurityNumber_${index}`}
                                              type="tel"
                                              className="input__field"
                                          />
                                          {typeof option.person.socialSecurityNumberValid !== 'undefined' && !option.person.socialSecurityNumberValid ? (
                                              <div className="input__required-information">Ange ett giltigt personnummer (ÅÅÅÅMMDDNNNN)</div>
                                          ) : null}
                                      </div>
                                  ) : null}
                                  {inputObj.meta.model.personAnswerFields.askForOwnershipPercentage ? (
                                      <div className={inputClasses(option.person.ownershipPercentageValid)}>
                                          <label className="input__label" htmlFor={`ownershipPercentage_${index}`}>
                                              Procentandel
                                          </label>
                                          <input
                                              autoComplete="off"
                                              disabled={kyc.completed}
                                              onBlur={this.validateField.bind(this, option)}
                                              onChange={this.handleChange.bind(this, option, index)}
                                              value={option.person.ownershipPercentage || ''}
                                              name="ownershipPercentage"
                                              placeholder="Procentandel"
                                              id={`ownershipPercentage_${index}`}
                                              type="number"
                                              min="25"
                                              max="100"
                                              className="input__field"
                                          />
                                          {typeof option.person.ownershipPercentageValid !== 'undefined' && !option.person.ownershipPercentageValid ? (
                                              <div className="input__required-information">Fältet är obligatoriskt</div>
                                          ) : null}
                                      </div>
                                  ) : null}
                                  {inputObj.meta.model.personAnswerFields.askForCountry ? (
                                      <div className={inputClasses(option.person.countryValid)}>
                                          <label className="input__label" htmlFor={`country_${index}`}>
                                              Hemland
                                          </label>
                                          <div className="input__field input__field--chip">
                                              <input
                                                  autoComplete="off"
                                                  disabled={kyc.completed}
                                                  onBlur={this.validateField.bind(this, option)}
                                                  ref={this.countrySearchInput}
                                                  onChange={this.searchCountries.bind(this, option.person, index)}
                                                  onKeyDown={this.handleKeyDown.bind(this)}
                                                  type="text"
                                                  className="input__field input__field--invisible"
                                                  value={option.person.country || ''}
                                                  name="country"
                                                  placeholder="Sök land"
                                                  id={`country_${index}`}
                                              />
                                          </div>
                                          {typeof option.person.countryValid !== 'undefined' && !option.person.countryValid ? (
                                              <div className="input__required-information">Fältet är obligatoriskt</div>
                                          ) : null}
                                          {this.state.countryMatches.length > 0 && this.state.optionIndex === index ? (
                                              <div className="input__autocomplete">
                                                  <ul
                                                      ref={this.autocompleteWrapper}
                                                      className="input__suggestion-list input__suggestion-list--static input__suggestion-list--buttons"
                                                  >
                                                      {this.state.countryMatches.map((country, cIndex) => {
                                                          return (
                                                              <li
                                                                  onClick={this.chooseCountry.bind(this, country, option.person, index)}
                                                                  key={cIndex}
                                                                  className="input__suggestion-item"
                                                              >
                                                                  <button
                                                                      onKeyDown={this.handleKeyDown.bind(this)}
                                                                      className="input__suggestion-text input__suggestion-text--button"
                                                                  >
                                                                      {country.name}
                                                                  </button>
                                                              </li>
                                                          );
                                                      })}
                                                  </ul>
                                              </div>
                                          ) : null}
                                      </div>
                                  ) : null}
                              </div>
                          );
                      })
                    : null}
                {inputObj.meta.model.personAnswerFields.allowMultiple && !kyc.completed ? (
                    <div className="kyc__buttons">
                        <button type="button" className="button" onClick={this.handlePersonAdd.bind(this)}>
                            Lägg till person
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        kyc: state.kyc,
    };
};

KycPerson = connect(mapStateToProps, { kycPersonAdd, kycPersonUpdate, kycPersonRemove })(KycPerson);

export default KycPerson;
