import { of, concat, empty } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { push } from 'redux-first-history';
import ChooseCommitment from '../components/modals/ChooseCommitment';

import {
    ORGANISATION_GET_COMMITMENTS,
    ORGANISATION_UPDATE,
    ORGANISATION_CHOOSE_COMMITMENTS,
    ORGANISATION_GET_TASKS,
    ORGANISATION_CREATE_ONBOARDING,
    ORGANISATION_CREATE_DYNAMICPRICING_ONBOARDING,
    ORGANISATION_ADD_CONTACT_DETAILS,
    ORGANISATION_GET_DIRECTORSHIP,
    BOOK_HELP_FROM_SUPPORT,
    organisationUpdate,
    organisationGetTasks,
    organisationGetDirectorship,
    tasksUpdate,
    tasksPollStart,
    modalTrigger,
    loadingUpdate,
    kycTrigger,
    getChosenAccount,
    agreementInit,
    bookHelpFromSupport,
} from '../actions';

const endpoint = process.env.REACT_APP_API_URL;

export const organisationGetCommitmentsEpic = (action$, store) =>
    action$.ofType(ORGANISATION_GET_COMMITMENTS).pipe(
        switchMap((action) => {
            let { oidc } = store.value;
            // Get available commitments and update loading state accordingly
            return concat(
                of(organisationUpdate({ isLoading: true })),
                ajax.getJSON(`${endpoint}api/company/getAvailableCommitments`, { Authorization: `Bearer ${oidc.user.access_token}` }).pipe(
                    map((response) => {
                        return organisationUpdate({ isLoading: false, list: response });
                    }),
                    catchError((error) => {
                        return of(organisationUpdate({ isLoading: false, error: { type: 'FETCHFAIL' }, list: [] }));
                    })
                )
            );
        })
    );

export const organisationUpdateEpic = (action$, store) =>
    action$.ofType(ORGANISATION_UPDATE).pipe(
        switchMap((action) => {
            return empty();
        })
    );

export const organisationChooseCommitmentEpic = (action$, store) =>
    action$.ofType(ORGANISATION_CHOOSE_COMMITMENTS).pipe(
        switchMap((action) => {
            let commitment = action.payload.commitment;
            let anyActive = action.payload.anyActive;

            // Set some information about what organisation was chosen in session storage for when/if user refreshes page
            sessionStorage.setItem(
                'commitment',
                JSON.stringify({
                    name: commitment.name,
                    organisationNumber: commitment.organisationNumber,
                    organizationType: commitment.organizationType,
                    isLoanApplication: commitment.isLoanApplication,
                    showContactInfoModal: commitment.contactInfo == null || commitment.isLoanApplication ? true : false,
                })
            );
            if (commitment.isLoanApplication) {
                let asyncCall = ajax
                    .post(
                        `${endpoint}api/loan/initloan`,
                        {
                            amount: store.value.loan.loanDetails.amount,
                            numberOfMonths: store.value.loan.loanDetails.numberOfMonths,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': commitment.organisationNumber,
                        }
                    )
                    .pipe(
                        switchMap((response) => {
                            return empty();
                        }),
                        catchError((error) => {
                            return empty();
                        })
                    );
                let organisationData = {
                    commitment: undefined,
                    showContactInfoModal: true,
                    chooseCommitmentIsLoading: false,
                    organisationNumber: commitment.organisationNumber,
                    organizationType: commitment.organizationType,
                    productType: commitment.productType,
                };
                let observablesToReturn = [of(organisationUpdate(organisationData)), asyncCall];
                return concat(...observablesToReturn);
            }

            if (commitment.status === 'NotYetCustomer' && !anyActive) {
                let organisationData = {
                    commitment: undefined,
                    showContactInfoModal: false,
                    chooseCommitmentIsLoading: false,
                    name: commitment.name,
                    organisationNumber: commitment.organisationNumber,
                    organizationType: commitment.organizationType,
                    productType: commitment.productType,
                };
                return concat(of(organisationUpdate(organisationData)), of(push({ pathname: '/erbjudande' }), modalTrigger(false)));
            }

            return concat(
                of(
                    organisationUpdate({
                        chooseCommitmentIsLoading: true,
                        isLoading: false,
                        name: commitment.name,
                        organisationNumber: commitment.organisationNumber,
                        organizationType: commitment.organizationType,
                        productType: commitment.productType,
                    })
                ),
                of(organisationGetTasks()),
                of(organisationUpdate({ commitment: undefined, showContactInfoModal: undefined, chooseCommitmentIsLoading: false }))
            );
        })
    );

export const organisationGetTasksEpic = (action$, store) =>
    action$.ofType(ORGANISATION_GET_TASKS).pipe(
        switchMap((action) => {
            // Each company has tasks to finish for an onboarding. These tasks in turn is used to build what is shown for a specific client when they log in.
            // When tasks are fetched, the rest of the onboarding can be initialized.
            return concat(
                of(loadingUpdate({ isLoading: true })),
                of(organisationUpdate({ isLoading: true })),
                ajax
                    .post(
                        `${endpoint}api/company/companyTasks`,
                        {
                            type: 'InvoicePurchase',
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        mergeMap((result) => {
                            let bankAccountTask = result.response.tasks.find((task) => task.type === 'BankAccountValidation');
                            let agreementBundleId;
                            result.response.tasks.map((task) => {
                                if (task.type === 'AgreementSigning' && !agreementBundleId) {
                                    agreementBundleId = task.dataId;
                                }
                                return task;
                            });
                            if (result.response) {
                                let commitment = {
                                    name: store.value.organisation.name,
                                    organizationType: store.value.organisation.organizationType,
                                    organisationNumber: store.value.organisation.organisationNumber,
                                    isLoanApplication: store.value.organisation.isLoanApplication,
                                };
                                sessionStorage.setItem('commitment', JSON.stringify(commitment));
                                let modalShouldBeActive = [false];
                                let organisationData = {
                                    isLoading: false,
                                };
                                if (!result.response.contactDetails && action.contactDetailsSaved === false) {
                                    if (!commitment.contactInfo) {
                                        modalShouldBeActive = [true, ChooseCommitment, true];
                                        organisationData = {
                                            isLoading: false,
                                            showContactInfoModal: true,
                                            commitment: commitment,
                                        };
                                    }
                                }
                                let observablesToReturn = [
                                    of(organisationUpdate(organisationData)),
                                    of(tasksUpdate(result.response)),
                                    of(tasksPollStart()),
                                    of(organisationGetDirectorship()),
                                    of(loadingUpdate({ isLoading: false })),
                                    of(agreementInit({ organisationNumber: store.value.organisation.organisationNumber, bundleId: agreementBundleId })),
                                    of(kycTrigger()),
                                ];
                                if (bankAccountTask.completed) {
                                    observablesToReturn.push(of(getChosenAccount(bankAccountTask.dataId, store.value.organisation.organisationNumber)));
                                }
                                observablesToReturn.push(of(modalTrigger(...modalShouldBeActive)));

                                return concat(...observablesToReturn);
                            } else {
                                // TODO: Handle error
                                return empty();
                            }
                        }),
                        catchError((error) => {
                            return concat(of(tasksUpdate({ isLoading: false, error: error, tasks: [] })), of(loadingUpdate({ isLoading: false })));
                        })
                    )
            );
        })
    );

export const organisationCreateOnboardingEpic = (action$, store) =>
    action$.ofType(ORGANISATION_CREATE_ONBOARDING).pipe(
        switchMap((action) => {
            // This is triggered when a new client logs in and chooses a commitment that has no tasks or onboarding available.
            return concat(
                of(organisationUpdate({ chooseCommitmentIsLoading: true })),
                ajax
                    .post(
                        `${endpoint}api/onboarding/initiate`,
                        {
                            companyName: action.payload.commitment.name,
                            type: action.payload.productType,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': action.payload.commitment.organisationNumber,
                        }
                    )
                    .pipe(
                        switchMap((response) => {
                            let commitment = action.payload.commitment;
                            return concat(
                                of(organisationUpdate({ name: commitment.name, organisationNumber: commitment.organisationNumber })),
                                of(organisationGetTasks())
                            );
                        }),
                        catchError((error) => {
                            return of(
                                organisationUpdate({
                                    isLoading: false,
                                    error: {
                                        message:
                                            'Ett fel uppstod när vi försökte att skapa ditt konto, försök gärna igen. Om problemet kvarstår, kontakta oss.',
                                        type: 'GENERIC',
                                    },
                                })
                            );
                        })
                    ),
                of(organisationUpdate({ chooseCommitmentIsLoading: false }))
            );
        })
    );

export const organisationCreateDynamicPricingOnboardingEpic = (action$, store) =>
    action$.ofType(ORGANISATION_CREATE_DYNAMICPRICING_ONBOARDING).pipe(
        switchMap((action) => {
            return concat(
                of(organisationUpdate({ addcontactDetailsLoading: true })),
                ajax
                    .post(
                        `${endpoint}api/onboarding/initiate`,
                        {
                            companyName: action.payload.commitment.name,
                            type: action.payload.productType,
                            provision: action.payload.provision,
                            limitCharge: action.payload.limitCharge,
                            volume: action.payload.volume,
                            contactName: action.payload.commitment.contactName,
                            contactEmailAddress: action.payload.commitment.contactEmailAddress,
                            contactPhoneNumber: action.payload.commitment.contactPhoneNumber,
                            promotionalCode: action.payload.commitment.promotionalCode,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': action.payload.commitment.organisationNumber,
                        }
                    )
                    .pipe(
                        switchMap((response) => {
                            let commitment = action.payload.commitment;
                            let contactDetailsSaved =
                                commitment.contactEmailAddress && commitment.contactEmailAddress && commitment.contactPhoneNumber ? true : false;

                            return concat(
                                of(
                                    organisationUpdate({
                                        showContactInfoModal: false,
                                        name: commitment.name,
                                        organisationNumber: commitment.organisationNumber,
                                        addcontactDetailsLoading: false,
                                        organizationType: commitment.organizationType
                                    })
                                ),
                                of(organisationGetTasks(contactDetailsSaved)),
                                of(modalTrigger(false)),
                                of(push({ pathname: '/hem' }))
                            );
                        }),
                        catchError((error) => {
                            let unhandledError = true;
                            if (error.response?.determinationError) {
                                unhandledError = false;
                            } else if (!error.response?.determinationError && !error.response?.passed) {
                                unhandledError = false;
                            }

                            if (unhandledError) {
                                return of(
                                    organisationUpdate({
                                        addcontactDetailsLoading: false,
                                        error: {
                                            message:
                                                'Något gick fel när era kontaktuppgifter skulle sparas. Detta kan bero på nätverksfel eller att något gick fel i våra system. Vänligen försök igen.',
                                            type: 'ADDCONTACTERROR',
                                        },
                                    })
                                );
                            }

                            return concat(
                                of(
                                    organisationUpdate({
                                        showContactInfoModal: false,
                                        addcontactDetailsLoading: false,
                                    })
                                ),
                                of(
                                    bookHelpFromSupport(
                                        action.payload.commitment.contactName,
                                        action.payload.commitment.contactEmailAddress,
                                        action.payload.commitment.contactPhoneNumber,
                                        action.payload.turnoverMonth
                                    )
                                )
                            );
                        })
                    ),
                of(organisationUpdate({ chooseCommitmentIsLoading: false }))
            );
        })
    );

export const organisationAddContactDetailsEpic = (action$, store) =>
    action$.ofType(ORGANISATION_ADD_CONTACT_DETAILS).pipe(
        switchMap((action) => {
            // Saves contact information that user has put in a form in a modal.
            const contactDetails = {
                name: action.payload.contactName,
                emailAddress: action.payload.contactEmailAddress,
                phoneNumber: action.payload.contactPhoneNumber,
                promotionalCode: action.payload.promotionalCode,
            };
            return concat(
                of(organisationUpdate({ addcontactDetailsLoading: true })),
                ajax
                    .post(`${endpoint}api/onboarding/addcontactdetails`, contactDetails, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((response) => {
                            return concat(of(tasksUpdate({ contactDetails: contactDetails })), of(modalTrigger(false)));
                        })
                    ),
                of(organisationUpdate({ showContactInfoModal: undefined, addcontactDetailsLoading: undefined })),
                of(modalTrigger(false)),
                of(push({ pathname: '/hem' }))
            );
        }),
        catchError((error) => {
            return of(
                organisationUpdate({
                    addcontactDetailsLoading: false,
                    error: {
                        message:
                            'Något gick fel när era kontaktuppgifter skulle sparas. Detta kan bero på nätverksfel eller att något gick fel i våra system. Vänligen försök igen.',
                        type: 'ADDCONTACTERROR',
                    },
                })
            );
        })
    );

export const organisationGetDirectorshipEpic = (action$, store) =>
    action$.ofType(ORGANISATION_GET_DIRECTORSHIP).pipe(
        switchMap((action) => {
            // This call to backend will answer who, or whom, is granted to sign for an organisation.
            let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
            if (ssData && ssData.bid) {
                return ajax
                    .get(`${endpoint}api/company/getDirectorship/${ssData.bid}`, {
                        'Content-Type': 'application/json',
                        invitationToken: `${ssData.invitationToken}`,
                    })
                    .pipe(
                        map((response) => response.response),
                        switchMap((response) => {
                            return concat(of(organisationUpdate({ directorship: response })));
                        }),
                        catchError((error) => {
                            return of(
                                organisationUpdate({
                                    isLoading: false,
                                    error: {
                                        message:
                                            'Ett fel uppstod när vi försökte att skapa ditt konto, försök gärna igen. Om problemet kvarstår, kontakta oss.',
                                        type: 'GENERIC',
                                    },
                                })
                            );
                        })
                    );
            }
            if (store.value.oidc) {
                return ajax
                    .get(`${endpoint}api/company/getDirectorship`, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((response) => response.response),
                        switchMap((response) => {
                            return concat(of(organisationUpdate({ directorship: response })));
                        }),
                        catchError((error) => {
                            return of(
                                organisationUpdate({
                                    isLoading: false,
                                    error: {
                                        message:
                                            'Ett fel uppstod när vi försökte att skapa ditt konto, försök gärna igen. Om problemet kvarstår, kontakta oss.',
                                        type: 'GENERIC',
                                    },
                                })
                            );
                        })
                    );
            }
        })
    );

export const organisationBookHelpFromSupportEpic = (action$, store) =>
    action$.ofType(BOOK_HELP_FROM_SUPPORT).pipe(
        switchMap((action) => {
            const contactDetails = {
                name: action.payload.contactName,
                emailAddress: action.payload.contactEmailAddress,
                phoneNumber: action.payload.contactPhoneNumber,
                volume: action.payload.volume,
            };
            return concat(
                of(organisationUpdate({ addcontactDetailsLoading: true })),
                ajax
                    .post(`${endpoint}api/onboarding/SalesContact`, contactDetails, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((response) => {
                            return concat(
                                of(organisationUpdate({ addcontactDetailsLoading: false, showContactInfoModal: false })),
                                of(modalTrigger(false)),
                                of(push({ pathname: '/klart' }))
                            );
                        })
                    )
            );
        }),
        catchError((error) => {
            return of(
                organisationUpdate({
                    addcontactDetailsLoading: false,
                    error: {
                        message:
                            'Något gick fel när era kontaktuppgifter skulle sparas. Detta kan bero på nätverksfel eller att något gick fel i våra system. Vänligen försök igen.',
                        type: 'ADDCONTACTERROR',
                    },
                })
            );
        })
    );
