import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userManager } from '../store';
import { AccountValidation, AccountTypes, FlowType } from '@factoringgruppen/fg.ui.components';
import { loginStatus } from '../actions';

class ValidateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = { bearer: '', ssn: '', invitationId: '' };
    }

    componentDidMount() {
        let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
        if (ssData) {
            this.setState((state) => {
                state.invitationId = ssData.bid;
            });
        } else {
            userManager.getUser(userManager.settings.id).then((u) =>
                this.setState((state) => {
                    state.bearer = u.access_token;
                })
            );
        }
    }

    orgnumberAsSsn() {
        let year = this.props.organisation.organisationNumber.substring(0, 2);
        let yearInt = parseInt(year);
        let century = yearInt >= 23 ? '19' : '20';
        let fullssn = century + this.props.organisation.organisationNumber;
        return fullssn;
    }

    render() {
        return (
            <div className="content__body">
                {this.props.organisation.organisationNumber && (this.state.bearer || this.state.invitationId) ? (
                    <AccountValidation
                        props={{
                            orgNum: this.props.organisation.organisationNumber,
                            environment: {
                                environmentName: process.env.REACT_APP_ENV_STATE,
                                token: process.env.REACT_APP_STRAPI_TOKEN,
                            },
                            ssn: this.props.oidc.user?.profile?.sub ?? this.props.organisation.organizationType === 5 ? this.orgnumberAsSsn() : '',
                            initiatingPersonName: this.props.oidc.user?.profile?.name,
                            clientId: process.env.REACT_APP_FINSHARK_CLIENT_ID,
                            bearerToken: this.state.bearer,
                            invitationId: this.state.invitationId,
                            accountType: this.props.organisation.organizationType === 5 ? AccountTypes.Personal : AccountTypes.Business,
                            type: FlowType.AccountVerification,
                            styles: { centerText: false },
                            showNextStepButton: false,
                        }}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        organisation: state.organisation,
        oidc: state.oidc,
    };
};

ValidateAccount = connect(mapStateToProps, { loginStatus })(ValidateAccount);

export default ValidateAccount;
