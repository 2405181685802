import React from 'react';
import { connect } from 'react-redux';

// The image that is rendered on the left side of each page
let RouteImage = ({ imageUrlSource, extraClass, oidc, imgAlt }) => {
    return (
        <div className="content__background">
            <div className="content__background-img" style={{ backgroundImage: 'url(' + imageUrlSource + ')' }} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        kyc: state.kyc,
        oidc: state.oidc,
        router: state.router,
        backDoor: state.backDoor,
        loading: state.loading,
        tasks: state.tasks,
        agreement: state.agreement,
    };
};

RouteImage = connect(mapStateToProps)(RouteImage);

export default RouteImage;
