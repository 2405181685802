import { of, concat, interval, empty, merge } from 'rxjs';
import { catchError, takeUntil, map, mergeMap, switchMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
    BACKDOOR_INIT,
    BACKDOOR_REFRESH,
    BACKDOOR_POLL_STOP,
    BACKDOOR_POLL,
    backDoorPollStop,
    backDoorPollStart,
    backDoorUpdate,
    organisationUpdate,
    organisationGetDirectorship,
    backDoorFail,
    loadingUpdate,
    tasksUpdate,
    getChosenAccount,
} from '../actions';

const partnerUrl = process.env.REACT_APP_API_URL;

export const backDoorInitEpic = (action$, store) =>
    action$.ofType(BACKDOOR_INIT).pipe(
        map((action) => action.payload),
        mergeMap((payload) => {
            // Finds all data necessary for continuing onboarding
            let asyncCall = ajax.get(`${partnerUrl}api/company/companyTasks/${payload.id}`, { 'Content-Type': 'application/json' }).pipe(
                map((ajaxResponse) => ajaxResponse.response),
                mergeMap((result) => {
                    let ssData = {
                        bid: payload.id,
                        org: result.organisationNumber,
                        name: result.companyName,
                        organisationNumber: result.organisationNumber,
                        organizationType: result.organizationType,
                        productType: result.productType,
                        invitationToken: result.invitationToken,
                        date: new Date().getTime(),
                    };

                    sessionStorage.setItem('bidInfo', JSON.stringify(ssData));
                    let organisationData = {
                        name: result.companyName,
                        organisationNumber: result.organisationNumber,
                        organizationType: result.organizationType,
                        productType: result.productType,
                        isLoading: false,
                        overrideProtectedRoutes: true,
                        showContactInfoModal: result.contactDetails == null && result.productType === 'Factoringgruppen',
                    };
                    return concat(
                        of(tasksUpdate(result)),
                        of(organisationGetDirectorship()),
                        of(backDoorUpdate({ ...organisationData })),
                        of(organisationUpdate({ ...organisationData }))
                    );
                }),
                catchError((error) => {
                    return of(backDoorFail(error));
                })
            );
            return concat(of(loadingUpdate({ isLoading: true })), asyncCall, of(loadingUpdate({ isLoading: false })), of(backDoorPollStart()));
        })
    );

export const backDoorRefreshEpic = (action$, store) =>
    action$.ofType(BACKDOOR_REFRESH).pipe(
        map((action) => action.payload),
        mergeMap((payload) => {
            // For when we need to refresh bid information
            let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
            let asyncCall = ajax
                .get(`${partnerUrl}api/company/companyTasks/${ssData.bid}`, {
                    'Content-Type': 'application/json',
                    invitationToken: `${ssData.invitationToken}`,
                })
                .pipe(
                    map((ajaxResponse) => ajaxResponse.response),
                    mergeMap((result) => {
                        return of(tasksUpdate(result));
                    }),
                    catchError((error) => {
                        return of(backDoorFail(error));
                    })
                );
            return concat(of(tasksUpdate({ isLoading: true })), asyncCall, of(tasksUpdate({ isLoading: false })));
        })
    );

// Polls updates in company tasks
export const backDoorPollEpic = (action$, store) =>
    action$.ofType(BACKDOOR_POLL).pipe(
        mergeMap((d) => {
            return interval(2000).pipe(
                takeUntil(merge(action$.ofType(BACKDOOR_POLL_STOP), action$.ofType(BACKDOOR_POLL))),
                switchMap((d) => {
                    let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
                    if (!ssData.bid) {
                        return of(backDoorPollStop());
                    }
                    return ajax
                        .get(`${partnerUrl}api/company/companyTasks/${ssData.bid}`, {
                            'Content-Type': 'application/json',
                            invitationToken: `${ssData.invitationToken}`,
                        })
                        .pipe(
                            mergeMap((result) => {
                                let currentTasks = store.value.tasks;
                                let updatedTasks = { ...result.response };

                                let hasChanged = (currentTasks, updatedTasks) => {
                                    let shouldContinue = false;
                                    if (currentTasks.completed !== updatedTasks.completed) {
                                        shouldContinue = true;
                                    }
                                    currentTasks.tasks.map((otask) => {
                                        updatedTasks.tasks.map((ntask) => {
                                            if (otask.id === ntask.id && otask.completed !== ntask.completed) {
                                                shouldContinue = true;
                                            }
                                            return ntask;
                                        });
                                        return otask;
                                    });

                                    return shouldContinue;
                                };

                                if (!hasChanged(currentTasks, updatedTasks)) {
                                    return empty();
                                }
                                // Keeps track of all observables to return in order to return different action depending on what's changed
                                let observablesToReturn = [of(tasksUpdate({ ...result.response }))];
                                window.dataLayer = window.dataLayer || [];
                                updatedTasks.tasks.map((newTask) => {
                                    currentTasks.tasks.map((oldTask) => {
                                        // Find and compare new task and old task
                                        if (newTask.id === oldTask.id && newTask.completed !== oldTask.completed) {
                                            // Avoid undefined errors
                                            // Build event name based on task type
                                            let eventName = `${newTask.type}Complete`;
                                            // If no gtm event has been created...
                                            if (window.dataLayer.findIndex((d) => d.event === eventName) === -1) {
                                                // Push a new event
                                                let dataLayerObj = {
                                                    event: eventName,
                                                };
                                                window.dataLayer.push(dataLayerObj);
                                            }
                                            if (oldTask.type === 'BankAccountValidation' && newTask.type === 'BankAccountValidation') {
                                                // BankAccountValidation has changed, forcefully fetch bankaccount.
                                                observablesToReturn.push(of(getChosenAccount(newTask.dataId, store.value.organisation.organisationNumber)));
                                            }
                                        }
                                        return oldTask;
                                    });

                                    return newTask;
                                });
                                // If overall completion has changed and is set to true
                                if (currentTasks.completed !== updatedTasks.completed && updatedTasks.completed) {
                                    // Tell GTM that onboarding is finished.
                                    let dataLayerObj = {
                                        event: 'OnboardingComplete',
                                    };
                                    window.dataLayer.push(dataLayerObj);
                                }

                                return concat(...observablesToReturn);
                            }),
                            catchError((error) => {
                                return of(backDoorPollStop());
                            })
                        );
                }),
                catchError((error) => {
                    return of(backDoorPollStop());
                })
            );
        })
    );
