export const LOGIN_TRIGGER = 'LOGIN_TRIGGER';
export const LOGIN_STATUS = 'LOGIN_STATUS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_POLL = 'LOGIN_POLL';
export const LOGIN_POLL_STOP = 'LOGIN_POLL_STOP';
export const LOGIN_CANCEL = 'LOGIN_CANCEL';

export const loginTrigger = (ssn, bankIdOnThisDevice) => ({
    type: LOGIN_TRIGGER,
    payload: {
        ssn,
        bankIdOnThisDevice,
    },
});

export const loginStatus = (status) => ({
    type: LOGIN_STATUS,
    payload: status,
});

export const loginSuccess = (status) => ({
    type: LOGIN_SUCCESS,
    payload: status,
});

export const loginFailure = (status) => ({
    type: LOGIN_FAIL,
    payload: status,
});

export const loginPoll = (id, type) => ({
    type: LOGIN_POLL,
    payload: {
        transactionId: id,
        transactionType: type,
    },
});

export const loginPollStop = (id, type) => ({
    type: LOGIN_POLL_STOP,
    payload: {
        transactionId: id,
        transactionType: type,
    },
});

export const loginCancel = (id, type) => ({
    type: LOGIN_CANCEL,
    payload: {
        transactionId: id,
        transactionType: type,
    },
});
