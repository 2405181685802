import { combineEpics } from 'redux-observable';
import { modalEpic } from './modalEpic';

import { backDoorInitEpic, backDoorRefreshEpic, backDoorPollEpic } from './backDoorEpic';

import { loginEpic, loginSuccessEpic, loginPollEpic, loginFailEpic, loginCancelEpic } from './loginEpic';

import {
    kycFetchEpic,
    kycCompleteEpic,
    kycPersonAddEpic,
    kycPersonRemoveEpic,
    kycChipsChoose,
    kycChipsDeselect,
    kycHandleUpdate,
    kycPersonUpdate,
} from './kycEpic';

import { agreementInitEpic, agreementSignEpic, agreementSignPollEpic, agreementSignPollSignatureChangesEpic, agreementMarkAsDoneEpic } from './agreementEpic';

import { validateAccountPollEpic, validateAccountChooseAccount, validateAccountGetChosenAccount } from './validateAccountEpic';

import {
    organisationGetCommitmentsEpic,
    organisationUpdateEpic,
    organisationChooseCommitmentEpic,
    organisationGetTasksEpic,
    organisationCreateOnboardingEpic,
    organisationAddContactDetailsEpic,
    organisationGetDirectorshipEpic,
    organisationCreateDynamicPricingOnboardingEpic,
    organisationBookHelpFromSupportEpic,
} from './organisationEpic';

import { tasksRefresh, tasksPollEpic } from './tasksEpic';

import { oidcTimeOutEpic } from './oidcEpic';

import { saveLoanApplicationEpic } from './loanEpic';

import { fetchPendingCustomerBankDetailsEpic, savePendingCustomerBankDetailsEpic } from './bankDetailsEpic';

import { fetchOfferEpic } from './offerEpic';

export const rootEpic = combineEpics(
    // Login
    loginEpic,
    loginSuccessEpic,
    loginPollEpic,
    loginFailEpic,
    loginCancelEpic,
    // KYC
    kycFetchEpic,
    kycCompleteEpic,
    kycPersonAddEpic,
    kycPersonRemoveEpic,
    kycChipsChoose,
    kycChipsDeselect,
    kycHandleUpdate,
    kycPersonUpdate,
    // Back door
    backDoorInitEpic,
    backDoorRefreshEpic,
    backDoorPollEpic,
    // Agreement
    agreementInitEpic,
    agreementSignEpic,
    agreementSignPollEpic,
    agreementSignPollSignatureChangesEpic,
    agreementMarkAsDoneEpic,
    // Validate account
    validateAccountPollEpic,
    validateAccountChooseAccount,
    validateAccountGetChosenAccount,
    // Organisation
    organisationGetCommitmentsEpic,
    organisationUpdateEpic,
    organisationChooseCommitmentEpic,
    organisationGetTasksEpic,
    organisationCreateOnboardingEpic,
    organisationCreateDynamicPricingOnboardingEpic,
    organisationAddContactDetailsEpic,
    organisationGetDirectorshipEpic,
    organisationBookHelpFromSupportEpic,
    // Oidc
    oidcTimeOutEpic,
    // Modal
    modalEpic,
    // Tasks
    tasksRefresh,
    tasksPollEpic,
    // Loan
    saveLoanApplicationEpic,
    // offer
    fetchOfferEpic,
    //Bank Details
    savePendingCustomerBankDetailsEpic,
    fetchPendingCustomerBankDetailsEpic
);
