export const BACKDOOR_UPDATE = 'BACKDOOR_UPDATE';
export const BACKDOOR_INIT = 'BACKDOOR_INIT';
export const BACKDOOR_FAIL = 'BACKDOOR_FAIL';
export const BACKDOOR_REFRESH = 'BACKDOOR_REFRESH';
export const BACKDOOR_POLL = 'BACKDOOR_POLL';
export const BACKDOOR_POLL_STOP = 'BACKDOOR_POLL_STOP';

export const backDoorUpdate = (backDoorObj) => ({
    type: BACKDOOR_UPDATE,
    payload: backDoorObj,
});

export const backDoorRefresh = () => ({
    type: BACKDOOR_REFRESH,
});

export const backDoorInit = (id) => {
    return {
        type: BACKDOOR_INIT,
        payload: id,
    };
};

export const backDoorFail = (error) => {
    return {
        type: BACKDOOR_FAIL,
        payload: error,
    };
};

export const backDoorPollStart = (id, type) => ({
    type: BACKDOOR_POLL,
    payload: {
        transactionId: id,
        transactionType: type,
    },
});

export const backDoorPollStop = (tasks) => ({
    type: BACKDOOR_POLL_STOP,
});
