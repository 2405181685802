import React, { Component } from 'react';
import { connect } from 'react-redux';

import { kycChooseChips } from '../actions';

// Radio input field for KYC
class KycRadio extends Component {
    constructor(props) {
        super(props);

        let { inputObj } = props;
        inputObj.meta.options.map((d) => (d.isChosen = false));
        if (inputObj.answer && inputObj.answer.length > 0) {
            for (let answer of inputObj.answer) {
                if (inputObj.meta && inputObj.meta.options) {
                    inputObj.meta.options = inputObj.meta.options.map((d) => {
                        if (d.id === answer.id) {
                            // Is match in answers is found, change to chosen
                            d.isChosen = true;
                        } else if (!d.isChosen) {
                            // Make sure there is a value
                            d.isChosen = false;
                        }
                        return d;
                    });
                }
            }
        }
        this.state = {
            inputObj: inputObj,
        };
    }

    validateSelections() {
        if (this.state.inputObj.meta.model.validation.mandatory && this.state.inputObj.answer && this.state.inputObj.answer.length === 0) {
            this.setState((state) => {
                state.isValid = false;
                return state;
            });
        } else if (this.state.inputObj.meta.model.validation.mandatory && this.state.inputObj.answer && this.state.inputObj.answer.length > 0) {
            this.setState((state) => {
                state.isValid = true;
                return state;
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isValid !== nextProps.inputObj.isValid) {
            this.setState((state) => {
                state.isValid = nextProps.inputObj.isValid;
                return state;
            });
        }
    }

    handleSelction(option, index, event) {
        let { inputObj } = this.state;
        let isChosen = event.target.checked;
        option.isChosen = isChosen;
        this.setState(
            (state) => {
                state.inputObj.meta.options[index].isChosen = isChosen;
                return state;
            },
            () => {
                this.validateSelections();
            }
        );
        if (isChosen) {
            this.props.kycChooseChips(inputObj, option);
        } else {
            this.props.kycDeselectChips(inputObj, option);
        }
    }

    render() {
        let { inputObj, isValid } = this.state;
        let { kyc } = this.props;
        let inputClasses = () => {
            let className = 'input input--radio';
            if (typeof isValid !== 'undefined' && !isValid) {
                className += ' input--error';
            }
            if (kyc.completed) {
                className += ' input--disabled';
            }
            return className;
        };
        return (
            <div className="kyc__radio">
                <div className="form">
                    <p className="kyc__label">{inputObj.question}</p>
                    {inputObj.meta.options.map((a, index) => {
                        return (
                            <div className={inputClasses()} key={a.id}>
                                <label htmlFor={a.id}>
                                    <input
                                        disabled={kyc.completed}
                                        value={a.isChosen}
                                        checked={a.isChosen}
                                        onChange={this.handleSelction.bind(this, a, index)}
                                        id={a.id}
                                        name={a.parentQuestionId}
                                        type="radio"
                                        className="input__radio"
                                    />
                                    <span className="input__box" />
                                    <span className="input__check" />
                                    <div htmlFor={a.id} className="input__label">
                                        {a.text}
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        kyc: state.kyc,
    };
};

KycRadio = connect(mapStateToProps, {
    kycChooseChips,
})(KycRadio);

export default KycRadio;
