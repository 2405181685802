import {
    CUSTOMER_BANK_DETAILS,
    FETCH_PENDING_CUSTOMER_BANK_DETAILS,
    SAVE_PENDING_CUSTOMER_BANK_DETAILS,
    CUSTOMER_BANK_DETAILS_SAVED,
} from '../actions/bankDetails';

const initialState = {
    isLoading: true,
    customerBankDetails: {
        bankAccountNumber: '',
    },
    accountNumber: '',
    bank: '',
    bankAccountType: '',
    organisationNumber: '',
    screenShotOfBankAccount: '',
    responseStatus: '',
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case CUSTOMER_BANK_DETAILS:
        case FETCH_PENDING_CUSTOMER_BANK_DETAILS:
        case SAVE_PENDING_CUSTOMER_BANK_DETAILS:
        case CUSTOMER_BANK_DETAILS_SAVED:
            return { ...state, ...action.payload };
        default:
            return { ...state };
    }
}
