import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FETCH_OFFER, updateOffer } from '../actions';

const endpoint = process.env.REACT_APP_API_URL;

export const fetchOfferEpic = (action$, store) => {
    return action$.ofType(FETCH_OFFER).pipe(
        switchMap(() => {
            let { oidc, organisation } = store.value;

            return ajax
                .get(`${endpoint}api/onboarding/getoffer/`, {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${oidc.user.access_token}`,
                    'organisation-number': organisation.organisationNumber,
                })
                .pipe(
                    map((response) => response.response),
                    switchMap((response) => {
                        return of(
                            updateOffer({
                                ...response,
                                isLoading: false,
                                determinationError: false,
                            })
                        );
                    }),
                    catchError((error) => {
                        return of(
                            updateOffer({
                                isLoading: false,
                                determinationError: true,
                            })
                        );
                    })
                );
        })
    );
};
