export const CREATE_LOAN_APPLICATION = 'CREATE_LOAN_APPLICATION';
export const SAVE_LOAN_APPLICATION = 'SAVE_LOAN_APPLICATION';
export const UPDATE_LOAN_APPLICATION = 'UPDATE_LOAN_APPLICATION';

export const createLoanApplication = (loanRequest) => ({
    type: CREATE_LOAN_APPLICATION,
    payload: loanRequest,
});

export const saveLoanApplication = (loanRequest) => ({
    type: SAVE_LOAN_APPLICATION,
    payload: loanRequest,
});

export const updateLoanApplication = (state) => ({
    type: UPDATE_LOAN_APPLICATION,
    payload: state,
});
