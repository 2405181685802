import { CREATE_LOAN_APPLICATION, SAVE_LOAN_APPLICATION, UPDATE_LOAN_APPLICATION } from '../actions/index';

const initialState = {
    isLoanApplication: false,
    success: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_LOAN_APPLICATION:
        case SAVE_LOAN_APPLICATION:
        case CREATE_LOAN_APPLICATION:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
