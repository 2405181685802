import { FETCH_OFFER, UPDATE_OFFER, ADD_TURNOVER_MONTH } from '../actions';

const initialState = {
    isLoading: true,
    determinationError: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_OFFER:
            return {
                ...initialState,
                isLoading: true,
                error: null,
            };
        case UPDATE_OFFER:
            return {
                ...action.payload,
                isLoading: false,
                error: null,
            };
        case ADD_TURNOVER_MONTH:
            return {
                ...state,
                turnover: action.payload,
            };
        default:
            return state;
    }
}
