import { LOGIN_TRIGGER, LOGIN_STATUS, LOGIN_SUCCESS, LOGIN_FAIL, LOGIN_POLL, LOGIN_CANCEL } from '../actions/index';

const initialState = {
    status: {
        message: '',
    },
    isLoggedIn: false,
    isLoading: false,
    user: null,
    error: null,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_TRIGGER:
        case LOGIN_CANCEL:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false,
                error: null,
            };
        case LOGIN_POLL:
            return {
                ...state,
                ...action.payload,
            };
        case LOGIN_STATUS:
        case LOGIN_SUCCESS:
        case LOGIN_FAIL:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
