import React from 'react';
import { NavLink } from 'react-router-dom';
import { Motion, spring } from 'react-motion';

import { vanishConfig } from '../AnimationSettings';

// 404 page
export const Error = ({ firstHit }) => {
    return (
        <Motion defaultStyle={{ width: 70 }} style={{ width: spring(100, vanishConfig) }}>
            {(style) => (
                <div className="content__body content__body--centered content__body--error" style={{ width: `${style.width}%` }}>
                    <h1 className="title title--h1">Så det kan gå!</h1>
                    <p className="paragraph">
                        Något gick snett när systemet skulle visa sidan. Om felet kvarstår kan du testa att stänga din webbläsare och försöka på nytt igen från
                        början.
                    </p>
                    {firstHit ? (
                        <NavLink to={firstHit.name} className="button button--action">
                            {' '}
                            Till startsidan
                        </NavLink>
                    ) : null}
                </div>
            )}
        </Motion>
    );
};
