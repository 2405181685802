export const VALIDATE_ACCOUNT_INIT = 'VALIDATE_ACCOUNT_INIT';
export const VALIDATE_ACCOUNT_POLL_START = 'VALIDATE_ACCOUNT_POLL_START';
export const VALIDATE_ACCOUNT_POLL_STOP = 'VALIDATE_ACCOUNT_POLL_STOP';
export const VALIDATE_ACCOUNT_STATUS = 'VALIDATE_ACCOUNT_STATUS';
export const VALIDATE_ACCOUNT_ERROR = 'VALIDATE_ACCOUNT_ERROR';
export const VALIDATE_ACCOUNT_CHOOSE_ACCOUNT = 'VALIDATE_ACCOUNT_CHOOSE_ACCOUNT';
export const VALIDATE_ACCOUNT_GET_CHOSEN_ACCOUNT = 'VALIDATE_ACCOUNT_GET_CHOSEN_ACCOUNT';

export const validateAccountInit = (sessionId) => {
    return {
        type: VALIDATE_ACCOUNT_INIT,
        payload: sessionId,
    };
};

export const validateAccountStatus = (status) => {
    return {
        type: VALIDATE_ACCOUNT_STATUS,
        payload: status,
    };
};

export const validateAccountPollStop = (sessionId) => ({
    type: VALIDATE_ACCOUNT_POLL_STOP,
    payload: sessionId,
});

export const validateAccountPollStart = (sessionId) => ({
    type: VALIDATE_ACCOUNT_POLL_START,
    payload: sessionId,
});

export const validateAccountFailure = (error) => ({
    type: VALIDATE_ACCOUNT_ERROR,
    payload: {
        error: error,
    },
});

export const validateAccountChooseAccount = (account) => ({
    type: VALIDATE_ACCOUNT_CHOOSE_ACCOUNT,
    payload: {
        account: account,
    },
});

export const getChosenAccount = (id, organisationNumber) => ({
    type: VALIDATE_ACCOUNT_GET_CHOSEN_ACCOUNT,
    payload: {
        id: id,
        organisationNumber: organisationNumber,
    },
});
