import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createLoanApplication, loginSuccess } from '../actions';
import { BankIdLogin } from '@factoringgruppen/fg.ui.components';
import { loginTrigger, loginStatus, organisationUpdate, loginCancel } from '../actions';
import SignInCallback from './SignInCallback';
import { isMobile, isIosWebView } from '../helpers/mobile';
import { userManager } from '../store';
import { isMobileSafari } from 'react-device-detect';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankIdEndPoints: {
                initiateEndPoint: {
                    url: process.env.REACT_APP_BANKID_INIT_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
                pollEndPoint: {
                    url: process.env.REACT_APP_BANKID_POLL_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
                generateRollingQrCode: {
                    url: process.env.REACT_APP_BANKID_GENERATE_ROLLING_QR_CODE_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
            },
            redirectUrl: isIosWebView() ? process.env.REACT_APP_BANKID_MYPAGES_IOS_APP_REDIRECT_URL : process.env.REACT_APP_BANKID_REDIRECT_URL,
        };
        if (sessionStorage.getItem('bidInfo')) {
            sessionStorage.removeItem('bidInfo');
        }

        if (!props.oidc.isLoadingUser && !props.oidc.user && sessionStorage.getItem('commitment')) {
            sessionStorage.removeItem('commitment');
            this.props.organisationUpdate({
                name: '',
                organisationNumber: '',
                list: [],
                isLoading: false,
            });
        }
        if (props.loginInformation.isLoading) {
            props.loginStatus({ isLoading: false });
        }
        if (props.oidc.user && props.organisation.organisationNumber) {
            if (this.props.location.state && this.props.location.state.from.pathname) {
                let prevRoute = this.props.location.state.from.pathname;
                this.props.history.push({ pathname: prevRoute });
            } else {
                if (this.props.loan.isLoanApplication) {
                    this.props.history.push({ pathname: '/lån' });
                } else {
                    this.props.history.push({ pathname: '/hem' });
                }
            }
        }
    }

    componentDidUpdate() {
        userManager.getUser().then((user) => {
            if (user && user.id_token !== '' && isMobileSafari) {
                this.props.history.push({ pathname: '/hem' });
            }
        });
    }

    componentDidMount() {
        this.setState((state) => {
            state.isMobile = isMobile(navigator, window);
            return state;
        });
        if (this.props.organisation.name !== '') {
            // used for backdoor link
            this.props.history.push({ pathname: '/hem' });
        }
    }

    render() {
        return (
            <div className="form form--login content__body content__body--centered">
                {this.props.loan.isLoanApplication ? (
                    <div>
                        <h1 className="title title--h1">Identifiera dig och välj företag</h1>
                        <p className="paragraph">
                            Skriv in ditt personnummer nedan för att komma vidare. Klicka på fortsätt och öppna BankID på din telefon eller dator. Ansökan kan
                            endast göras om du sitter som ledamot i ditt företags styrelse.
                        </p>
                        <p className="paragraph">
                            I samband med detta kommer en kreditupplysning tas på det företag du väljer att ansökan ska gälla. Om ditt företag är en enskild
                            firma eller ett handels- eller kommanditbolag kommer även en omfrågandekopia att skickas.
                        </p>
                        <p className="paragraph">
                            Eftersom lånet skall säkerställas med en personlig borgen från minst en person kommer vi även att utföra en personupplysning via UC
                            på dig som gör ansökan. Läs gärna vår fullständiga integritetspolicy om hur vi behandlar dina personuppgifter{' '}
                            <a href="https://www.factoringgruppen.se/integritetspolicy/">här</a>.
                        </p>
                    </div>
                ) : (
                    <div>
                        <h1 className="title title--h1">Välkommen till Factoringgruppen</h1>
                        <p className="paragraph">
                            Trevligt att du hittat hit! Vi på Factoringgruppen älskar företagande och hjälper dig att frigöra både tid och pengar till nya
                            affärer och investeringar. Med oss får du en snabbfotad partner för alla stadier av ditt företagande. Välkommen till oss, nu startar
                            vi!
                        </p>
                        <p>Nu ska vi genomföra följande steg:</p>
                        <div className="content__checklist">
                            <ul>
                                <li>Legitimera dig och ditt företag med BankID</li>
                                <li>Få ditt skräddarsydda prisförslag</li>
                                <li>Läs igenom villkoren</li>
                            </ul>
                        </div>
                        <p className="paragraph">
                            Är vi överens signerar du avtalet enkelt och smidigt med BankID och du kan sedan få pengar för dina fakturor direkt!
                        </p>
                    </div>
                )}

                <div className="bankId_wrapper">
                    <BankIdLogin
                        handleCompleted={() => { }}
                        title={''}
                        description={''}
                        onBankIdComplete={(id) => this.props.loginSuccess(id)}
                        bankIdEndPoints={this.state.bankIdEndPoints}
                        redirectUrl={this.state.redirectUrl}
                        orString={'eller om du vill öppna BankID på den här enheten'}
                        buttonStringSameDevice={isIosWebView() ? 'Logga in' : 'Öppna BankID på denna enheten'}
                        preventRedirect={isIosWebView()}
                        styles={{ useRoundedCorner: true }}
                    />
                </div>
                {!this.props.loan.isLoanApplication ? (
                    <p className="caption">
                        *I samband med att du identifierar dig med BankID kommer en kreditupplysning utföras på det företag du väljer att ansökan ska gälla. Om
                        ditt företag är en enskild firma eller ett handels- eller kommanditbolag kommer även en omfrågandekopia att skickas. Läs gärna vår
                        fullständiga integritetspolicy om hur vi behandlar dina personuppgifter{' '}
                        <a href="https://www.factoringgruppen.se/integritetspolicy/">här</a>.
                    </p>
                ) : null}

                {this.props.router.location.hash ? <SignInCallback /> : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        router: state.router,
        loginInformation: state.loginInformation,
        organisation: state.organisation,
        loan: state.loan,
    };
};

Login = connect(mapStateToProps, {
    loginTrigger,
    loginStatus,
    organisationUpdate,
    loginCancel,
    createLoanApplication,
    loginSuccess,
})(Login);

export default Login;
