import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'redux-first-history';
import { userManager } from '../store';
import { createLoanApplication } from '../actions';

// Sign in callback based on oidc client and redux-oidc
class SignInCallback extends React.Component {
    constructor(props) {
        super(props);

        let url = '/hem';
        let loanapplicationString = sessionStorage.getItem('loanapplication') || '';
        if (loanapplicationString !== '') {
            let loanApplicationObj = JSON.parse(loanapplicationString);
            this.props.createLoanApplication(loanApplicationObj);
            url = '/lån';
        }

        this.state = {
            url: url,
        };
    }

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={() => this.state.url}
                errorCallback={(error) => {
                    push({ pathname: '/' });
                }}
            >
                <React.Fragment></React.Fragment>
            </CallbackComponent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        router: state.router,
        loan: state.loan,
    };
};
SignInCallback = connect(mapStateToProps, {
    createLoanApplication,
})(SignInCallback);

export default SignInCallback;
