import { of, empty } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';
import { modalTrigger } from '../actions';

export const oidcTimeOutEpic = (action$, store) =>
    action$.ofType(USER_EXPIRED, SILENT_RENEW_ERROR).pipe(
        switchMap((action) => {
            let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
            if (ssData && ssData.bid && !(ssData.date < new Date().setMinutes(-60))) {
                // If user has valid BID active
                return empty();
            }

            let loanapplicationString = sessionStorage.getItem('loanapplication') || '';

            // User is not valid anymore. Clean up data.
            sessionStorage.clear();

            // Remember context
            if (loanapplicationString !== '') {
                sessionStorage.setItem('loanapplication', loanapplicationString);
            }

            return of(modalTrigger(false));
        })
    );
