import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/string';
import 'raf/polyfill';
import React from 'react';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import 'rxjs';
import App from './App';
import { store, history, userManager } from './store';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

class Index extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <OidcProvider store={store} userManager={userManager}>
                    <Router history={history}>
                        <App />
                    </Router>
                </OidcProvider>
            </Provider>
        );
    }
}

root.render(<Index />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
