import React, { Component } from 'react';
import { connect } from 'react-redux';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import { fetchOffer, organisationCreateDynamicPricingOnboarding, addTurnoverMonth } from '../actions';
import Spinner from './Spinner';
import imageHelp from '../img/help_icon.svg';
import ReactTooltip from 'react-tooltip';
import { isMobile } from '../helpers/mobile';
import { organisationUpdate } from '../actions';
import AddContactInformationAndSendOnboarding from './modals/AddContactInformationAndSendOnboarding';
import { modalTrigger } from '../actions';
import AddContactInformation from './modals/AddContactInformation';

class Offerlandingpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousAmount: 0,
            amount: 200000,
            targetAmount: 0,
            provision: 3.95,
            limitCharge: 0.5,
            totalCost: 4.45,
            turnover: this.toSek(500),
            turnoverMonth: this.toSek(42),
        };
        this.props.fetchOffer();
    }

    onAmountSlide = (render, handle, value, un, percent) => {
        this.getPriceFromOptions(value);
        this.setState((state) => {
            state.amount = Math.round(value);
            return state;
        });
    };

    getPriceFromOptions(value) {
        if (this.props.offer.dynamicPricingOptions == null) {
            return;
        }

        let pricing = null;
        if (value > this.props.offer.dynamicPricingOptions[this.props.offer.dynamicPricingOptions.length - 1].volumeEnd) {
            pricing = this.props.offer.dynamicPricingOptions[this.props.offer.dynamicPricingOptions.length - 1];
        } else {
            pricing = this.props.offer.dynamicPricingOptions.find((range) => range.volumeStart <= value && range.volumeEnd >= value);
        }
        if (pricing != null) {
            this.setState((state) => {
                state.provision = pricing.provision;
                state.limitCharge = pricing.limitCharge;
                state.targetAmount = value[0];
                state.totalCost = (pricing.provision + pricing.limitCharge).toFixed(2);
                return state;
            });
        }
    }

    toSek(value) {
        if (value == null) {
            return;
        }
        return Intl.NumberFormat('sv-se', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
        }).format(value.toFixed(0));
    }

    getMinAndMax() {
        if (Object.keys(this.props.offer).length !== 0 && !this.props.offer.isLoading) {
            var min = this.props.offer.dynamicPricingOptions[0].volumeStart;
            var max = this.props.offer.turnoverMonth;
            if (!this.props.offer.isDefault) {
                max = max * 1.33;
            }
            return { min: min, max: max };
        } else {
            return { min: 50000, max: 500000 };
        }
    }

    getStart() {
        var startAmount = this.state.previousAmount ? this.state.previousAmount : this.props.offer.turnoverMonth;
        if (!this.state.previousAmount && this.props.offer.isDefault) {
            startAmount = 130000;
        }
        return startAmount;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.offer.isLoading !== this.props.offer.isLoading && !this.props.offer.isLoading) {
            this.getPriceFromOptions(this.props.offer.turnoverMonth);
            this.setState((state) => {
                state.amount = this.state.previousAmount ? this.state.previousAmount : this.props.offer.turnoverMonth;
                if (!this.state.previousAmount && this.props.offer.isDefault) {
                    state.amount = 130000;
                }
                if (!state.targetAmount) {
                    state.targetAmount = this.state.previousAmount ? this.state.previousAmount : this.props.offer.turnoverMonth;
                }
                return state;
            });
        }
    }

    componentDidMount() {
        this.setState((state) => {
            state.tooltipPosition = isMobile(navigator, window) ? 'left' : 'right';
            state.previousAmount = this.props.offer.turnoverMonth;
            return state;
        });
    }

    helpClick(e) {
        e.preventDefault();
        this.props.addTurnoverMonth(this.state.amount);
        this.setState(
            (state) => {
                state.bookHelp = true;
                return state;
            },
            () => {
                this.props.modalTrigger(true, AddContactInformation, true, this.state);
            }
        );
    }

    openContactDetails(event) {
        event.preventDefault();
        this.props.modalTrigger(true, AddContactInformationAndSendOnboarding, true, this.state);
    }
    render() {
        return (
            <React.Fragment>
                <div className="content__body">
                    <div>
                        <h1 className="title title--h1">Hur mycket räknar du med att sälja varje månad?</h1>
                        <p className="paragraph">
                            Dags att boosta företaget? Bra! För vi på fg gillar att hjälpa företagare. Du får ett skräddarsytt förslag som är flexibelt och ett
                            avtal utan bindningstid.
                        </p>
                        {this.props.offer.determinationError ? (
                            <div>
                                <h1 className="title title--h3">
                                    Attans! Vi kunde inte hämta informationen åt dig. Men det är ingen fara. Boka personlig service så hjälper vi till att hitta
                                    rätt erbjudande för dig
                                </h1>
                            </div>
                        ) : null}
                        {this.props.offer.isLoading ? (
                            <div>
                                <p className="paragraph">
                                    Just nu hämtar vi lite information om ert företag för att göra det superlätt för dig att välja hur mycket du kan finansiera
                                    per månad
                                </p>
                                <div className="content__spinner">
                                    <Spinner />
                                </div>
                            </div>
                        ) : null}

                        {!this.props.offer.isLoading && !this.props.offer.determinationError ? (
                            <div>
                                {this.props.offer.isDefault ? (
                                    <div>
                                        <p className="paragraph">
                                            Vi kan inte läsa ut vilken din senaste årsomsättning var för ditt företag. Detta kan exempelvis bero på att du inte
                                            haft ett bokslut ännu eller att det är en annan företagsform än ett aktiebolag. Vi gör därför ett antagande att du
                                            omsätter ca 1 200 000 kr idag.
                                        </p>
                                        <p className="paragraph">
                                            Om du skulle vilja sälja alla dina fakturor skulle detta motsvara ca 130 000 kr per månad. Din föreslagna
                                            månadsvolym är ett snitt beräknad på din omsättning inklusive moms.
                                        </p>
                                    </div>
                                ) : (
                                    <p className="paragraph">
                                        Vid ditt senaste årsbokslut omsatte ditt företag {this.toSek(this.props.offer.turnover)} exklusive moms. Om du skulle
                                        vilja sälja alla dina fakturor skulle detta motsvara ca {this.toSek(this.props.offer.turnoverMonth)} per månad eftersom
                                        vi köper fakturan inklusive moms.
                                    </p>
                                )}

                                <p className="paragraph">
                                    Vill du justera detta gör du det nedan. Ju bättre du estimerar din volym, desto mer exakt kan vi beräkna ditt pris.
                                </p>
                                <div className="input">
                                    <label className="input__label input-range offer__sliderlabel">Hur mycket vill du sälja per månad?</label>
                                    <p className="offer__selectedvalue">{this.toSek(this.state.amount)} (inkl. moms)</p>
                                    <Nouislider
                                        className="offer-slider"
                                        range={this.getMinAndMax()}
                                        start={this.getStart()}
                                        connect="lower"
                                        accessibility
                                        step={10000}
                                        onSlide={this.onAmountSlide}
                                    />
                                </div>
                                <div className="offer__calc receipt receipt--small">
                                    <div className="calc__example">
                                        <p className="caption calc__header">Ditt skräddarsydda pris</p>
                                        <div className="calc__example__itemcost calc__tooltip">
                                            <a
                                                data-event="click focus"
                                                data-tip="Priset är baserat på din angivna volym hos oss och utgörs av en provision på fakturorna
                                            du säljer samt en minimiavgift på din förväntade volym. Provisionen per 30 dagar betalas vid varje köpetillfälle 
                                            medan minimiavgiften debiteras månadsvis"
                                            >
                                                <img src={imageHelp} alt="frågetecken" />
                                            </a>
                                            <ReactTooltip
                                                class="generic-tooltip"
                                                globalEventOff="click"
                                                place={this.state.tooltipPosition}
                                                type="dark"
                                                effect="solid"
                                            />
                                        </div>
                                    </div>
                                    <div className="calc__example">
                                        <div className="calc__example item caption">Provision per 30 dagar</div>
                                        <div className="calc__example__itemcost">{this.state.provision}%</div>
                                    </div>
                                    <div className="calc__example">
                                        <div className="calc__example item caption">Minimiavgift</div>
                                        <div className="calc__example__itemcost">{this.state.limitCharge}%</div>
                                    </div>
                                </div>
                                {this.props.organisation.addcontactDetailsLoading ? (
                                    <div>
                                        <div className="content__spinner">
                                            <Spinner />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        <div className="offer__buttons">
                            {!this.props.offer.isLoading && !this.props.offer.determinationError ? (
                                <div>
                                    <button className="button" onClick={this.openContactDetails.bind(this)}>
                                        Fortsätt
                                    </button>
                                </div>
                            ) : null}

                            {!this.props.offer.isLoading ? (
                                <div>
                                    <button className="button button--secondary offer__help__button" onClick={this.helpClick.bind(this)}>
                                        Personlig service
                                    </button>
                                </div>
                            ) : null}
                        </div>

                        <div className="offer__help">
                            {this.props.organisation.helpBooked ? (
                                <p className="paragraph">Vi har mottagit din kontaktförfrågan och någon hos FG kommer kontakta dig inom kort.</p>
                            ) : null}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        organisation: state.organisation,
        offer: state.offer,
        loading: state.loading,
    };
};

Offerlandingpage = connect(mapStateToProps, {
    fetchOffer,
    organisationCreateDynamicPricingOnboarding,
    organisationUpdate,
    modalTrigger,
    addTurnoverMonth,
})(Offerlandingpage);

export default Offerlandingpage;
