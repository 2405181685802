import React, { Component } from 'react';
import { connect } from 'react-redux';
import { agreementInit, agreementChoose, agreementPollSignatureChanges } from '../actions/index';
import { agreementMarkAsDone } from '../actions';

import Agreement from './Agreement';
import Confirmation from './Confirmation';

import { Motion } from 'react-motion';
import { spring } from 'react-motion';

// This is the page that lists all the different agreements a user can sign
class Sign extends Component {
    constructor(props) {
        super(props);
        this.termsView = React.createRef();
        this.state = {
            isTermsExpanded: false,
        };

        props.tasks.tasks.map((task) => {
            if (task.type === 'AgreementSigning' && !task.completed) {
                let eventName = `${task.type}Started`;
                if (window.dataLayer.findIndex((d) => d.event === eventName) === -1) {
                    let dataLayerObj = {
                        event: eventName,
                    };
                    window.dataLayer.push(dataLayerObj);
                }
            }
            return task;
        });
    }

    componentDidMount() {
        if (this.props.agreement.agreements && this.props.agreement.agreements.length > 0) {
            return;
        }
        let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
        let bundleId;
        this.props.tasks.tasks.map((task) => {
            if (task.type === 'AgreementSigning' && !bundleId) {
                bundleId = task.dataId;
            }
            return task;
        });
        // Depending on what data is available, use invitation ID or not.
        if (!this.props.agreement.id && !this.props.agreement.error && ssData && ssData.bid) {
            this.props.agreementInit({
                bundleId: bundleId,
                invitationId: ssData.bid,
                invitationToken: ssData.invitationToken,
                organisationNumber: this.props.organisation.organisationNumber,
            });
        } else if (this.props.oidc.user && !this.props.agreement.error && this.props.organisation.organisationNumber) {
            this.props.agreementInit({
                organisationNumber: this.props.organisation.organisationNumber,
                bundleId: bundleId,
            });
        }
    }

    componentWillUnmount() {
        // Clean up if user navigates somewhere else
        this.props.agreementChoose({});
    }

    retryFetch(event) {
        event.preventDefault();
        let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
        let bundleId;
        this.props.tasks.tasks.map((task) => {
            if (task.type === 'AgreementSigning') {
                bundleId = task.dataId;
            }
            return task;
        });
        // Depending on what data is available, use invitation ID or not.
        if (ssData) {
            this.props.agreementInit({
                bundleId: bundleId,
                invitationId: ssData.bid,
                organisationNumber: this.props.organisation.organisationNumber,
            });
        } else if (this.props.oidc.user && this.props.organisation.organisationNumber) {
            this.props.agreementInit({
                organisationNumber: this.props.organisation.organisationNumber,
                bundleId: bundleId,
            });
        }
    }

    chooseAgreement(agreement, event) {
        event.preventDefault();
        this.setState((state) => {
            state.animating = true;
        });
        this.props.agreementChoose(agreement);
        this.props.agreementPollSignatureChanges();
    }

    onRest() {
        this.setState((state) => {
            state.animating = false;
            return state;
        });
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    render() {
        const animationConfig = { stiffness: 250, damping: 20 };
        let { agreement } = this.props || {};
        let allSigned = agreement.chosenAgreement && agreement.chosenAgreement.signatureOptions && agreement.chosenAgreement.signatureOptions.fullySigned;
        let task = this.props.tasks.tasks.find((task) => task.type === 'AgreementSigning');
        return (
            <div className="content__body">
                <div style={this.state.animating ? { position: 'relative' } : null}>
                    {allSigned && agreement.chosenAgreement && agreement.chosenAgreement.content ? (
                        <Motion
                            defaultStyle={agreement.chosenAgreement.agreementId ? { opacity: 0, transform: 15 } : { opacity: 1, transform: 0 }}
                            style={
                                !agreement.chosenAgreement.agreementId
                                    ? { opacity: spring(0, animationConfig), transform: spring(15, animationConfig) }
                                    : { opacity: spring(1, animationConfig), transform: spring(0, animationConfig) }
                            }
                            onRest={this.onRest.bind(this)}
                        >
                            {(style) => (
                                <div
                                    style={
                                        this.state.animating
                                            ? {
                                                  opacity: style.opacity,
                                                  transform: `translateY(${style.transform}px)`,
                                                  transformOrigin: 'top',
                                                  position: 'absolute',
                                              }
                                            : { opacity: style.opacity, transform: `translateY(${style.transform}px)`, transformOrigin: 'top' }
                                    }
                                >
                                    <Confirmation />
                                </div>
                            )}
                        </Motion>
                    ) : agreement.chosenAgreement && agreement.chosenAgreement.content ? (
                        <h1 className="title title--h1">{agreement.chosenAgreement.name}</h1>
                    ) : (
                        <h1 className="title title--h1">Avtal</h1>
                    )}
                    {task && task.needsManualHandling ? (
                        <article className="manual-handling">
                            <img className="warning-icon" src={require('../img/warning.svg').default} alt="En kvadrat med ett utropstecken inuti" />
                            <h1 className="title title--h1">Manuell granskning pågår</h1>
                            <p className="paragraph">
                                Ett eller flera avtal kräver manuell granskning från vår sida. Detta kan bero på att vi inte kan automatiskt validera signaturer
                                mot er firmateckning eller liknande.
                            </p>
                            <h3 className="title title--h3">Vad gör jag nu?</h3>
                            <p className="paragraph">
                                Skicka ett email till Factoringgruppen på{' '}
                                <a className="link" href="mailto:onboarding@fg.se">
                                    onboarding@fg.se
                                </a>{' '}
                                så hjälper vi till att lösa det den gamla vanliga vägen. Vänligen ange ert organisationsnummer{' '}
                                <strong>({this.props.organisation.organisationNumber})</strong> och era kontaktuppgifter så kan vi hjälpa er snabbare.
                            </p>
                            <h3 className="title title--h3">Kan jag göra klart resten av registreringen?</h3>
                            <p className="paragraph">
                                Inga problem! Du kan fortfarande svara på kontrollfrågor och validera kontouppgifter så är du nästan klar.
                            </p>
                        </article>
                    ) : null}
                    {!(agreement.chosenAgreement && agreement.chosenAgreement.agreementId) || this.state.animating ? (
                        <Motion
                            defaultStyle={!agreement.chosenAgreement.agreementId ? { opacity: 0, transform: 15 } : { opacity: 1, transform: 0 }}
                            style={
                                agreement.chosenAgreement.agreementId
                                    ? { opacity: spring(0, animationConfig), transform: spring(15, animationConfig) }
                                    : { opacity: spring(1, animationConfig), transform: spring(0, animationConfig) }
                            }
                            onRest={this.onRest.bind(this)}
                        >
                            {(style) => (
                                <div style={this.state.animating ? { opacity: style.opacity, position: 'absolute' } : { opacity: style.opacity }}>
                                    {agreement && agreement.agreements && agreement.agreements.length > 0 ? (
                                        <React.Fragment>
                                            <p className="paragraph">
                                                Nedan ser du de avtal du behöver signera för att aktivera tjänsten. Klicka på gå till avtal för att läsa igenom
                                                avtalsvillkoren och signera sedan enkelt och tryggt med BankID.
                                            </p>
                                            <div className="agreement-list" style={{ transform: `translateY(${style.transform}%)`, transformOrigin: 'top' }}>
                                                {agreement.agreements.map((a, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div
                                                                key={index}
                                                                className={
                                                                    a.signatureOptions.fullySigned
                                                                        ? 'agreement-list__item agreement-list__item--done'
                                                                        : 'agreement-list__item'
                                                                }
                                                            >
                                                                <div className="agreement-list__panel">
                                                                    <p className="title title--h3">{a.name}</p>
                                                                    {a.signatureOptions ? (
                                                                        <React.Fragment>
                                                                            {a.signatureOptions.signatureType === 'Board' ? (
                                                                                <p className="paragraph">Kräver firmateckning</p>
                                                                            ) : null}
                                                                            {a.signatureOptions.signatureType === 'Person' ? (
                                                                                <p className="paragraph">Kräver signatur från person(er)</p>
                                                                            ) : null}
                                                                            {a.signatureOptions.signatureType === 'None' ? (
                                                                                <p className="paragraph">Ingen signatur krävs</p>
                                                                            ) : null}
                                                                            {a.signatureOptions.signatureType !== 'Board' &&
                                                                            a.signatureOptions.signatureType !== 'Person' ? (
                                                                                <p className="caption">Valfritt</p>
                                                                            ) : null}
                                                                        </React.Fragment>
                                                                    ) : null}
                                                                    {a.signatureOptions.fullySigned ? (
                                                                        <span className="caption">Avtalet är signerat av alla parter</span>
                                                                    ) : null}
                                                                </div>
                                                                <div className="agreement-list__panel">
                                                                    <button
                                                                        className="button button--secondary agreement-list__button"
                                                                        onClick={this.chooseAgreement.bind(this, a)}
                                                                    >
                                                                        Gå till avtal
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <p className="paragraph">
                                                Kunde inte hitta några avtal. Detta kan bero på att du inte har några avtal hos oss, eller ett systemfel.
                                            </p>
                                            <button onClick={this.retryFetch.bind(this)} className="button button--secondary">
                                                Försök igen
                                            </button>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                        </Motion>
                    ) : null}

                    {(!allSigned && agreement.chosenAgreement && agreement.chosenAgreement.agreementId && this.state.animating) ||
                    (!allSigned && agreement.chosenAgreement && agreement.chosenAgreement.agreementId) ? (
                        <Motion
                            defaultStyle={agreement.chosenAgreement.agreementId ? { opacity: 0, transform: 15 } : { opacity: 1, transform: 0 }}
                            style={
                                !agreement.chosenAgreement.agreementId
                                    ? { opacity: spring(0, animationConfig), transform: spring(15, animationConfig) }
                                    : { opacity: spring(1, animationConfig), transform: spring(0, animationConfig) }
                            }
                            onRest={this.onRest.bind(this)}
                        >
                            {(style) => (
                                <div
                                    style={
                                        this.state.animating
                                            ? {
                                                  opacity: style.opacity,
                                                  transform: `translateY(${style.transform}px)`,
                                                  transformOrigin: 'top',
                                                  position: 'absolute',
                                              }
                                            : { opacity: style.opacity, transform: `translateY(${style.transform}px)`, transformOrigin: 'top' }
                                    }
                                >
                                    <Agreement />
                                </div>
                            )}
                        </Motion>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginInformation: state.loginInformation,
        oidc: state.oidc,
        router: state.router,
        backDoor: state.backDoor,
        agreement: state.agreement,
        tasks: state.tasks,
        organisation: state.organisation,
    };
};

Sign = connect(mapStateToProps, {
    agreementInit,
    agreementChoose,
    agreementPollSignatureChanges,
    agreementMarkAsDone,
})(Sign);

export default Sign;
