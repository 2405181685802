import React, { Component } from 'react';
import { connect } from 'react-redux';
import { agreementSignInit } from '../actions';
import { Motion, spring } from 'react-motion';
import { ajax } from 'rxjs/ajax';
import Spinner from './Spinner.js';
import imageCheck from '../img/check.svg';
import imageWarning from '../img/warning.svg';
import { BankIdSign } from '@factoringgruppen/fg.ui.components';
import { isIosWebView } from '../helpers/mobile';
import { agreementSignSuccess } from '../actions';
import { isAndroid } from 'react-device-detect';

class Agreement extends Component {
    constructor(props) {
        super(props);
        this.termsView = React.createRef();
        this.ssnInput = React.createRef();
        this.openLocal = React.createRef();
        this.state = { showPopup: false };

        this.state = {
            isTermsExpanded: false,
            bankIdEndPoints: {
                initiateEndPoint: {
                    url: process.env.REACT_APP_BANKID_SIGN_URL,
                    method: 'post',
                    body: {
                        organisationNumber: this.props.organisation.organisationNumber,
                        agreementSetId: this.props.agreement.id,
                        agreementId: this.props.agreement.chosenAgreement.agreementId,
                    },
                },
                pollEndPoint: {
                    url: process.env.REACT_APP_BANKID_POLLSIGNAGREEMENT_URL,
                    method: 'post',
                    body: {
                        organisationNumber: this.props.organisation.organisationNumber,
                        agreementSetId: this.props.agreement.id,
                        agreementId: this.props.agreement.chosenAgreement.agreementId,
                        companyTaskId: this.props.tasks.tasks.find((task) => task.type === 'AgreementSigning').id,
                    },
                },
                generateRollingQrCode: {
                    url: process.env.REACT_APP_BANKID_GENERATE_ROLLING_QR_CODE_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
                signBankIdCompleted: false,
            },
            redirectUrl: isIosWebView() ? process.env.REACT_APP_BANKID_MYPAGES_IOS_APP_REDIRECT_URL : process.env.REACT_APP_BANKID_REDIRECT_URL,
        };
        // Find bid data and store it if present.
        let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
        if (ssData) {
            this.state.bid = ssData.bid;
        }
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    expandView(event) {
        event.preventDefault();
        this.setState((state) => {
            state.isTermsExpanded = !state.isTermsExpanded;
            return state;
        });
    }

    markForManualHandling(event) {
        event.preventDefault();
        // Find task from store based on type name. There should always be one of this type...
        let task = this.props.tasks.tasks.find((task) => task.type === 'AgreementSigning');
        // ... but null check because you never know.
        if (task) {
            this.setState(
                (state) => {
                    state.isLoadingManualHandling = true;
                    return state;
                },
                () => {
                    const endpoint = process.env.REACT_APP_API_URL;
                    this.subscriber = ajax
                        .post(
                            `${endpoint}api/company/markTaskForManualHandling`,
                            {
                                companyTaskId: task.id,
                            },
                            {
                                'Content-Type': 'application/json',
                            }
                        )
                        .subscribe((response) => {
                            this.setState((state) => {
                                state.isLoadingManualHandling = false;
                                return state;
                            });
                        });
                }
            );
        }
    }

    componentWillUnmount() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    componentDidMount() {
        if (this.termsView.current) {
            let el = this.termsView.current.querySelector('.terms-conditions__content');
            // Check if a scroller is needed
            let needsScroll = el.scrollHeight > el.offsetHeight ? true : false;
            if (!needsScroll) {
                this.termsView.current.classList.add('terms-conditions--small');
            }
        }
    }

    render() {
        let { chosenAgreement } = this.props.agreement;
        let { organisation } = this.props;
        let task = this.props.tasks.tasks.find((task) => task.type === 'AgreementSigning');
        let text = {
            __html: '',
        };
        let summary = {
            __html: '',
        };
        let tokens = [];
        const recFindTokens = (chosenAgreement) => {
            tokens = [...tokens, ...chosenAgreement.tokens];
            if (chosenAgreement.agreements && chosenAgreement.agreements.length > 0) {
                chosenAgreement.agreements.map((a) => {
                    recFindTokens(a);
                    return a;
                });
            }
        };
        const recRenderHtml = (chosenAgreement) => {
            if (chosenAgreement.content.text) {
                text.__html = `${text.__html} <article>${chosenAgreement.content.text}</article>`;
            }
            if (chosenAgreement.content.summary) {
                summary.__html = `${summary.__html} <article>${chosenAgreement.content.summary}</article>`;
            }
            if (chosenAgreement.agreements && chosenAgreement.agreements.length > 0) {
                chosenAgreement.agreements.map((a) => {
                    recRenderHtml(a);
                    return a;
                });
            }
        };

        if (this.props.agreement.chosenAgreement) {
            // If there is a chosen agreement, find HTML from that agreement and find all tokens in order to display them properly.
            recRenderHtml(this.props.agreement.chosenAgreement);
            recFindTokens(this.props.agreement.chosenAgreement);
        }

        let agreementTypeBoard = false;
        if (this.props.agreement.chosenAgreement.signatureOptions.signatureType === 'Board') {
            agreementTypeBoard = true;
        }
        let peopleThatHasSigned = [];
        if (this.props.agreement.chosenAgreement && this.props.agreement.chosenAgreement.signatureOptions.persons) {
            peopleThatHasSigned = this.props.agreement.chosenAgreement.signatureOptions.persons.map((person) => person.socialSecurityNumber);
        }

        return (
            <React.Fragment>
                {text.__html ? (
                    <div className={this.state.isTermsExpanded ? 'terms-conditions terms-conditions--expanded' : 'terms-conditions'} ref={this.termsView}>
                        <div className="terms-conditions__content">{text.__html ? <div dangerouslySetInnerHTML={text}></div> : null}</div>
                        <div className="terms-conditions__expand">
                            <button className="button button--small" onClick={this.expandView.bind(this)}>
                                {this.state.isTermsExpanded ? 'Minimera' : 'Expandera'}
                            </button>
                        </div>
                    </div>
                ) : null}
                {summary.__html ? (
                    <div>
                        <div dangerouslySetInnerHTML={summary}></div>
                    </div>
                ) : null}
                {tokens.filter((token) => token.visible).length > 0 ? (
                    <div className="offer">
                        <ul className="offer__list">
                            {tokens.map((token, index) => {
                                if (token.visible) {
                                    return (
                                        <li className="offer__item" key={index}>
                                            <p className="caption">{token.label}</p>
                                            <p className="title title--h3">{token.value}</p>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ul>
                    </div>
                ) : null}
                <h2 className="title title--h2">
                    <img className="title__img title__img--large" src={require('../img/bank-id-logo.svg').default} alt="Bank ID logotype" />
                    Signera med BankID
                </h2>
                <p className="paragraph">
                    Godkänn avtalet genom att signera med BankID. Din signatur och personliga information kommer att lagras enligt den sekress som anges i
                    avtalet ovan.
                </p>
                <div className="signature">
                    <div className="signature__panel signature__panel--full">
                        <h3 className="title title--h3">Andra signaturer</h3>
                        {agreementTypeBoard ? (
                            <p className="paragraph">
                                Om det finns fler firmatecknare i företaget behöver samtliga signera avtalet. Personerna nedan kan antingen signera direkt här
                                eller gå in på registrering.fg.se och logga in med BankID.
                            </p>
                        ) : null}
                        {chosenAgreement.signatureOptions.persons && chosenAgreement.signatureOptions.persons.length > 0 ? (
                            <p>Personer som har signerat avtalet</p>
                        ) : null}
                        {chosenAgreement.signatureOptions.persons && chosenAgreement.signatureOptions.persons.length <= 0 ? (
                            <p className="caption">Ingen har signerat detta avtal ännu.</p>
                        ) : null}
                    </div>
                    {organisation.directorship && organisation.directorship.firmSigning && agreementTypeBoard ? (
                        <div className="signature__panel signature__panel--full signature__panel--firm-signing">
                            <h3 className="title title--h4">Firmateckning</h3>
                            <ul className="signature__firm-signing">
                                {organisation.directorship.firmSigning.map((row, index) => {
                                    return (
                                        <li className="signature__firm-signing-item" key={index}>
                                            {row}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : null}
                    {organisation.directorship && organisation.directorship.members && agreementTypeBoard
                        ? organisation.directorship.members
                              .filter((m) => m.canSign === true)
                              .map((member, index) => {
                                  let signatureIndex = -1;
                                  if (chosenAgreement.signatureOptions && chosenAgreement.signatureOptions.persons) {
                                      signatureIndex = chosenAgreement.signatureOptions.persons.findIndex((person) => {
                                          return person.socialSecurityNumber === member.socialSecurityNumber;
                                      });
                                  }
                                  let person = {};
                                  if (signatureIndex > -1) {
                                      person = chosenAgreement.signatureOptions.persons[signatureIndex];
                                      peopleThatHasSigned.splice(
                                          peopleThatHasSigned.findIndex((ssn) => ssn === person.socialSecurityNumber),
                                          1
                                      );
                                  }
                                  return (
                                      <div className="signature__panel signature__panel--card" key={index}>
                                          <div className="signature__card">
                                              <div className="signature__card-info">
                                                  <h3 className="title title--h4">{member.name}</h3>
                                                  {member.functions ? <p className="caption">{member.functions.map((f) => f.description).join(', ')}</p> : null}
                                                  {person.email || person.phone ? <p className="paragraph">{person.email || person.phone}</p> : null}
                                              </div>
                                              {person.hasSigned ? (
                                                  <div className="signature__card-info signature__card-info--full">
                                                      <img className="signature__check" src={imageCheck} alt="check" />
                                                      <span className="signature__date">
                                                          Signerade {Intl.DateTimeFormat('se-sv').format(new Date(person.signedAt))}
                                                      </span>
                                                  </div>
                                              ) : (
                                                  <div className="signature__card-info signature__card-info--full">
                                                      <span className="signature__date">Ej signerat</span>
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                  );
                              })
                        : null}
                    {!agreementTypeBoard && chosenAgreement.signatureOptions.persons
                        ? chosenAgreement.signatureOptions.persons.map((person, index) => {
                              peopleThatHasSigned.splice(
                                  peopleThatHasSigned.findIndex((ssn) => ssn === person.socialSecurityNumber),
                                  1
                              );

                              return (
                                  <div className="signature__panel signature__panel--card" key={index}>
                                      <div className="signature__card">
                                          <div className="signature__card-info">
                                              <h3 className="title title--h4">{person.name}</h3>
                                              {person.functions ? <p className="caption">{person.functions.map((f) => f.description).join(', ')}</p> : null}
                                              {person.email || person.phone ? <p className="paragraph">{person.email || person.phone}</p> : null}
                                          </div>
                                          <div className="signature__card-info signature__card-info--full">
                                              <img className="signature__check" src={imageCheck} alt="check" />
                                              <span className="signature__date">
                                                  Signerade {Intl.DateTimeFormat('se-sv').format(new Date(person.signedAt))}
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                    {peopleThatHasSigned.length > 0
                        ? peopleThatHasSigned.map((ssn, index) => {
                              let signatureIndex = -1;
                              if (chosenAgreement.signatureOptions && chosenAgreement.signatureOptions.persons) {
                                  signatureIndex = chosenAgreement.signatureOptions.persons.findIndex((person) => {
                                      return person.socialSecurityNumber === ssn;
                                  });
                              }
                              let person = {};
                              if (signatureIndex > -1) {
                                  person = chosenAgreement.signatureOptions.persons[signatureIndex];
                              }
                              return (
                                  <div className="signature__panel signature__panel--card" key={index}>
                                      <div className="signature__card">
                                          <div className="signature__card-info">
                                              <h3 className="title title--h4">{person.name}</h3>
                                              {person.functions ? <p className="caption">{person.functions.map((f) => f.description).join(', ')}</p> : null}
                                              {person.email || person.phone ? <p className="paragraph">{person.email || person.phone}</p> : null}
                                          </div>
                                          <div className="signature__card-info signature__card-info--full">
                                              <img className="signature__check" src={imageCheck} alt="check" />
                                              <span className="signature__date">
                                                  Signerade {Intl.DateTimeFormat('se-sv').format(new Date(person.signedAt))}
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                    {organisation.directorship != null && organisation.directorship.containsVacancyText ? (
                        task && task.needsManualHandling ? (
                            <div className="signature__panel signature__panel--full signature__panel--firm-signing">
                                <img src={imageWarning} className="warning-icon warning-icon--small" alt="varningsikon" />
                                <h3 className="title title--h3">Signaturer granskas manuellt</h3>
                                <p className="paragraph">Era signaturer är under granskning. Vi kommer höra av oss till er när allt är klart.</p>
                            </div>
                        ) : (
                            <div className="signature__panel signature__panel--full signature__panel--firm-signing">
                                <h3 className="title title--h3">
                                    <img className="title__img" src={imageWarning} alt="varningsikon" />
                                    Vi kan inte tolka er firmateckning automatiskt
                                </h3>
                                <p className="paragraph">
                                    Säkerställ att alla som skall signera avtalet har signerat. Anmäl sedan för manuell granskning genom att klicka på knappen
                                    nedan. När du gjort detta hör vi av oss till er via den e-post eller det telefonnummer du angivit tidigare.
                                </p>
                                <div className="signature__panel signature__panel--full signature__panel--bottom">
                                    <button
                                        onClick={this.markForManualHandling.bind(this)}
                                        disabled={this.state.isLoadingManualHandling}
                                        className="button button--action button--full"
                                    >
                                        Anmäl för manuell granskning
                                    </button>
                                    {this.state.isLoadingManualHandling ? (
                                        <Motion defaultStyle={{ opacity: 0, width: 0 }} style={{ opacity: spring(1), width: spring(50) }}>
                                            {(value) => (
                                                <div style={{ opacity: value.opacity, width: `${value.width}px` }} className="signature__loader">
                                                    <Spinner />
                                                </div>
                                            )}
                                        </Motion>
                                    ) : null}
                                </div>
                            </div>
                        )
                    ) : null}
                    <div className="signature__panel signature__panel--full">
                        <div className="form">
                            {this.props.agreement.status ? (
                                <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                                    {(value) => (
                                        <div
                                            className="form__async-status"
                                            style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                        >
                                            {this.props.agreement.status.userHasSigned ? (
                                                <img className="signature__check animate-pulse" src={imageCheck} alt="check" />
                                            ) : null}
                                            {this.props.agreement.status.message}
                                        </div>
                                    )}
                                </Motion>
                            ) : null}
                            {this.state.ssnError ? <div className="form__async-status form__async-status--error">{this.state.ssnErrorMessage}</div> : null}
                        </div>
                    </div>
                    <div className="signature__panel signature__panel--full signature__panel--bottom">
                        {this.props.agreement.status && this.props.agreement.status.isLoading ? (
                            <Motion defaultStyle={{ opacity: 0, width: 0 }} style={{ opacity: spring(1), width: spring(50) }}>
                                {(value) => (
                                    <div style={{ opacity: value.opacity, width: `${value.width}px` }} className="signature__loader">
                                        <Spinner />
                                    </div>
                                )}
                            </Motion>
                        ) : null}
                    </div>
                </div>
                <div className="bankId_wrapper">
                    {!this.state.signBankIdCompleted ? (
                        <BankIdSign
                        handleCompleted={()=>{}}
                            title={''}
                            description={''}
                            onBankIdComplete={(id) => {
                                id != null ? this.setState({ signBankIdCompleted: true }) : this.setState({ signBankIdCompleted: false });
                                this.props.agreementSignSuccess(id);
                            }}
                            bankIdEndPoints={this.state.bankIdEndPoints}
                            redirectUrl={isAndroid ? null : this.state.redirectUrl}
                            orString={'eller '}
                            buttonStringSameDevice={isIosWebView() ? 'Signera med BankID' : 'Öppna BankID på denna enheten'}
                            preventRedirect={isIosWebView()}
                            styles={{ useRoundedCorner: true }}
                        />
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginInformation: state.loginInformation,
        oidc: state.oidc,
        router: state.router,
        backDoor: state.backDoor,
        agreement: state.agreement,
        tasks: state.tasks,
        organisation: state.organisation,
    };
};

Agreement = connect(mapStateToProps, {
    agreementSignInit,
    agreementSignSuccess,
})(Agreement);

export default Agreement;
