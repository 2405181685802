import React, { Component } from 'react';
import { connect } from 'react-redux';

import { kycChooseChips } from '../actions';

// Select input field for KYC
class KycSelect extends Component {
    constructor(props) {
        super(props);

        let { inputObj } = props;
        inputObj.meta.options.map((d) => (d.isChosen = false));
        if (inputObj.answer && inputObj.answer.length > 0) {
            for (let answer of inputObj.answer) {
                if (inputObj.meta && inputObj.meta.options) {
                    inputObj.meta.options = inputObj.meta.options.map((d) => {
                        if (d.id === answer.id) {
                            // Is match in answers is found, change to chosen
                            d.isChosen = true;
                        } else if (!d.isChosen) {
                            // Make sure there is a value
                            d.isChosen = false;
                        }
                        return d;
                    });
                }
            }
        } else {
            inputObj.answer = [{ id: '' }];
        }
        this.state = {
            inputObj: inputObj,
            isValid: true,
        };
    }

    validateSelections() {
        if (this.state.inputObj.meta.model.validation.mandatory && this.state.inputObj.answer && this.state.inputObj.answer.length === 0) {
            this.setState((state) => {
                state.isValid = false;
                return state;
            });
        } else if (this.state.inputObj.meta.model.validation.mandatory && this.state.inputObj.answer && this.state.inputObj.answer.length > 0) {
            this.setState((state) => {
                state.isValid = true;
                return state;
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isValid !== nextProps.inputObj.isValid) {
            this.setState((state) => {
                state.isValid = nextProps.inputObj.isValid;
                return state;
            });
        }
    }

    handleSelction(event) {
        let { inputObj } = this.state;
        let index = inputObj.meta.options.findIndex((option) => option.id === event.target.value);
        let option = this.state.inputObj.meta.options[index];

        this.setState(
            (state) => {
                state.inputObj.answer[0] = state.inputObj.meta.options[index];
                return state;
            },
            () => {
                this.validateSelections();
            }
        );
        this.props.kycChooseChips(inputObj, option);
    }

    render() {
        let { inputObj, isValid } = this.state;
        let { kyc } = this.props;
        let selectClasses = () => {
            let className = 'select';
            if (typeof isValid !== 'undefined' && !isValid) {
                className += ' select--error';
            }
            if (kyc.completed) {
                className += ' select--disabled';
            }
            return className;
        };
        return (
            <div className="kyc__select">
                <div className="form">
                    <p className="kyc__label">{inputObj.question}</p>

                    <label htmlFor={inputObj.id} className={selectClasses()}>
                        <select
                            disabled={kyc.completed}
                            className="select__box"
                            value={inputObj.answer[0].id}
                            onChange={this.handleSelction.bind(this)}
                            id={inputObj.id}
                            name={inputObj.id}
                        >
                            <option className="select__option" hidden value="">
                                Välj ett alternativ
                            </option>
                            {inputObj.meta.options.map((a, index) => {
                                return (
                                    <option className="select__option" key={a.id} name={a.parentQuestionId} value={a.id}>
                                        {a.text}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        kyc: state.kyc,
    };
};

KycSelect = connect(mapStateToProps, {
    kycChooseChips,
})(KycSelect);

export default KycSelect;
