import React, { Component } from 'react';
import { connect } from 'react-redux';

import FaqQuestion from './Faq-question';

// The FAQ component shows and toggles FAQs on the start page
class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            faqObj: [],
        };
    }

    componentWillMount() {
        let onboardingFaqList = [];
        let header = '';
        if (this.props.type === 0) {
            header = 'Få betalt på dina villkor, och slipp krångel';
            onboardingFaqList = [
                {
                    id: 0,
                    img: '/img/add-invoice.svg',
                    title: '1. Skapa din faktura',
                    desc: 'Du gör precis som vanligt när du skapar en faktura. Ni skickar sedan fakturan till oss och sedan tar vi hand om resten.',
                    faq: [
                        {
                            id: 0,
                            question: 'Ska jag skicka fakturan själv till min kund om jag använder tjänsten?',
                            answer: 'Nej det ska du inte göra. Factoringgruppen kommer att skicka den till din fakturamottagare.',
                        },
                        {
                            id: 1,
                            question: 'Kommer min faktura se annorlunda ut när den når min mottagare?',
                            answer: 'I de flesta fall är det ingen skillnad förutom att det påförs en instruktion på fakturan om vart betalning skall gå samt att den är överlåten till oss. Be gärna din kontaktperson visa exempel för hur det kan se ut.',
                        },
                        {
                            id: 2,
                            question: 'Kan jag koppla mitt affärssystem till er för automatisk överföring av fakturor?',
                            answer: 'Ja det går utmärkt. I dagsläget har vi stöd för FortNox. Kontakta din kundansvarige säljare för mer information.',
                        },
                    ],
                },
                {
                    id: 1,
                    img: '/img/check-in-box.svg',
                    title: '2. Hantering av fakturorna',
                    desc: 'När vi mottagit era fakturor kommer vi att hantera dessa samma dag om de inkommit innan kl 10. Skickas fakturorna efter kl 10 hanteras de oftast samma dag ändå men det finns ingen garanti.',
                    faq: [
                        {
                            id: 0,
                            question: 'Finansierar ni alla mina fakturor?',
                            answer: 'Ibland kan en fakturamottagare sakna tillräcklig kreditvärdighet. Detta kan exempelvis bero på att din fakturamottagare har fått ett antal anmärkningar i närtid som medför att den inte går att finansiera. I det här läget föreslår vi att vi administrerar fakturan åt er så hjälper vi till med kravhanteringen.',
                        },
                        {
                            id: 0,
                            question: 'Kan jag välja att Factoringgruppen bara administrerar fakturan åt oss?',
                            answer: 'Ni väljer själva i er kundportal om fakturan skall finansieras eller bara administreras.',
                        },
                        {
                            id: 0,
                            question: 'Vad innebär att en faktura enbart administreras?',
                            answer: 'Fakturan skickas ut till din fakturamottagare av Factoringgruppen och fakturabeloppet utbetalas till ert konto när er kund betalt till oss. Skulle din kund inte betala i tid så tar vi hand om påminnelser och eventuellt inkasso.',
                        },
                    ],
                },
                {
                    id: 2,
                    img: '/img/coffee.svg',
                    title: '3. Luta er tillbaka',
                    desc: 'När du har skickat över fakturorna till oss så kan du slappna av. Pengar är på väg till ert konto. ',
                    faq: [
                        {
                            id: 0,
                            question: 'Får jag något underlag efter att jag skickat fakturorna till er?',
                            answer: 'Så snart fakturorna är färdighanterade av oss så skickar vi ut ett mail med en avräkning av de fakturor som vi hanterat den dagen. Samma information kan ni hitta i er kundportal där det finns ett arkiv för alla fakturor som hanterats av oss.',
                        },
                        {
                            id: 0,
                            question: 'Hur kan jag följa mina fakturor?',
                            answer: 'När ni är kund hos oss får ni tillgång vår kundportal. Där hittar ni all information om era finansierade och administrerade fakturor. Ni hittar även information om påminda fakturor och sådana som eventuellt hanteras av vår inkassoavdelning.',
                        },
                    ],
                },
                {
                    id: 3,
                    img: null,
                    title: 'Psst! Visste du att vi även kan hantera era inkasso-ärenden?',
                    desc: 'Skulle ni behöva stöd med inkassotjänster är det enkelt att komma igång. Allt ni behöver göra är att kontakta oss så löser vi det.',
                },
            ];

            this.setState({ faqObj: { list: onboardingFaqList, header: header } });
        } else if (this.props.type === 1) {
            this.setState({ faqObj: null });
        } else {
            this.setState({ faqObj: { list: [], header: '' } });
        }
    }

    render() {
        let { faqObj } = this.state;

        if (faqObj == null) {
            return null;
        }

        return (
            <div className="faq">
                <h1 className="title title--h1">{faqObj.header}</h1>
                {faqObj.list.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="faq__section" key={item.id}>
                                <img src={item.img} alt="" className="icon" />
                                <div className="faq__section-intro">
                                    <h3 className="title title--h3">{item.title}</h3>
                                    <p className="paragraph">{item.desc}</p>
                                </div>
                            </div>
                            {item.faq ? (
                                <div className="faq__questions">
                                    <FaqQuestion faq={item.faq} />
                                </div>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

Faq = connect(mapStateToProps, {})(Faq);

export default Faq;
