export const AGREEMENT_UPDATE = 'AGREEMENT_UPDATE';
export const AGREEMENT_INIT = 'AGREEMENT_INIT';
export const AGREEMENT_INIT_ERROR = 'AGREEMENT_INIT_ERROR';
export const AGREEMENT_CHOOSE = 'AGREEMENT_CHOOSE';
export const AGREEMENT_SIGN_INITIATE = 'AGREEMENT_SIGN_INITIATE';
export const AGREEMENT_SIGN_POLL = 'AGREEMENT_SIGN_POLL';
export const AGREEMENT_POLL_STOP = 'AGREEMENT_POLL_STOP';
export const AGREEMENT_SIGN_FAIL = 'AGREEMENT_SIGN_FAIL';
export const AGREEMENT_SIGN_SUCCESS = 'AGREEMENT_SIGN_SUCCESS';
export const AGREEMENT_SIGN_STATUS = 'AGREEMENT_SIGN_STATUS';
export const AGREEMENT_SIGN_POLL_SIGNATURE_CHANGES = 'AGREEMENT_SIGN_POLL_SIGNATURE_CHANGES';
export const AGREEMENT_UPDATE_SIGNATURES = 'AGREEMENT_UPDATE_SIGNATURES';
export const AGREEMENT_MARK_AS_DONE = 'AGREEMENT_MARK_AS_DONE';

export const agreementUpdate = (agreementObj) => ({
    type: AGREEMENT_UPDATE,
    payload: agreementObj,
});

export const agreementInit = (initObj) => {
    return {
        type: AGREEMENT_INIT,
        payload: initObj,
    };
};

export const agreementInitError = (error) => {
    return {
        type: AGREEMENT_INIT_ERROR,
        payload: error,
    };
};

export const agreementChoose = (agreement) => {
    return {
        type: AGREEMENT_CHOOSE,
        payload: agreement,
    };
};

export const agreementSignInit = (ssn, agreement, shouldOpenLocal) => {
    return {
        type: AGREEMENT_SIGN_INITIATE,
        payload: {
            ssn: ssn,
            agreement: agreement,
            shouldOpenLocal: shouldOpenLocal,
        },
    };
};

export const agreementPoll = (id, type) => ({
    type: AGREEMENT_SIGN_POLL,
    payload: {
        transactionId: id,
        transactionType: type,
    },
});

export const agreementPollSignatureChanges = (id, type) => ({
    type: AGREEMENT_SIGN_POLL_SIGNATURE_CHANGES,
});

export const agreementUpdateSignatures = (id, signOptions) => ({
    type: AGREEMENT_UPDATE_SIGNATURES,
    payload: {
        agreementId: id,
        signOptions: signOptions,
    },
});

export const agreementPollStop = (id, type, status) => ({
    type: AGREEMENT_POLL_STOP,
    payload: {
        transactionId: id,
        transactionType: type,
        status: { ...status },
    },
});

export const agreementSignSuccess = (status) => ({
    type: AGREEMENT_SIGN_SUCCESS,
    payload: status,
});

export const agreementSignFail = (status) => ({
    type: AGREEMENT_SIGN_FAIL,
    payload: status,
});

export const agreementSignStatus = (status) => ({
    type: AGREEMENT_SIGN_STATUS,
    payload: status,
});

export const agreementMarkAsDone = (agreementId) => ({
    type: AGREEMENT_MARK_AS_DONE,
    payload: agreementId,
});
