import { of, concat } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { SAVE_LOAN_APPLICATION, updateLoanApplication, organisationUpdate, modalTrigger } from '../actions';

const endpoint = process.env.REACT_APP_API_URL;

export const saveLoanApplicationEpic = (action$, store) =>
    action$.ofType(SAVE_LOAN_APPLICATION).pipe(
        switchMap((action) => {
            // Tells backend what account has been chosen
            return ajax
                .post(`${endpoint}api/loan/createLoanApplication`, action.payload.loanDetails, {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                    'organisation-number': store.value.organisation.organisationNumber,
                })
                .pipe(
                    switchMap((data) => {
                        sessionStorage.removeItem('commitment');
                        sessionStorage.removeItem('loanapplication');
                        return concat(
                            of(updateLoanApplication({ success: true, status: 'OK' })),
                            of(organisationUpdate({ showContactInfoModal: false })),
                            of(modalTrigger(false))
                        );
                    }),
                    catchError((error) => {
                        return of(
                            updateLoanApplication({
                                success: false,
                                status: 'Ett fel uppstod, försök gärna igen. Om problemet kvarstår, kontakta oss.',
                            })
                        );
                    })
                );
        })
    );
