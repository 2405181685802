import React from 'react';
import { Motion, spring } from 'react-motion';

import { vanishConfig } from '../AnimationSettings';

// Image for 404 page.
export const ErrorImg = () => {
    return (
        <Motion defaultStyle={{ flexBasis: 0, opacity: 0 }} style={{ flexBasis: spring(100, vanishConfig), opacity: spring(1) }}>
            {(style) => (
                <div
                    className="content__image content__image--error"
                    style={{
                        flexBasis: `${style.flexBasis}%`,
                        opacity: `${style.opacity}`,
                    }}
                >
                    <img src={require('../img/error-person.png').default} alt="en person med ryggen vänd mot kameran" className="content__error-image" />
                </div>
            )}
        </Motion>
    );
};
