export const ORGANISATION_UPDATE = 'ORGANISATION_UPDATE';
export const ORGANISATION_GET_COMMITMENTS = 'ORGANISATION_GET_COMMITMENTS';
export const ORGANISATION_CHOOSE_COMMITMENTS = 'ORGANISATION_CHOOSE_COMMITMENTS';
export const ORGANISATION_GET_TASKS = 'ORGANISATION_GET_TASKS';
export const ORGANISATION_CREATE_ONBOARDING = 'ORGANISATION_CREATE_ONBOARDING';
export const ORGANISATION_CREATE_DYNAMICPRICING_ONBOARDING = 'ORGANISATION_CREATE_DYNAMICPRICING_ONBOARDING';
export const ORGANISATION_ADD_CONTACT_DETAILS = 'ORGANISATION_ADD_CONTACT_DETAILS';
export const ORGANISATION_GET_DIRECTORSHIP = 'ORGANISATION_GET_DIRECTORSHIP';
export const BOOK_HELP_FROM_SUPPORT = 'BOOK_HELP_FROM_SUPPORT';

export const organisationUpdate = (organisation) => ({
    type: ORGANISATION_UPDATE,
    payload: organisation,
});

export const organisationGetCommitments = () => ({
    type: ORGANISATION_GET_COMMITMENTS,
});

export const organisationChooseCommitment = (commitment) => ({
    type: ORGANISATION_CHOOSE_COMMITMENTS,
    payload: commitment,
});

export const organisationGetTasks = (contactDetailsSaved = false) => ({
    type: ORGANISATION_GET_TASKS,
    contactDetailsSaved: contactDetailsSaved,
});

export const organisationCreateOnboarding = (commitment, productType) => ({
    type: ORGANISATION_CREATE_ONBOARDING,
    payload: {
        commitment: commitment,
        productType: productType,
    },
});

export const organisationCreateDynamicPricingOnboarding = (commitment, productType, provision, limitCharge, volume, turnoverMonth) => ({
    type: ORGANISATION_CREATE_DYNAMICPRICING_ONBOARDING,
    payload: {
        commitment: commitment,
        productType: productType,
        provision: provision,
        limitCharge: limitCharge,
        volume: volume,
        turnoverMonth: turnoverMonth,
    },
});

export const organisationAddContactDetails = (contactName, contactEmailAddress, contactPhoneNumber) => ({
    type: ORGANISATION_ADD_CONTACT_DETAILS,
    payload: {
        contactName: contactName,
        contactEmailAddress: contactEmailAddress,
        contactPhoneNumber: contactPhoneNumber,
    },
});

export const organisationGetDirectorship = () => ({
    type: ORGANISATION_GET_DIRECTORSHIP,
});

export const bookHelpFromSupport = (contactName, contactEmailAddress, contactPhoneNumber, volume) => ({
    type: BOOK_HELP_FROM_SUPPORT,
    payload: {
        contactName: contactName,
        contactEmailAddress: contactEmailAddress,
        contactPhoneNumber: contactPhoneNumber,
        volume: volume,
    },
});
