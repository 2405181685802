import { BACKDOOR_UPDATE, BACKDOOR_FAIL, BACKDOOR_INIT } from '../actions/index';

const initialState = {
    overrideProtectedRoutes: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case BACKDOOR_INIT:
            return {
                id: action.payload.id,
                ...state,
            };
        case BACKDOOR_UPDATE:
            return { ...state, ...action.payload };
        case BACKDOOR_FAIL:
            let stateWithError = {
                ...state,
                error: action.payload,
            };
            return stateWithError;
        default:
            return { ...state };
    }
}
