import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalTrigger } from '../actions';

import UserHandler from './UserHandler';
import ChooseCommitment from './modals/ChooseCommitment';
import { createLoanApplication } from '../actions';

// This component shows what commitment has been chosen
class SessionHandler extends Component {
    constructor(props) {
        super(props);
        let loanapplicationString = sessionStorage.getItem('loanapplication') || '';
        if (loanapplicationString !== '') {
            let loanApplicationObj = JSON.parse(loanapplicationString);
            this.props.createLoanApplication(loanApplicationObj);
        }
    }

    openCommitment(event) {
        event.preventDefault();
        this.props.modalTrigger(true, ChooseCommitment);
    }
    render() {
        let { oidc, organisation, tasks } = this.props;
        if (tasks.callbackUrl && tasks.callbackLinkText && !this.props.loan.isLoanApplication) {
            return (
                <div className="session-handler session-handler--callback">
                    <div className="session-handler__content">
                        <UserHandler smallVersion={true} />
                    </div>
                    <div className="session-handler__content">
                        <h4 className="title title--h4">Du verkar vara en kund till vår partner</h4>
                        <p className="paragraph paragraph--small">
                            Vad kul att du hittat till oss! Välkommen till vardagsmagin hos oss på Factoringgruppen. Du är nu ett steg närmare till smart
                            cashflow.
                        </p>
                        <a className="button button--secondary button--small" href={tasks.callbackUrl}>
                            {tasks.callbackLinkText}
                        </a>
                    </div>
                </div>
            );
        }
        if (organisation.organisationNumber.length === 0 || !oidc.user) {
            return null;
        }

        if (!this.props.loan.isLoanApplication) {
            return (
                <div className="session-handler">
                    <button className="session-handler__button" onClick={this.openCommitment.bind(this)}>
                        <img src={require('../img/up.svg').default} alt="pil som pekar uppåt" />
                    </button>
                    <div className="session-handler__content">
                        <UserHandler smallVersion={true} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        loading: state.loading,
        organisation: state.organisation,
        tasks: state.tasks,
        loan: state.loan,
    };
};

SessionHandler = connect(mapStateToProps, {
    modalTrigger,
    createLoanApplication,
})(SessionHandler);

export default SessionHandler;
