import React, { Component } from 'react';
import { Route, NavLink, Switch } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { Motion, spring } from 'react-motion';
import { connect } from 'react-redux';
import './styles.scss';

import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { withRouter } from 'react-router-dom';
import {
    agreementInit,
    kycTrigger,
    getChosenAccount,
    backDoorInit,
    agreementChoose,
    modalTrigger,
    organisationUpdate,
    loadingUpdate,
    createLoanApplication,
} from './actions';

// Config
import { slide, vanish, vanishConfig } from './AnimationSettings';
import { routes } from './RouteSettings';

// Helpers
import scrollTo from './helpers/scrollTo';

// Components
import Navigation from './components/Navigation';
import ValidateAccount from './components/ValidateAccount';
import Sign from './components/Sign';
import Kyc from './components/Kyc';
import Spinner from './components/Spinner';

// Subcomponents
import { Error } from './components/Error';
import { ErrorImg } from './components/ErrorImg';
import ProtectedRoute from './components/ProtectedRoute';
import RouteImage from './components/RouteImage';
import Modal from './components/Modal';
import ChooseCommitment from './components/modals/ChooseCommitment';
import SessionHandler from './components/SessionHandler';

// Private global vars
let firstHit;

// App component
class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            routes: routes,
        };
        this.scrollEl = React.createRef();

        let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
        if (ssData && ssData.date < new Date().setMinutes(-60)) {
            sessionStorage.clear();
            return;
        }
        if (ssData && ssData.bid) {
            this.props.backDoorInit({ id: ssData.bid });
        }
    }

    closeRoutes() {
        let closestRoutes = {
            previous: {},
            next: {},
        };
        let { router } = this.props;
        let routesToRender = this.state.routes.filter((route) => !route.isHidden && route.includeInSteps);
        let activeRouteIndex = routesToRender.findIndex((route) => route.name === router.location.pathname);
        closestRoutes = {
            previous: routesToRender[activeRouteIndex - 1] || {},
            next: routesToRender[activeRouteIndex + 1] || {},
        };
        return closestRoutes;
    }

    componentDidMount() {
        this.mutationSub = new Subject();
        const scrollObs = merge(
            fromEvent(window, 'scroll', true).pipe(debounceTime(25)),
            fromEvent(window, 'resize', true).pipe(debounceTime(25)),
            this.mutationSub.pipe(debounceTime(25))
        );
        this.scrollSub = scrollObs.subscribe((e) => {
            this.findAndSetScrollState();
        });
    }

    componentWillUnmount() {
        if (this.scrollSub) {
            this.scrollSub.unsubscribe();
        }
        if (this.mutationObs) {
            this.mutationObs.disconnect();
        }
    }

    componentDidUpdate(prevProps) {
        let { backDoor, tasks, router, oidc, organisation, modal } = this.props;
        let prevBackDoor = prevProps.backDoor;

        // Create mutation observer for finding out if page is scrollable or not
        if (!this.mutationObs && this.scrollEl.current && this.mutationSub) {
            this.mutationObs = new MutationObserver(() => {
                this.mutationSub.next();
            });
            this.mutationObs.observe(this.scrollEl.current, { attributes: true, childList: true, subtree: true });
        }

        // Find out if there is changes to backdoor or not and if protected routes should be overridden or not
        if (JSON.stringify(backDoor) !== JSON.stringify(prevBackDoor) && backDoor.overrideProtectedRoutes) {
            let taskTypeToRouteMapper = {
                agreementsigning: Sign,
                bankaccountvalidation: ValidateAccount,
                kyc: Kyc,
            };
            let routes = [...this.state.routes];
            routes = routes.map((route) => {
                tasks.tasks.map((task) => {
                    if (route.component === taskTypeToRouteMapper[task.type.toLowerCase()]) {
                        if (!firstHit && route.includeInSteps) {
                            firstHit = route;
                        }
                    }
                    return task;
                });
                return route;
            });
            this.setState(
                (state) => {
                    state.routes = routes;
                    return state;
                },
                () => {
                    let currentIndex = this.state.routes.findIndex((route) => {
                        if (route.name.split('/')[1].length > 0) {
                            return router.location.pathname.indexOf(route.name.split('/')[1]) > -1;
                        }
                        return false;
                    });
                    if (currentIndex === -1 || !firstHit) {
                        // If no route has been found or current page is an error page, dont do anything.
                    }
                    else if (this.state.routes[currentIndex] && this.state.routes[currentIndex].name && !this.state.routes[currentIndex].isHidden) {
                        this.props.history.push({ pathname: this.state.routes[currentIndex].name });
                    } else {
                        this.props.history.push({ pathname: '/hem' });
                    }
                }
            );
        }

        if (oidc.user && !oidc.isLoadingUser && organisation && !organisation.organisationNumber) {
            let ssCommitment = sessionStorage.getItem('commitment');
            if (ssCommitment) {
                this.props.organisationUpdate(JSON.parse(ssCommitment));
                return; //Don't continue until we have all the info about organisation
            }
        }

        if (
            oidc &&
            !oidc.isLoadingUser &&
            oidc.user &&
            !modal.isOpen &&
            organisation &&
            ((!organisation.organisationNumber && !organisation.isLoading) || organisation.showContactInfoModal)
        ) {
            this.props.modalTrigger(true, ChooseCommitment, true);
        } else if (!oidc.user && !oidc.isLoadingUser && !modal.isOpen && organisation && organisation.showContactInfoModal && !organisation.isLoading) {
            this.props.modalTrigger(true, ChooseCommitment, true);
        }
    }

    chooseAgreement(agreement, event) {
        event.preventDefault();
        this.props.agreementChoose(agreement);
    }

    findAndSetScrollState() {
        let switchWrapper = this.scrollEl.current.querySelector('.content__main .switch-wrapper');
        let contentWrapper = this.scrollEl.current.querySelector('.content');
        if (!switchWrapper || !contentWrapper) {
            return;
        }
        this.setState((state) => {
            let scrollEl;
            if (switchWrapper.scrollHeight > switchWrapper.clientHeight) {
                scrollEl = switchWrapper;
            } else if (contentWrapper.scrollHeight > contentWrapper.clientHeight) {
                scrollEl = contentWrapper;
            }
            if (scrollEl) {
                state.isFullyScrolled = scrollEl.getBoundingClientRect().height + scrollEl.scrollTop === scrollEl.scrollHeight;
            } else {
                state.isFullyScrolled = true;
            }
            return state;
        });
    }

    scrollClickHandler(event) {
        let switchWrapper = this.scrollEl.current.querySelector('.content__main .switch-wrapper');
        let contentWrapper = this.scrollEl.current.querySelector('.content');
        if (!switchWrapper || !contentWrapper) {
            return;
        }
        let scrollEl;
        if (switchWrapper.scrollHeight > switchWrapper.clientHeight) {
            scrollEl = switchWrapper;
        } else if (contentWrapper.scrollHeight > contentWrapper.clientHeight) {
            scrollEl = contentWrapper;
        }
        scrollTo(scrollEl, scrollEl.scrollTop + window.innerHeight * 0.7, 500);
    }

    render() {
        let { oidc, organisation, backDoor, loading, agreement } = this.props;
        let { routes } = this.state;
        let closestRoutes = this.closeRoutes();
        let routeNames = '';
        let currentRoute;

        routes.map((route) => {
            if (this.props.location.pathname === route.name || this.props.location.pathname.startsWith(route.name)) {
                currentRoute = route;
            }
            if (route.alignment === 'right') {
                return route;
            }
            if (route.name !== '/') {
                // Remove first char, i.e. the initial slash
                let name = route.name.substr(1);

                if (name.indexOf(':') > -1) {
                    // Apparantly params can't be in the joined string
                    name = name.split(':')[0];
                }
                if (name.indexOf('/') > -1) {
                    // Only keep the first part if it contains a slash
                    name = name.split('/')[0];
                }
                routeNames = `${routeNames}|${name}`;
            }
            return route;
        });
        routeNames = `/(${routeNames})`;
        let isHalfSize = currentRoute && currentRoute.size && currentRoute.size === 'half';
        let imgFlexBasis = isHalfSize ? 50 : 33.333;
        let hasNav =
            (oidc.user || backDoor.overrideProtectedRoutes) &&
            this.props.tasks.tasks &&
            this.props.tasks.tasks.length > 0 &&
            this.props.router.location.pathname !== '/hem';

        return (
            <Modal>
                <div className="page" ref={this.scrollEl}>
                    <div className="content">
                        {typeof this.state.isFullyScrolled === 'boolean' && !this.state.isFullyScrolled ? (
                            <img
                                onClick={this.scrollClickHandler.bind(this)}
                                className="content__scroll-arrow content__scroll-arrow--active"
                                src={require('./img/scroll-hand.svg').default}
                                alt="En hand som swipear upp och ner ovanpå en pil"
                            />
                        ) : (
                            <img
                                className="content__scroll-arrow"
                                src={require('./img/scroll-hand.svg').default}
                                alt="En hand som swipear upp och ner ovanpå en pil"
                            />
                        )}
                        <Route
                            path={routeNames}
                            render={(props) => (
                                <Motion defaultStyle={{ flexBasis: 0 }} style={{ flexBasis: spring(imgFlexBasis, vanishConfig) }}>
                                    {(style) => (
                                        <div className="content__image" style={{ flexBasis: `${style.flexBasis}%` }}>
                                            <SessionHandler />
                                            <NavLink to={this.props.loan.isLoanApplication ? '/lån' : '/hem'}>
                                                <img
                                                    style={{ marginLeft: '32px', marginTop: '32px' }}
                                                    className="content__logo"
                                                    src={require('./img/Logo-FG-RGB-white.svg').default}
                                                    alt="fg logotype"
                                                />
                                            </NavLink>
                                            <AnimatedSwitch
                                                atEnter={vanish.atEnter}
                                                atLeave={vanish.atLeave}
                                                atActive={vanish.atActive}
                                                className="switch-wrapper"
                                                mapStyles={vanish.mapStyles}
                                            >
                                                {routes.map((route, index) => {
                                                    if (route.alignment === 'right') {
                                                        return null;
                                                    }
                                                    return (
                                                        <Route
                                                            path={route.name}
                                                            exact
                                                            key={index}
                                                            render={(props) => (
                                                                <RouteImage
                                                                    {...{ props: props, imgAlt: route.imgAlt, imageUrlSource: route.imgUrlSource }}
                                                                    imageUrlSource={route.imgUrlSource}
                                                                />
                                                            )}
                                                        />
                                                    );
                                                })}
                                            </AnimatedSwitch>
                                        </div>
                                    )}
                                </Motion>
                            )}
                        />
                        <div className={isHalfSize ? 'content__main content__main--half' : 'content__main'}>
                            {oidc.isLoadingUser || loading.isLoading || (this.props.tasks.tasks.length === 0 && this.props.modal.isOpen) ? (
                                <div className="content__spinner">
                                    <Spinner />
                                </div>
                            ) : (
                                <React.Fragment>
                                    {hasNav ? (
                                        <div className="content__navigation">
                                            <Navigation routes={routes} />
                                        </div>
                                    ) : null}
                                    <AnimatedSwitch
                                        atEnter={slide.atEnter}
                                        atLeave={slide.atLeave}
                                        atActive={slide.atActive}
                                        className={hasNav ? 'switch-wrapper' : 'switch-wrapper content__no-nav'}
                                        mapStyles={slide.mapStyles}
                                    >
                                        {routes.map(({ name, component, isExact, isProtected }, index) => {
                                            if (isProtected) {
                                                return <ProtectedRoute exact={isExact} key={index} path={name} component={component} />;
                                            }
                                            return <Route path={name} exact={isExact} key={index} component={component} />;
                                        })}
                                        <Route render={(props) => <Error {...props} firstHit={firstHit} />} />
                                    </AnimatedSwitch>
                                    {(oidc.user && organisation.organisationNumber) || backDoor.overrideProtectedRoutes ? (
                                        <React.Fragment>
                                            {agreement.chosenAgreement.agreementId && agreement.agreements.length > 1 ? (
                                                <div
                                                    className={
                                                        agreement.chosenAgreement.signatureOptions.fullySigned
                                                            ? 'step step--agreement step--signed'
                                                            : 'step step--agreement'
                                                    }
                                                >
                                                    <div className="agreement-list">
                                                        <div className="agreement-list__item">
                                                            <div className="agreement-list__panel">
                                                                <p className="title title--h4">{agreement.chosenAgreement.name}</p>
                                                                {agreement.chosenAgreement.signatureOptions ? (
                                                                    <React.Fragment>
                                                                        {agreement.chosenAgreement.signatureOptions.signatureType === 'Board' ? (
                                                                            <p className="caption">Kräver firmateckning</p>
                                                                        ) : null}
                                                                        {agreement.chosenAgreement.signatureOptions.signatureType === 'Person' ? (
                                                                            <p className="caption">Kräver signatur från person(er)</p>
                                                                        ) : null}
                                                                        {agreement.chosenAgreement.signatureOptions.signatureType === 'None' ? (
                                                                            <p className="caption">Ingen signatur krävs</p>
                                                                        ) : null}
                                                                        {agreement.chosenAgreement.signatureOptions.signatureType !== 'Board' &&
                                                                        agreement.chosenAgreement.signatureOptions.signatureType !== 'Person' ? (
                                                                            <p className="caption">Valfritt</p>
                                                                        ) : null}
                                                                    </React.Fragment>
                                                                ) : null}
                                                            </div>
                                                            <div className="agreement-list__panel">
                                                                <button
                                                                    className="button button--alt button--small"
                                                                    onClick={this.chooseAgreement.bind(this, {})}
                                                                >
                                                                    Fortsätt
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : this.props.tasks.tasks && this.props.tasks.tasks.length > 1 ? (
                                                <div className="step">
                                                    {closestRoutes.previous.name ? (
                                                        <NavLink className="step__link" activeClassName="" to={closestRoutes.previous.name}>
                                                            {closestRoutes.previous.linkText}
                                                        </NavLink>
                                                    ) : (
                                                        <div className="step__link step__link--disabled"></div>
                                                    )}
                                                    {closestRoutes.next.name ? (
                                                        <NavLink
                                                            className="step__link step__link step__link--next"
                                                            activeClassName=""
                                                            to={closestRoutes.next.name}
                                                        >
                                                            {closestRoutes.next.linkText}
                                                        </NavLink>
                                                    ) : (
                                                        <div className="step__link step__link--next step__link--disabled"></div>
                                                    )}
                                                </div>
                                            ) : null}
                                        </React.Fragment>
                                    ) : null}
                                </React.Fragment>
                            )}
                        </div>
                        <Switch>
                            {routes.map((route, index) => {
                                if (route.alignment === 'left') {
                                    return <Route exact path={route.name} key={index} />;
                                }
                                return (
                                    <Route
                                        path={route.name}
                                        key={index}
                                        render={(props) => (
                                            <Motion
                                                defaultStyle={{ flexBasis: 0, opacity: 0 }}
                                                style={{ flexBasis: spring(imgFlexBasis, vanishConfig), opacity: spring(1) }}
                                            >
                                                {(style) => (
                                                    <div
                                                        className="content__image"
                                                        style={{
                                                            flexBasis: `${style.flexBasis}%`,
                                                            opacity: `${style.opacity}`,
                                                        }}
                                                    >
                                                        <RouteImage
                                                            {...{ props: props, imgAlt: route.imgAlt, imageUrlSource: route.imgUrlSource }}
                                                            imageUrlSource={route.imgUrlSource}
                                                        />
                                                    </div>
                                                )}
                                            </Motion>
                                        )}
                                    />
                                );
                            })}
                            <Route component={ErrorImg} />
                        </Switch>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        kyc: state.kyc,
        oidc: state.oidc,
        router: state.router,
        backDoor: state.backDoor,
        loading: state.loading,
        tasks: state.tasks,
        agreement: state.agreement,
        organisation: state.organisation,
        modal: state.modal,
        validateAccount: state.validateAccount,
        loan: state.loan,
    };
};

App = withRouter(
    connect(mapStateToProps, {
        backDoorInit,
        agreementChoose,
        modalTrigger,
        organisationUpdate,
        loadingUpdate,
        kycTrigger,
        getChosenAccount,
        agreementInit,
        createLoanApplication,
    })(App)
);

export default App;
