import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userManager } from '../store';
import { loginStatus } from '../actions';

import Spinner from './Spinner';

// Simple user handler that displays who is logged in and supports logging out
class UserHandler extends Component {
    logout(event) {
        this.props.loginStatus({ isLoading: true });
        event.preventDefault();
        userManager.signoutRedirect();
    }
    render() {
        let { loginInformation, organisation, oidc } = this.props;
        let smallVersion = typeof this.props.smallVersion !== 'undefined' && this.props.smallVersion;
        return (
            <div className={smallVersion ? 'user-handler user-handler--small' : 'user-handler'}>
                <div className="user-handler__panel">
                    <img className="user-handler__img" src={require('../img/user.svg').default} alt="siluett av en person" />
                </div>
                <div className="user-handler__panel">
                    {oidc.user ? (
                        <React.Fragment>
                            <p className="title title--h4">{oidc.user.profile.name}</p>
                        </React.Fragment>
                    ) : null}
                    {organisation.name ? (
                        <React.Fragment>
                            <p className="caption">{organisation.name}</p>
                        </React.Fragment>
                    ) : null}
                </div>
                {!smallVersion ? (
                    <div className="user-handler__panel user-handler__panel--button">
                        <button onClick={this.logout.bind(this)} className="button button--small button--cancel">
                            Logga ut
                            {!loginInformation.isLoading ? (
                                <img className="button__img" src={require('../img/logout.svg').default} alt="symbol för logga ut" />
                            ) : (
                                <Spinner />
                            )}
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        loginInformation: state.loginInformation,
        loading: state.loading,
        organisation: state.organisation,
    };
};

UserHandler = connect(mapStateToProps, {
    loginStatus,
})(UserHandler);

export default UserHandler;
