import React, { Component } from 'react';
import { connect } from 'react-redux';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import { createLoanApplication } from '../actions';
import { loginStatus } from '../actions';
import coffeeImage from '../img/fg-coffee.svg';
import { SendEventToGoogleTagMngr } from '../helpers/googleTagManager';

// Using third party bank account login we can validate bank accounts.
class LoanApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: 150,
            numberOfMonths: 12,
            installmentCostMonthly: this.toSek(12500),
            interestCostMonthly: this.toSek(3500),
            totalCost: this.toSek(16000),
        };
    }

    getInterestCost(amount) {
        let baseCost = 500;
        let totalInterestCost = baseCost + amount * 1000 * 0.02;
        return totalInterestCost;
    }

    onAmountSlide = (render, handle, value, un, percent) => {
        let newAmount = Math.round(value[0]);
        let totalInterestCost = this.getInterestCost(newAmount);

        let newInstallmentCost = (newAmount * 1000) / this.state.numberOfMonths;
        let newTotalCost = totalInterestCost + newInstallmentCost;
        this.setState({
            amount: newAmount,
            installmentCostMonthly: this.toSek(newInstallmentCost),
            interestCostMonthly: this.toSek(totalInterestCost),
            totalCost: this.toSek(newTotalCost),
        });
    };

    toSek(value) {
        return Intl.NumberFormat('sv-se', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
        }).format(value.toFixed(0));
    }

    onNumberOfMonthsSlide = (render, handle, value, un, percent) => {
        let totalInterestCost = this.getInterestCost(this.state.amount);

        let newMonths = value[0].toFixed(0);
        let newInstallmentCost = (this.state.amount * 1000) / newMonths;
        let newTotalCost = totalInterestCost + newInstallmentCost;
        this.setState({
            numberOfMonths: newMonths,
            installmentCostMonthly: this.toSek(newInstallmentCost),
            interestCostMonthly: this.toSek(totalInterestCost),
            totalCost: this.toSek(newTotalCost),
        });
    };

    createLoanApplication(event) {
        let promoCode = '';
        if (this.props.match.params && this.props.match.params.promoCode) {
            // Temporarily only allow one preset promocode
            if (this.props.match.params.promoCode === 'sommar2022') {
                promoCode = this.props.match.params.promoCode;
            }
        }

        let loanObj = {
            isLoanApplication: true,
            loanDetails: { amount: this.state.amount * 1000, numberOfMonths: this.state.numberOfMonths, promoCode: promoCode },
        };
        this.props.createLoanApplication(loanObj);
        sessionStorage.setItem('loanapplication', JSON.stringify(loanObj));
        SendEventToGoogleTagMngr('loan_request_select', { loan_amount: loanObj.loanDetails.amount, loan_time: loanObj.loanDetails.numberOfMonths });
        this.props.history.push({ pathname: '/' });
        event.preventDefault();
    }

    render() {
        return (
            <React.Fragment>
                <div className="content__body">
                    {this.props.oidc.user && this.props.organisation.organisationNumber ? (
                        <div>
                            <h1 className="title title--h1">Tack för din ansökan!</h1>
                            <p className="paragraph">
                                Vi jobbar nu för fullt med att behandla din förfrågan och återkommer med ett svar till dig inom 1 arbetsdag på de
                                kontaktuppgifter du angav tidigare.
                            </p>
                            <p className="paragraph">
                                Har du frågor eller funderingar fram till dess når du oss enklast på <a href="mailto:info@fg.se">info@fg</a> eller via telefon
                                på 031-719 99 20.
                            </p>
                            <p>Ett bekräftelsemejl är även på väg och landar alldeles strax i din inkorg.</p>
                            <p>Vi hörs snart!</p>
                            <div>
                                <img className="title__img title__img--large loan-application-coffee" src={coffeeImage} alt="Kaffe" />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <div>
                                    <h1 className="title title--h1">Boosta företaget upp till 750.000 kr</h1>
                                    <p className="paragraph">
                                        Vill du förverkliga en ny affärsidé, anställa nya medarbetare eller kanske köpa ny utrustning för att höja
                                        produktionskapaciteten i din verksamhet? Som företagare kan du ibland behöva extra likviditet för att finansiera nya
                                        affärsmöjligheter.
                                    </p>
                                    <p className="paragraph">
                                        Din ansökan är helt gratis och vi kommer inte med dolda avgifter. För att du skall kunna låna pengar av oss måste du ha
                                        ett företag som är registrerat i Sverige. Vi kommer även kräva att minst en person går i personlig borgen för lånet.
                                    </p>
                                    <p className="paragraph">
                                        För att det skall bli enkelt för er som företagare har vi istället för ränta en fast månadsavgift beroende på hur mycket
                                        och hur länge ni behöver låna pengarna. Återbetalning av lånet sker jämt fördelat över låneperioden. I vår offert får du
                                        tydligt reda på vilken månadsavgift som gäller för ditt företag.
                                    </p>

                                    <div className="content__checklist">
                                        <ul>
                                            <li>Ansökan är inte bindande</li>
                                            <li>Snabb utbetalning</li>
                                            <li>Tydliga avgifter</li>
                                        </ul>
                                    </div>

                                    <div className="input">
                                        <label className="input__label input-range slider-label-bold">
                                            Hur mycket?
                                            <span className="loan__selectedvalue slider-label-bold">{this.state.amount} 000 kr</span>
                                        </label>
                                        <Nouislider
                                            className="loan-slider"
                                            range={{ min: 25, max: 750 }}
                                            start={150}
                                            connect="lower"
                                            accessibility
                                            step={25}
                                            onSlide={this.onAmountSlide}
                                        />
                                    </div>
                                    <div className="input">
                                        <label className="input__label input-range slider-label-bold">
                                            Hur länge?
                                            <span className="loan__selectedvalue slider-label-bold">{this.state.numberOfMonths} månader</span>
                                        </label>
                                        <Nouislider
                                            className="loan-slider"
                                            range={{ min: 1, max: 18 }}
                                            start={12}
                                            connect="lower"
                                            accessibility
                                            step={1}
                                            onSlide={this.onNumberOfMonthsSlide}
                                        />
                                    </div>
                                </div>
                            </div>

                            <button className="button" onClick={this.createLoanApplication.bind(this)}>
                                Fortsätt
                            </button>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        loan: state.loan,
        organisation: state.organisation,
    };
};

LoanApplication = connect(mapStateToProps, {
    createLoanApplication,
    loginStatus,
})(LoanApplication);

export default LoanApplication;
