export const TASKS_UPDATE = 'TASKS_UPDATE';
export const TASKS_REFRESH = 'TASKS_REFRESH';
export const TASKS_POLL = 'TASKS_POLL';
export const TASKS_POLL_STOP = 'TASKS_POLL_STOP';

export const tasksUpdate = (tasks) => ({
    type: TASKS_UPDATE,
    payload: tasks,
});

export const tasksRefresh = (tasks) => ({
    type: TASKS_REFRESH,
});

export const tasksPollStart = (id, type) => ({
    type: TASKS_POLL,
    payload: {
        transactionId: id,
        transactionType: type,
    },
});
export const tasksPollStop = (tasks) => ({
    type: TASKS_POLL_STOP,
});
