import { MODAL_TRIGGER } from '../actions/index';

const initialState = {
    isOpen: false,
    dynamicOffer: {
        payload: {},
    },
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case MODAL_TRIGGER:
            return {
                ...state,
                isOpen: action.payload.isOpen,
                dynamicOffer: { ...action.payload },
                component: action.payload.component,
                persist: action.payload.persist,
            };
        default:
            return state;
    }
}
