import { of, concat, interval } from 'rxjs';
import { catchError, switchMap, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { isAndroid, isIOS } from 'react-device-detect';

import { userManager } from '../store';

import {
    LOGIN_TRIGGER,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN_POLL,
    LOGIN_POLL_STOP,
    LOGIN_CANCEL,
    loginStatus,
    loginSuccess,
    loginFailure,
    loginPoll,
    loginPollStop,
} from '../actions';

const getRedirectUrl = () => {
    let bankIdRedirectUrl = 'null';

    var userAgent = navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);
    let isWebView = navigator.userAgent.includes('wv');
    if (isWebView || (ios && !safari)) {
        if (isIOS) {
            bankIdRedirectUrl = process.env.REACT_APP_BANKID_MYPAGES_IOS_APP_REDIRECT_URL;
        } else if (isAndroid) {
            bankIdRedirectUrl = process.env.REACT_APP_BANKID_MYPAGES_ANDROID_APP_REDIRECT_URL;
        }
    } else {
        if (isAndroid) {
            bankIdRedirectUrl = 'null';
        }
    }

    return bankIdRedirectUrl;
};

const endpoint = process.env.REACT_APP_API_URL;

// en is not currently used, but i felt need to include it for the future when/if a language picker is included.
const BANK_ID_INIT_ERROR_STRINGS = {
    sv: {
        ALREADYINPROGRESS: 'Det finns redan en pågående inloggning, avbryt nuvarande inloggning och försök igen',
        CANCELED: 'Den pågående inloggningen har blivit avbruten. Försök att logga in igen',
        UNKNOWNUSER: 'Ingen användare med det personnumret kunde hittas',
        INTERNALERROR: 'Ett fel uppstod när inloggningen skulle startas. Försök igen',
    },
    en: {
        ALREADYINPROGRESS: 'An on-going login already exists, try to cancel the current login and try to log in again',
        CANCELED: 'The current login has been canceled. Try to login again',
        UNKNOWNUSER: 'No user with that person number could be found',
        INTERNALERROR: 'An error occurred when the login was initiated. Please try again',
    },
};

export const loginEpic = (action$) =>
    action$.ofType(LOGIN_TRIGGER).pipe(
        mergeMap((action) => {
            let ssn = action.payload.ssn;
            let bankIdOnThisDevice = action.payload.bankIdOnThisDevice;
            // Tells user that connection is in progress and displays message if something went wrong
            return concat(
                of(
                    loginStatus({
                        status: {
                            message: 'Ansluter till BankID',
                        },
                        isLoggedIn: false,
                        isLoading: true,
                        user: null,
                        error: null,
                    })
                ),
                ajax
                    .post(
                        `${endpoint}api/authentication/Initiate`,
                        {
                            id: ssn,
                        },
                        { 'Content-Type': 'application/json' }
                    )
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            if (data.errorCode) {
                                return loginFailure({
                                    status: {
                                        message: BANK_ID_INIT_ERROR_STRINGS.sv[data.errorCode],
                                    },
                                    isLoggedIn: false,
                                    isLoading: false,
                                    user: null,
                                    error: null,
                                });
                            }
                            let transactionId = data.response.transactionId;
                            let transactionType = data.response.transactionType;
                            let autoStartToken = data.response.autoStartToken;
                            if (bankIdOnThisDevice) {
                                let url = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${encodeURI(getRedirectUrl())}`;
                                window.location.href = url;
                            }

                            return loginPoll(transactionId, transactionType);
                        }),
                        catchError((error) => {
                            return of(
                                loginFailure({
                                    status: {
                                        message: BANK_ID_INIT_ERROR_STRINGS.sv['INTERNALERROR'],
                                    },
                                    isLoggedIn: false,
                                    isLoading: false,
                                    user: null,
                                    error: null,
                                })
                            );
                        })
                    )
            );
        })
    );

// en is not currently used, but i felt need to include it for the future when/if a language picker is included.
const BANK_ID_STRINGS = {
    sv: {
        OUTSTANDING_TRANSACTION: 'Starta BankID-appen',
        USER_SIGN: 'Skriv in din säkerhetskod i BankIDappen och välj Legitimera eller Skriv under',
        NO_CLIENT: 'Starta BankID-appen',
        STARTED: 'Söker efter BankID, det kan ta en liten stund…',
        COMPLETE: 'Loggar in...',
        USER_CANCEL: 'Åtgärden avbruten',
    },
    en: {
        OUTSTANDING_TRANSACTION: 'Start your BankID app',
        USER_SIGN: 'Enter your security code in the BankID app and select Identify or Sign.',
        NO_CLIENT: 'Start your BankID app.',
        STARTED: 'Searching for BankID:s, it may take a little while…',
        COMPLETE: 'Logging in...',
        USER_CANCEL: 'Action cancelled',
    },
};

export const loginPollEpic = (action$) =>
    action$.ofType(LOGIN_POLL).pipe(
        mergeMap((d) => {
            let transactionId = d.payload.transactionId;
            let transactionType = d.payload.transactionType;
            // Creates an interval that triggers each 2 seconds
            return interval(2000).pipe(
                takeUntil(action$.ofType(LOGIN_POLL_STOP)),
                switchMap((d) =>
                    ajax
                        .post(
                            `${endpoint}api/authentication/Poll`,
                            {
                                transactionId: transactionId,
                                transactionType: transactionType,
                            },
                            { 'Content-Type': 'application/json' }
                        )
                        .pipe(
                            map((data) => data.response),
                            mergeMap((d) => {
                                let status;
                                switch (d.response.progressStatus) {
                                    case 'COMPLETE':
                                        status = {
                                            status: {
                                                message: BANK_ID_STRINGS.sv[d.response.progressStatus],
                                            },
                                            isLoggedIn: true,
                                            isLoading: false,
                                            user: {},
                                            error: null,
                                        };
                                        return concat(of(loginPollStop(transactionId, transactionType)), of(loginSuccess(transactionId)));
                                    case 'NO_CLIENT':
                                    case 'USER_CANCEL':
                                    case 'FAILED':
                                        status = {
                                            status: {
                                                message: BANK_ID_STRINGS.sv[d.response.progressStatus],
                                            },
                                            isLoggedIn: false,
                                            isLoading: false,
                                            user: null,
                                            error: null,
                                        };
                                        return concat(of(loginPollStop(transactionId, transactionType)), of(loginFailure(status)));
                                    case 'OUTSTANDING_TRANSACTION':
                                    case 'STARTED':
                                    case 'USER_SIGN':
                                    default:
                                        status = {
                                            status: {
                                                message: BANK_ID_STRINGS.sv[d.response.progressStatus] || 'Ansluter till BankID',
                                            },
                                            isLoggedIn: false,
                                            isLoading: true,
                                            user: null,
                                            error: null,
                                        };
                                        return of(loginStatus(status));
                                }
                            }),
                            catchError((error) => {
                                let status = {
                                    status: {
                                        message: 'Kunde inte ansluta till BankID',
                                    },
                                    isLoggedIn: false,
                                    isLoading: false,
                                    user: null,
                                    error: true,
                                };
                                return concat(of(loginPollStop(transactionId, transactionType)), of(loginFailure(status)));
                            })
                        )
                )
            );
        })
    );

export const loginFailEpic = (action$) =>
    action$.ofType(LOGIN_FAIL).pipe(
        map((d) => {
            return loginStatus(d.payload);
        })
    );

export const loginSuccessEpic = (action$) =>
    action$.ofType(LOGIN_SUCCESS).pipe(
        switchMap((d) => {
            userManager.signinRedirect({
                extraQueryParams: {
                    //your params go here
                    tid: d.payload,
                },
            });
            return of(
                loginStatus({
                    status: {
                        message: BANK_ID_STRINGS.sv['COMPLETE'],
                    },
                    isLoading: true,
                })
            );
        })
    );

export const loginCancelEpic = (action$) =>
    action$.ofType(LOGIN_CANCEL).pipe(
        mergeMap((action) => {
            let transactionId = action.payload.transactionId;
            let transactionType = action.payload.transactionType;

            return concat(
                of(
                    loginStatus({
                        isLoggedIn: false,
                        isLoading: true,
                        status: {
                            message: 'Avbryter inloggning',
                        },
                        user: null,
                        error: null,
                    })
                ),
                ajax
                    .post(
                        `${endpoint}api/authentication/CancelLogin`,
                        {
                            transactionId: transactionId,
                            transactionType: transactionType,
                        },
                        { 'Content-Type': 'application/json' }
                    )
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        switchMap((data) => {
                            let status = {
                                status: {
                                    message: 'Den pågående inloggningen har blivit avbruten. Försök att logga in igen',
                                },
                                isLoggedIn: false,
                                isLoading: false,
                                user: null,
                                error: null,
                                transactionId: null,
                                transactionType: null,
                                canceled: true,
                            };
                            return concat(of(loginPollStop(transactionId, transactionType)), of(loginStatus(status)));
                        }),
                        catchError((error) => {
                            return of(
                                loginFailure({
                                    status: {
                                        message: BANK_ID_INIT_ERROR_STRINGS.sv['INTERNALERROR'],
                                    },
                                    isLoggedIn: false,
                                    isLoading: false,
                                    user: null,
                                    error: null,
                                })
                            );
                        })
                    )
            );
        })
    );
