import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExitView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSending: false,
        };
    }

    render() {
        let { organisation } = this.props;

        return (
            <div className="content__body">
                <h1 className="title title--h1">Tack för ert intresse</h1>
                <p className="paragraph">Vi kommer att kontakta er så snart vi kan så att du kan få igång ditt konto.</p>
                <p>Du kan nu stänga detta fönster.</p>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        organisation: state.organisation,
        offer: state.offer,
        loading: state.loading,
    };
};

ExitView = connect(mapStateToProps, {})(ExitView);

export default ExitView;
