import { ORGANISATION_UPDATE, ORGANISATION_GET_COMMITMENTS } from '../actions/index';

const initialState = {
    name: '',
    organisationNumber: '',
    list: [],
    showContactInfoModal: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case ORGANISATION_UPDATE:
        case ORGANISATION_GET_COMMITMENTS:
            return { ...state, ...action.payload };
        default:
            return { ...state };
    }
}
