export const KYC_FETCH = 'KYC_FETCH';
export const KYC_INIT = 'KYC_INIT';
export const KYC_UPDATE = 'KYC_UPDATE';
export const KYC_FETCH_FAIL = 'KYC_FETCH_FAIL';
export const KYC_PERSON_ADD = 'KYC_PERSON_ADD';
export const KYC_PERSON_REMOVE = 'KYC_PERSON_REMOVE';
export const KYC_PERSON_UPDATE = 'KYC_PERSON_UPDATE';
export const KYC_CHIPS_CHOOSE = 'KYC_CHIPS_CHOOSE';
export const KYC_CHIPS_DESELECT = 'KYC_CHIPS_DESELECT';
export const KYC_COMPLETE = 'KYC_COMPLETE';

export const kycTrigger = () => ({
    type: KYC_FETCH,
});

export const kycInit = (kyc) => ({
    type: KYC_INIT,
    payload: kyc,
});

export const kycUpdate = (kyc) => ({
    type: KYC_UPDATE,
    payload: kyc,
});

export const kycComplete = (kyc) => ({
    type: KYC_COMPLETE,
    payload: kyc,
});

export const kycFailure = (kyc, errorMessage) => ({
    type: KYC_FETCH_FAIL,
    payload: {
        kyc: kyc,
        error: errorMessage,
    },
});

export const kycPersonAdd = (parentObj) => ({
    type: KYC_PERSON_ADD,
    payload: parentObj,
});

export const kycPersonRemove = (parentObj, index) => ({
    type: KYC_PERSON_REMOVE,
    payload: {
        inputObj: parentObj,
        index: index,
    },
});

export const kycPersonUpdate = (inputObj, person, personIndex) => ({
    type: KYC_PERSON_UPDATE,
    payload: {
        inputObj: inputObj,
        person: person,
        personIndex: personIndex,
    },
});

export const kycChooseChips = (inputObj, option) => ({
    type: KYC_CHIPS_CHOOSE,
    payload: {
        inputObj: inputObj,
        option: option,
    },
});

export const kycDeselectChips = (inputObj, option) => ({
    type: KYC_CHIPS_DESELECT,
    payload: {
        inputObj: inputObj,
        option: option,
    },
});
