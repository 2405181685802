import {
    AGREEMENT_UPDATE,
    AGREEMENT_INIT_ERROR,
    AGREEMENT_CHOOSE,
    AGREEMENT_SIGN_STATUS,
    AGREEMENT_SIGN_FAIL,
    AGREEMENT_POLL_STOP,
    AGREEMENT_UPDATE_SIGNATURES,
} from '../actions/index';

const initialState = {
    chosenAgreement: {},
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case AGREEMENT_UPDATE_SIGNATURES:
            state.agreements.map((a) => {
                if (a.agreementId === action.payload.agreementId) {
                    a.signatureOptions = { ...a.signatureOptions, ...action.payload.signOptions };
                }
                return a;
            });
            state.chosenAgreement.signatureOptions = { ...state.chosenAgreement.signatureOptions, ...action.payload.signOptions };

            return { ...state };
        case AGREEMENT_UPDATE:
            return { ...state, ...action.payload };
        case AGREEMENT_CHOOSE:
            state.chosenAgreement = { ...action.payload };
            state.status = null;
            return {
                ...state,
            };
        case AGREEMENT_INIT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case AGREEMENT_SIGN_STATUS:
            return {
                ...state,
                status: { ...action.payload.status },
            };
        case AGREEMENT_SIGN_FAIL:
            return {
                ...state,
                status: { ...action.payload.status },
            };
        case AGREEMENT_POLL_STOP:
            return {
                ...state,
                status: { ...action.payload.status.status },
            };
        default:
            return { ...state };
    }
}
