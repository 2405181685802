import {
    VALIDATE_ACCOUNT_INIT,
    VALIDATE_ACCOUNT_STATUS,
    VALIDATE_ACCOUNT_ERROR,
    VALIDATE_ACCOUNT_POLL_STOP,
    VALIDATE_ACCOUNT_CHOOSE_ACCOUNT,
} from '../actions';

const initialState = {
    sessionId: '',
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case VALIDATE_ACCOUNT_INIT:
            return {
                ...state,
                sessionId: action.payload,
                error: null,
            };
        case VALIDATE_ACCOUNT_STATUS:
            return { ...state, ...action.payload };
        case VALIDATE_ACCOUNT_POLL_STOP:
            return {
                ...state,
                isLoading: false,
            };
        case VALIDATE_ACCOUNT_ERROR:
            return {
                ...state,
                ...action.payload,
            };
        case VALIDATE_ACCOUNT_CHOOSE_ACCOUNT:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return { ...state };
    }
}
