export const FETCH_PENDING_CUSTOMER_BANK_DETAILS = 'FETCH_PENDING_CUSTOMER_BANK_DETAILS';
export const SAVE_PENDING_CUSTOMER_BANK_DETAILS = 'SAVE_PENDING_CUSTOMER_BANK_DETAILS';
export const CUSTOMER_BANK_DETAILS = 'CUSTOMER_BANK_DETAILS';
export const CUSTOMER_BANK_DETAILS_SAVED = 'CUSTOMER_BANK_DETAILS_SAVED';

export const fetchPendingCustomerBankDetails = (request) => ({
    type: FETCH_PENDING_CUSTOMER_BANK_DETAILS,
    payload: request,
});

export const updatePendingCustomerBankDetails = (customerBankDetails) => ({
    type: CUSTOMER_BANK_DETAILS,
    payload: customerBankDetails,
});

export const savePendingCustomerBankDetails = (pendingCustomerBankDetails) => ({
    type: SAVE_PENDING_CUSTOMER_BANK_DETAILS,
    payload: pendingCustomerBankDetails,
});

export const pendingCustomerBankDetailsSaved = (response) => ({
    type: CUSTOMER_BANK_DETAILS_SAVED,
    payload: response,
});
