import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import loginReducer from './login';
import kycReducer from './kyc';
import backDoorReducer from './backDoor';
import organisationReducer from './organisation';
import loadingReducer from './loading';
import tasksReducer from './tasks';
import agreementReducer from './agreement';
import modalReducer from './modal';
import validateAccountReducer from './validate-account';
import loanReducer from './loan';
import offerReducer from './offer';
import bankDetails from './bankDetails';
export const rootReducer = (routerReducer) =>
    combineReducers({
        agreement: agreementReducer,
        backDoor: backDoorReducer,
        kyc: kycReducer,
        loading: loadingReducer,
        loginInformation: loginReducer,
        modal: modalReducer,
        oidc: oidcReducer,
        organisation: organisationReducer,
        router: routerReducer,
        tasks: tasksReducer,
        validateAccount: validateAccountReducer,
        loan: loanReducer,
        offer: offerReducer,
        bankDetails: bankDetails,
    });
