import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { routes } from '../RouteSettings';
import { tasksRefresh, backDoorRefresh } from '../actions';
import Faq from './Faq';
import Spinner from './Spinner';
import coffeeImage from '../img/fg-coffee.svg';
import checkImage from '../img/check.svg';
import warningImage from '../img/warning.svg';
import { BankIdLogin } from '@factoringgruppen/fg.ui.components';
import { loginSuccess } from '../actions';
import { isIosWebView } from '../helpers/mobile';
// The startpage shows all the tasks a company has assigned to them, and also shows FAQs.
class StartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankIdEndPoints: {
                initiateEndPoint: {
                    url: process.env.REACT_APP_BANKID_INIT_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
                pollEndPoint: {
                    url: process.env.REACT_APP_BANKID_POLL_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
                generateRollingQrCode: {
                    url: process.env.REACT_APP_BANKID_GENERATE_ROLLING_QR_CODE_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
            },
            redirectUrl: isIosWebView() ? process.env.REACT_APP_BANKID_MYPAGES_IOS_APP_REDIRECT_URL : process.env.REACT_APP_BANKID_REDIRECT_URL,
        };
        if (!props.tasks.isLoading && props.oidc.user && !props.loan.isLoanApplication) {
            props.tasksRefresh();
        }

        let ssData = JSON.parse(sessionStorage.getItem('bidInfo'));
        if (ssData && ssData.bid) {
            this.props.backDoorRefresh();
        }
    }

    render() {
        let { tasks } = this.props;
        let tasksWithRouteInformation = [];
        let taskAllDone = true;
        // Find out if all tasks are done and join information about routes and tasks
        routes.map((route) => {
            tasks.tasks.map((task) => {
                taskAllDone = taskAllDone && task.completed;
                if (task.type === route.taskType) {
                    // Join task and route information
                    tasksWithRouteInformation.push({ route: { ...route }, task: { ...task } });
                }
                return task;
            });
            return route;
        });
        return (
            <div className="content__body">
                {this.props.loan.isLoanApplication ? (
                    <div>
                        <h1 className="title title--h1">Tack för din ansökan!</h1>
                        <p className="paragraph">
                            Puhh! Nu är det mesta klart. Nu kan du luta dig tillbaka med en go kopp kaffe så tar vi över. Vi kommer att återkomma med ett
                            erbjudande till dig inom 48 timmar på de kontaktuppgifter du angav tidigare.
                        </p>
                        <div>
                            <img className="title__img title__img--large loan-application-coffee" src={coffeeImage} alt="Kaffe" />
                        </div>
                    </div>
                ) : (
                    <div className="content__body">
                        <div>
                            <h1 className="title title--h1">
                                Registrering till Factoringgruppen
                                {tasks.isLoading ? (
                                    <div className="content__spinner content__spinner--inline">
                                        <Spinner />
                                    </div>
                                ) : null}
                            </h1>
                            {tasks.type === 0 ? (
                                <p className="paragraph">
                                    För att skapa likviditet i världsklass har du bara några steg kvar. Först behöver vi lära känna dig bättre, sedan signerar
                                    du ditt avtal med oss och till sist bekräftar du till vilket konto pengarna ska utbetalas till.
                                </p>
                            ) : null}
                            {tasks.type === 1 ? (
                                <p className="paragraph">
                                    För att skapa likviditet i världsklass har du bara några steg kvar. Först behöver vi lära känna dig bättre, sedan signerar
                                    du ditt avtal med oss och till sist bekräftar du till vilket konto pengarna ska utbetalas till.
                                </p>
                            ) : null}
                            <div className="tasks">
                                {taskAllDone && this.props.loan.isLoanApplication ? (
                                    <div className="tasks__item tasks__item--confirmation">
                                        <div className="tasks__item-content">
                                            <img src={checkImage} alt="En bock" className="confirmation-icon" />
                                            <h3 className="title title--h3">Tack, nu är allt klart!</h3>
                                            <p className="paragraph">
                                                Nu ska vi bara granska uppgifterna, sen har du tillgång till smidig och flexibel finansiering, när du behöver
                                                det! Vi meddelar dig på den e-postadress du angivit, så snart tjänsten är aktiverad för ditt företag. I
                                                normalfallet sker detta inom en arbetsdag.
                                            </p>
                                            <p className="paragraph">Vi ser fram emot ett gott framtida samarbete!</p>
                                            {tasks.callbackUrl && tasks.callbackLinkText ? (
                                                <a className="button button--secondary button--small" href={tasks.callbackUrl}>
                                                    {tasks.callbackLinkText}
                                                </a>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                                {tasksWithRouteInformation.map((taskRouter, index) => (
                                    <NavLink to={taskRouter.route.name} key={index} className="tasks__item">
                                        <React.Fragment>
                                            <div
                                                className={(() => {
                                                    if (taskRouter.task.completed) {
                                                        return 'tasks__item-img tasks__item-img--done';
                                                    }
                                                    if (taskRouter.task.needsManualHandling) {
                                                        return 'tasks__item-img tasks__item-img--warning';
                                                    }

                                                    return 'tasks__item-img';
                                                })()}
                                            >
                                                {taskRouter.task.completed ? (
                                                    <img src={checkImage} alt="En bock" className="confirmation-icon" />
                                                ) : taskRouter.task.needsManualHandling ? (
                                                    <img className="warning-icon" src={warningImage} alt="En kvadrat med ett utropstecken inuti" />
                                                ) : null}
                                                <img className="tasks__img" src={taskRouter.route.thumbUrlSource} alt={taskRouter.route.imgAlt} />
                                            </div>
                                            <div className="tasks__item-content">
                                                <h3 className="title title--h3">
                                                    {index + 1 + '. '} {taskRouter.route.linkText}
                                                </h3>
                                                {(() => {
                                                    switch (taskRouter.task.type) {
                                                        case 'BankAccountValidation':
                                                            return <p className="paragraph">Bekräfta ditt konto för utbetalning.</p>;
                                                        case 'AgreementSigning':
                                                            return <p className="paragraph">Här signerar du avtalet enkelt med hjälp av BankID.</p>;
                                                        case 'Kyc':
                                                            return <p className="paragraph">Här får du svara på några frågor om ditt företag.</p>;
                                                        default:
                                                            return null;
                                                    }
                                                })()}
                                                <img className="tasks__item-arrow" src={require('../img/right.svg').default} alt="pil som pekar åt höger" />
                                            </div>
                                        </React.Fragment>
                                    </NavLink>
                                ))}
                            </div>
                            <Faq type={tasks.type} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tasks: state.tasks,
        oidc: state.oidc,
        loan: state.loan,
    };
};

StartPage = connect(mapStateToProps, {
    tasksRefresh,
    backDoorRefresh,
    loginSuccess,
})(StartPage);

export default StartPage;
