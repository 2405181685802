import { of, concat, interval, empty } from 'rxjs';
import { map, switchMap, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { isAndroid } from 'react-device-detect';

import {
    AGREEMENT_INIT,
    AGREEMENT_SIGN_INITIATE,
    AGREEMENT_SIGN_POLL,
    AGREEMENT_SIGN_POLL_SIGNATURE_CHANGES,
    AGREEMENT_CHOOSE,
    AGREEMENT_POLL_STOP,
    AGREEMENT_MARK_AS_DONE,
    agreementUpdate,
    agreementInitError,
    agreementPoll,
    agreementPollStop,
    agreementSignSuccess,
    agreementSignFail,
    agreementSignStatus,
    agreementUpdateSignatures,
    loadingUpdate,
} from '../actions';

const getRedirectUrl = () => {
    let bankIdRedirectUrl = window.location.href;

    var userAgent = navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);
    let isWebView = navigator.userAgent.includes('wv');
    if (isWebView || (ios && !safari)) {
        return bankIdRedirectUrl;
    } else {
        if (isAndroid) {
            bankIdRedirectUrl = 'null';
        }
    }

    return bankIdRedirectUrl;
};

const endpoint = process.env.REACT_APP_API_URL;

export const agreementInitEpic = (action$, store) =>
    action$.ofType(AGREEMENT_INIT).pipe(
        map((action) => action.payload),
        switchMap((payload) => {
            let asyncCall;
            // Fetch agreements using bid or access token
            if (payload.invitationId) {
                if (!payload.bundleId) {
                    return of(agreementUpdate({ isLoading: false, agreements: [] }));
                }
                asyncCall = ajax
                    .get(`${endpoint}api/agreement/getAgreements/${payload.bundleId}/${payload.invitationId}/${payload.organisationNumber}`, {
                        'Content-Type': 'application/json',
                        invitationToken: `${payload.invitationToken}`,
                    })
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        switchMap((result) => {
                            return of(agreementUpdate({ isLoading: false, ...result }));
                        }),
                        catchError((error) => {
                            return concat(of(agreementInitError(error)), of(loadingUpdate({ isLoading: false })));
                        })
                    );
            } else {
                asyncCall = ajax
                    .post(
                        `${endpoint}api/agreement/getAgreements`,
                        {
                            type: 'InvoicePurchase',
                            Id: payload.bundleId,
                        },
                        {
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': payload.organisationNumber,
                        }
                    )
                    .pipe(
                        map((result) => result.response),
                        switchMap((result) => {
                            return of(agreementUpdate({ isLoading: false, ...result }));
                        }),
                        catchError((error) => {
                            return concat(of(agreementInitError(error)), of(loadingUpdate({ isLoading: false })));
                        })
                    );
            }
            return concat(of(loadingUpdate({ isLoading: true })), of(agreementUpdate({ isLoading: true })), asyncCall, of(loadingUpdate({ isLoading: false })));
        })
    );

export const agreementSignEpic = (action$, store) =>
    action$.ofType(AGREEMENT_SIGN_INITIATE).pipe(
        mergeMap((action) => {
            let bankIdOnThisDevice = action.payload.shouldOpenLocal;
            let status = {
                status: {
                    message: 'Kontaktar BankID',
                    userHasSigned: false,
                    isLoading: true,
                },
                error: null,
            };
            // Before we trigger sign process, let's check if user already has signed
            if (store.value.agreement.chosenAgreement.signatureOptions.persons) {
                store.value.agreement.chosenAgreement.signatureOptions.persons.map((person) => {
                    let personSsn = person.socialSecurityNumber;
                    if (personSsn === action.payload.ssn.replace('-', '').replace('+', '')) {
                        // This ssn has already signed this agreement. Create error message.
                        status = {
                            status: {
                                message: `Du signerade detta avtalet ${Intl.DateTimeFormat('se-sv').format(new Date(person.signedAt))}`,
                                userHasSigned: true,
                                isLoading: false,
                            },
                            error: null,
                        };
                    }
                    return person;
                });
            }

            if (status.status.userHasSigned) {
                // User has already signed, send error message
                return of(agreementSignStatus(status));
            }
            // ssn passed tests, go on with sign process
            return concat(
                of(agreementSignStatus(status)),
                ajax
                    .post(
                        `${endpoint}api/agreement/InitiateSignAgreement`,
                        {
                            agreementId: action.payload.agreement.agreementId,
                            agreementSetId: store.value.agreement.id,
                            socialSecurityNumber: action.payload.ssn,
                            organisationNumber: store.value.organisation.organisationNumber,
                        },
                        { 'Content-Type': 'application/json' }
                    )
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            if (data.errorMessage) {
                                let status = {
                                    status: {
                                        message: 'Kunde inte kontakta BankID. Vänligen försök igen.',
                                    },
                                    error: null,
                                };
                                return agreementSignFail(status);
                            }
                            let transactionId = data.response.transactionId;
                            let transactionType = data.response.transactionType;
                            let autoStartToken = data.response.autoStartToken;
                            if (bankIdOnThisDevice) {
                                let url = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${encodeURI(getRedirectUrl())}`;
                                window.location.href = url;
                            }

                            return agreementPoll(transactionId, transactionType);
                        })
                    )
            );
        })
    );

// en is not currently used, but i felt need to include it for the future when/if a language picker is included.
const BANK_ID_STRINGS = {
    sv: {
        OUTSTANDING_TRANSACTION: 'Starta BankID-appen',
        USER_SIGN: 'Skriv in din säkerhetskod i BankIDappen och välj Legitimera eller Skriv under',
        NO_CLIENT: `BankID-appen svarar inte.
            Kontrollera att den är startad och att
            du har internetanslutning. Om du
            inte har något giltigt BankID kan du
            hämta ett hos din Bank. Försök
            sedan igen.`,
        STARTED: 'Söker efter BankID, det kan ta en liten stund…',
        COMPLETE: `Avtalet signerades. Det kan ta upp till en minut innan din signatur syns på den här sidan. 
            Skriv in nästa personnummer eller välj att gå tillbaka.`,
        USER_CANCEL: 'Åtgärden avbruten',
    },
    en: {
        OUTSTANDING_TRANSACTION: 'Start your BankID app',
        USER_SIGN: 'Enter your security code in the BankID app and select Identify or Sign.',
        NO_CLIENT: `The BankID app is not responding. Please
            check that the program is started and that
            you have internet access. If you don’t have a
            valid BankID you can get one from your
            bank. Try again.`,
        STARTED: 'Searching for BankID:s, it may take a little while…',
        COMPLETE: 'The agreement was successfully signed. It can take up to one minute before your signature is displayed on this page.',
        USER_CANCEL: 'Action cancelled',
    },
};

export const agreementSignPollEpic = (action$, store) =>
    action$.ofType(AGREEMENT_SIGN_POLL).pipe(
        mergeMap((d) => {
            let transactionId = d.payload.transactionId;
            let transactionType = d.payload.transactionType;
            let { tasks } = store.value;
            let taskId;
            tasks.tasks.map((task) => {
                if (task.type === 'AgreementSigning') {
                    taskId = task.id;
                }

                return task;
            });
            // poll each 2s with progress for signature update
            return interval(2000).pipe(
                takeUntil(action$.ofType(AGREEMENT_POLL_STOP)),
                switchMap((d) =>
                    ajax
                        .post(
                            `${endpoint}api/agreement/PollSignAgreement`,
                            {
                                transactionId: transactionId,
                                transactionType: transactionType,
                                agreementId: store.value.agreement.chosenAgreement.agreementId,
                                agreementSetId: store.value.agreement.id,
                                companyTaskId: taskId,
                            },
                            { 'Content-Type': 'application/json' }
                        )
                        .pipe(
                            map((data) => data.response),
                            mergeMap((d) => {
                                // Display correct message to the user
                                let status;
                                switch (d.response.progressStatus) {
                                    case 'COMPLETE':
                                        status = {
                                            status: {
                                                message: BANK_ID_STRINGS.sv[d.response.progressStatus],
                                                isLoading: false,
                                                userHasSigned: true,
                                            },
                                            error: null,
                                        };
                                        return concat(of(agreementPollStop(transactionId, transactionType, status)), of(agreementSignSuccess(transactionId)));
                                    case 'NO_CLIENT':
                                    case 'USER_CANCEL':
                                        status = {
                                            status: {
                                                message: BANK_ID_STRINGS.sv[d.response.progressStatus],
                                                isLoading: false,
                                                userHasSigned: false,
                                            },
                                            error: null,
                                        };
                                        return concat(of(agreementPollStop(transactionId, transactionType, status)), of(agreementSignFail(status)));
                                    case 'OUTSTANDING_TRANSACTION':
                                    case 'STARTED':
                                    case 'USER_SIGN':
                                    default:
                                        status = {
                                            status: {
                                                message: BANK_ID_STRINGS.sv[d.response.progressStatus],
                                                isLoading: true,
                                                userHasSigned: false,
                                            },
                                            error: null,
                                        };
                                        return of(agreementSignStatus(status));
                                }
                            }),
                            catchError((error) => {
                                let status = {
                                    status: {
                                        message: 'Kunde inte signera avtal.',
                                        isLoading: false,
                                        userHasSigned: false,
                                    },
                                    error: error,
                                };
                                return of(agreementSignFail(status));
                            })
                        )
                )
            );
        })
    );

export const agreementSignPollSignatureChangesEpic = (action$, store) =>
    action$.ofType(AGREEMENT_SIGN_POLL_SIGNATURE_CHANGES).pipe(
        mergeMap((d) => {
            let agreementId = store.value.agreement.chosenAgreement.agreementId;
            let agreementSetId = store.value.agreement.id;
            let organisationNumber = store.value.organisation.organisationNumber;
            // Polls overall changes in signatures.
            // Let's someone signs the same agreement on another computer/mobile. That signature needs to be shown everywhere.
            return interval(2000).pipe(
                takeUntil(action$.ofType(AGREEMENT_CHOOSE)),
                switchMap((d) =>
                    ajax
                        .get(`${endpoint}api/agreement/signaturestatus/${agreementSetId}/${agreementId}`, {
                            'Content-Type': 'application/json',
                            'organisation-number': organisationNumber,
                        })
                        .pipe(
                            map((request) => request.response),
                            mergeMap((d) => {
                                if (
                                    JSON.stringify({ ...store.value.agreement.chosenAgreement.signatureOptions, ...d }) ===
                                    JSON.stringify(store.value.agreement.chosenAgreement.signatureOptions)
                                ) {
                                    return empty();
                                }
                                return of(agreementUpdateSignatures(agreementId, d));
                            })
                        )
                )
            );
        })
    );

// this is used for agreements that require that users tell us when they're done signing
export const agreementMarkAsDoneEpic = (action$, store) =>
    action$.ofType(AGREEMENT_MARK_AS_DONE).pipe(
        mergeMap((d) => {
            let agreementId = d.payload || store.value.agreement.chosenAgreement.agreementId;
            let agreementSetId = store.value.agreement.id;
            let organisationNumber = store.value.organisation.organisationNumber;
            let hasSignatures =
                store.value.agreement.chosenAgreement.signatureOptions.persons && store.value.agreement.chosenAgreement.signatureOptions.persons.length > 0;
            let { tasks } = store.value;
            let taskId;
            let agreementIndex = store.value.agreement.agreements.findIndex((agreement) => agreement.agreementId === agreementId);

            tasks.tasks.map((task) => {
                if (task.type === 'AgreementSigning') {
                    taskId = task.id;
                }

                return task;
            });
            let url = hasSignatures ? `${endpoint}api/agreement/markAgreementAsFullySigned` : `${endpoint}api/agreement/markAgreementAsOptOut`;

            let asyncCall = ajax
                .post(
                    url,
                    {
                        agreementSetId: agreementSetId,
                        agreementId: agreementId,
                        companyTaskId: taskId,
                    },
                    {
                        'Content-Type': 'application/json',
                        'organisation-number': organisationNumber,
                    }
                )
                .pipe(
                    map((request) => request.response),
                    mergeMap((d) => {
                        store.value.agreement.agreements[agreementIndex].isLoading = false;
                        store.value.agreement.chosenAgreement.isLoading = false;
                        return of(agreementUpdate(store.value.agreement));
                    }),
                    catchError((error) => {
                        return empty();
                    })
                );
            store.value.agreement.agreements[agreementIndex].isLoading = true;
            store.value.agreement.chosenAgreement.isLoading = true;
            return concat(of(agreementUpdate(store.value.agreement)), asyncCall);
        })
    );
