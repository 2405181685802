import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Motion, spring } from 'react-motion';
import { SendEventToGoogleTagMngr } from '../../helpers/googleTagManager';

import { organisationChooseCommitment, organisationAddContactDetails, saveLoanApplication, modalTrigger, bookHelpFromSupport } from '../../actions';
import Spinner from '../Spinner';

class AddContactInformation extends Component {
    constructor(props) {
        super(props);

        this.name = React.createRef();
        this.email = React.createRef();
        this.phone = React.createRef();
        this.promotionalCode = React.createRef();
        this.state = {
            name: '',
            email: '',
            phone: '',
            promotionalCode: props.loan.isLoanApplication ? props.loan.loanDetails.promoCode : '',
            loanDetails: props.loan ? props.loan.loanDetails : null,
        };
    }
    handleChange(name, event) {
        let propName = name;
        let input = event.target;
        this.setState((state) => {
            state[propName] = input.value;

            return state;
        });
        this.validateInput(event);
    }
    validateInput(event) {
        let inputValue = event.target.value;
        switch (event.target.id) {
            case 'name':
                this.setState((state) => {
                    state.nameIsValid = inputValue && inputValue.length > 0;
                    return state;
                });
                break;
            case 'email':
                let regex =
                    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.setState((state) => {
                    state.emailIsValid = inputValue && regex.test(inputValue);
                    return state;
                });
                break;
            case 'phone':
                event.target.value = inputValue.replace(/\s/g, '');
                inputValue = event.target.value;
                this.setState((state) => {
                    state.phoneIsValid = inputValue && /^[0-9]*$/.test(inputValue);
                    return state;
                });
                break;
            default:
                break;
        }
    }
    inputsAreValid() {
        return this.state.nameIsValid && this.state.emailIsValid && this.state.phoneIsValid;
    }
    saveResult(event) {
        event.preventDefault();

        this.name.current.querySelector('input').dispatchEvent(new Event('blur'));
        this.email.current.querySelector('input').dispatchEvent(new Event('blur'));
        this.phone.current.querySelector('input').dispatchEvent(new Event('blur'));

        if (this.inputsAreValid()) {
            const contactName = this.name.current.querySelector('input').value;
            const contactEmailAddress = this.email.current.querySelector('input').value;
            const contactPhoneNumber = this.phone.current.querySelector('input').value;
            const promotionalCode = this.promotionalCode.current.querySelector('input').value;
            if (this.props.loan.isLoanApplication) {
                this.setState(
                    (state) => {
                        state.loanDetails.contactName = contactName;
                        state.loanDetails.contactEmailAddress = contactEmailAddress;
                        state.loanDetails.contactPhoneNumber = contactPhoneNumber;
                        state.loanDetails.promotionalCode = promotionalCode;
                        return state;
                    },
                    () => {
                        SendEventToGoogleTagMngr('loan_request_submit', {
                            loan_amount: this.state.loanDetails.amount,
                            loan_time: this.state.loanDetails.numberOfMonths,
                            organisation: this.props.organisation.name,
                            organisationNumber: this.props.organisation.organisationNumber,
                        });

                        this.props.saveLoanApplication({
                            loanDetails: this.state.loanDetails,
                        });
                    }
                );
            } else if (this.props.onboarding && this.props.onboarding.bookHelp) {
                SendEventToGoogleTagMngr('onboarding_contacted_by_salesman', {
                    companyName: this.props.organisation.name,
                    organisationNumber: this.props.organisation.organisationNumber,
                });
                this.props.bookHelpFromSupport(contactName, contactEmailAddress, contactPhoneNumber, this.props.offer.turnoverMonth);
            } else {
                this.props.organisationAddContactDetails(contactName, contactEmailAddress, contactPhoneNumber, promotionalCode);
            }
        }
    }

    closeModal(event) {
        event.preventDefault();
        this.props.modalTrigger(false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.loan.success) {
            this.props.modalTrigger(false);
        }
    }

    render() {
        let { organisation } = this.props;
        let inputClasses = (name) => {
            let className = 'input';
            if (typeof this.state[name] !== 'undefined' && !this.state[name]) {
                className += ' input--error';
            }
            return className;
        };
        return (
            <div className="contacts-info">
                <form className="form form--dark" autoComplete="off">
                    {this.props.onboarding && this.props.onboarding.bookHelp ? (
                        <h2 className="titles title--h2">Personlig Service</h2>
                    ) : (
                        <h2 className="titles title--h2">Innan vi går vidare...</h2>
                    )}

                    {organisation.addcontactDetailsLoading ? (
                        <div className="commitment-picker__inline-spinner">
                            <Spinner />
                        </div>
                    ) : null}
                    {this.props.onboarding && this.props.onboarding.bookHelp ? (
                        <p className="paragraphs">
                            Vill du prata med oss om hur mycket du vill sälja eller har andra frågor? Fyll i dina kontaktuppgifter nedan så står våra experter
                            redo att hjälpa dig.
                        </p>
                    ) : (
                        <p className="paragraphs">Fyll i dina kontaktuppgifter.</p>
                    )}

                    <div className={inputClasses('nameIsValid')} ref={this.name}>
                        <label className="input__label" htmlFor="name">
                            Kontaktperson
                        </label>
                        <input
                            autoComplete="off"
                            value={this.state.name}
                            onInput={this.handleChange.bind(this, 'name')}
                            onBlur={this.validateInput.bind(this)}
                            id="name"
                            type="text"
                            placeholder="För- och efternamn"
                            className="input__field"
                        />
                        {typeof this.state.nameIsValid !== 'undefined' && !this.state.nameIsValid ? (
                            <div className="input__required-information input__required-information--narrow">Fältet är obligatoriskt</div>
                        ) : null}
                    </div>
                    <div className={inputClasses('emailIsValid')} ref={this.email}>
                        <label className="input__label" htmlFor="name">
                            Email
                        </label>
                        <input
                            autoComplete="off"
                            value={this.state.email}
                            onInput={this.handleChange.bind(this, 'email')}
                            onBlur={this.validateInput.bind(this)}
                            id="email"
                            type="email"
                            placeholder="mail@exempel.se"
                            className="input__field"
                        />
                        {typeof this.state.emailIsValid !== 'undefined' && !this.state.emailIsValid ? (
                            <div className="input__required-information input__required-information--narrow">Ange en giltig email-adress</div>
                        ) : null}
                    </div>
                    <div className={inputClasses('phoneIsValid')} ref={this.phone}>
                        <label className="input__label" htmlFor="name">
                            Telefonnummer inkl. riktnummer
                        </label>
                        <input
                            autoComplete="off"
                            value={this.state.phone}
                            onInput={this.handleChange.bind(this, 'phone')}
                            onBlur={this.validateInput.bind(this)}
                            id="phone"
                            type="tel"
                            placeholder="Telefonnummer"
                            className="input__field"
                        />
                        {typeof this.state.phoneIsValid !== 'undefined' && !this.state.phoneIsValid ? (
                            <div className="input__required-information input__required-information--narrow">Ange enbart numeriska värden</div>
                        ) : null}
                    </div>
                    <div className={inputClasses('promotionalCodeIsValid')} ref={this.promotionalCode}>
                        <label className="input__label" htmlFor="name">
                            Om du har fått en <strong>kod</strong> ange den här
                        </label>
                        <input
                            autoComplete="off"
                            value={this.state.promotionalCode}
                            onChange={this.handleChange.bind(this, 'promotionalCode')}
                            id="promotionalCode"
                            type="promotionalCode"
                            placeholder=""
                            className="input__field"
                        />
                    </div>
                    {organisation.error && organisation.error.type === 'ADDCONTACTERROR' ? (
                        <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                            {(value) => (
                                <div
                                    className="form__async-status form__async-status--error"
                                    style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                >
                                    {organisation.error.message}
                                </div>
                            )}
                        </Motion>
                    ) : null}
                    {!this.props.loan.success && this.props.loan.status ? (
                        <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                            {(value) => (
                                <div
                                    className="form__async-status form__async-status--error"
                                    style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                >
                                    {this.props.loan.status}
                                </div>
                            )}
                        </Motion>
                    ) : null}

                    {this.props.onboarding && this.props.onboarding.bookHelp ? (
                        <div className="contacts-info-buttons">
                            <button
                                className="buttons button button--action button--left"
                                disabled={this.props.organisation.addcontactDetailsLoading}
                                onClick={this.saveResult.bind(this)}
                            >
                                Skicka
                            </button>
                            <button
                                onClick={this.closeModal.bind(this)}
                                disabled={this.props.organisation.addcontactDetailsLoading}
                                className="buttons button button--right button--action"
                            >
                                Avbryt
                            </button>
                        </div>
                    ) : (
                        <button
                            className="buttons button button--full button--action"
                            disabled={this.props.organisation.addcontactDetailsLoading}
                            onClick={this.saveResult.bind(this)}
                        >
                            Skicka {this.props.organisation.addcontactDetailsLoading}
                        </button>
                    )}
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.modal.dynamicOffer.payload,
        organisation: state.organisation,
        oidc: state.oidc,
        loan: state.loan,
        offer: state.offer,
    };
};

AddContactInformation = connect(mapStateToProps, {
    organisationChooseCommitment,
    organisationAddContactDetails,
    saveLoanApplication,
    modalTrigger,
    bookHelpFromSupport,
})(AddContactInformation);

export default AddContactInformation;
