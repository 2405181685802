import React, { Component } from 'react';
import { connect } from 'react-redux';

// this is a recursive component to display KYC answers for
const RecKyc = (props) => {
    const { input } = props;
    return (
        <React.Fragment>
            <React.Fragment>
                <p className="title title--h4">{input.question}</p>
                {input.answer
                    ? input.answer.map((a, i) => {
                          switch (input.meta.model.type) {
                              case 'Person':
                                  return (
                                      <div className="kyc__person-summary" key={i}>
                                          <p className="paragraph">
                                              <strong>Förnamn: </strong> {a.person.firstName}
                                          </p>
                                          <p className="paragraph">
                                              <strong>Efternamn: </strong> {a.person.lastName}
                                          </p>
                                          <p className="paragraph">
                                              <strong>Personnummer: </strong> {a.person.socialSecurityNumber}
                                          </p>
                                          <p className="paragraph">
                                              <strong>Procentandel: </strong> {a.person.ownershipPercentage}
                                          </p>
                                          <p className="paragraph">
                                              <strong>Hemland: </strong> {a.person.country}
                                          </p>
                                      </div>
                                  );
                              default:
                                  return (
                                      <p key={i} className="kyc__label">
                                          {a.text}
                                      </p>
                                  );
                          }
                      })
                    : null}
            </React.Fragment>
            {input.meta.options && input.meta.options.length > 0
                ? input.meta.options.map((option) => {
                      let answerIds = [];
                      if (input.answer && input.answer.length > 0) {
                          input.answer.map((answer) => {
                              answerIds.push(answer.id);
                              return answer;
                          });
                          answerIds.push();
                      }
                      if (option.questions) {
                          return option.questions.map((q, i) => {
                              let showFollowUp = false;
                              if (answerIds.indexOf(option.id) > -1 && !showFollowUp) {
                                  showFollowUp = true;
                              }
                              return option.isChosen || showFollowUp ? <RecKyc key={i} input={q} /> : null;
                          });
                      } else {
                          return null;
                      }
                  })
                : null}
        </React.Fragment>
    );
};

class Comfirmation extends Component {
    constructor(props) {
        super(props);
        this.termsView = React.createRef();
        this.scrollEl = React.createRef();
        this.state = {
            isKycExpanded: false,
            isTermsExpanded: false,
        };
    }

    componentDidMount() {
        this.scrollEl.current.closest('.switch-wrapper').scrollTop = 0;
        this.scrollEl.current.closest('.content').scrollTop = 0;

        if (this.termsView.current) {
            let el = this.termsView.current.querySelector('.terms-conditions__content');
            // Check if a scroller is needed
            let needsScroll = el.scrollHeight > el.offsetHeight ? true : false;
            if (!needsScroll) {
                this.termsView.current.classList.add('terms-conditions--small');
            }
        }
    }

    expandKycView(event) {
        event.preventDefault();
        this.setState((state) => {
            state.isKycExpanded = !state.isKycExpanded;
            return state;
        });
    }

    expandAgreementView(event) {
        event.preventDefault();
        this.setState((state) => {
            state.isTermsExpanded = !state.isTermsExpanded;
            return state;
        });
    }

    shouldComponentUpdate(nextProps) {
        if (JSON.stringify(this.props.agreement.chosenAgreement) === JSON.stringify(nextProps.agreement.chosenAgreement)) {
            return false;
        }
        return true;
    }

    render() {
        let { chosenAgreement } = this.props.agreement;
        let text = {
            __html: '',
        };
        let summary = {
            __html: '',
        };

        let tokens = [];
        const recFindTokens = (chosenAgreement) => {
            tokens = [...tokens, ...chosenAgreement.tokens];
            if (chosenAgreement.agreements && chosenAgreement.agreements.length > 0) {
                chosenAgreement.agreements.map((a) => {
                    recFindTokens(a);
                    return a;
                });
            }
        };

        const recRenderHtml = (chosenAgreement) => {
            if (chosenAgreement.content.text) {
                text.__html = `${text.__html + chosenAgreement.content.text}`;
            }
            if (chosenAgreement.content.summary) {
                summary.__html = `${summary.__html + chosenAgreement.content.summary}`;
            }
            if (chosenAgreement.agreements && chosenAgreement.agreements.length > 0) {
                chosenAgreement.agreements.map((a) => {
                    recRenderHtml(a);
                    return a;
                });
            }
        };
        if (this.props.agreement.chosenAgreement) {
            // If there is a chosen agreement, find HTML from that agreement and find all tokens in order to display them properly.
            recRenderHtml(this.props.agreement.chosenAgreement);
            recFindTokens(this.props.agreement.chosenAgreement);
        }

        if (this.props.agreement.chosenAgreement) {
            recRenderHtml(this.props.agreement.chosenAgreement);
        }

        return (
            <React.Fragment>
                <div className="content__section" ref={this.scrollEl}>
                    <img src={require('../img/check.svg').default} alt="En bock" className="confirmation-icon" />
                    <h1 className="title title--h1">Avtalet är signerat och klart!</h1>
                    <p className="paragraph">
                        Du kan när som helst gå tillbaka hit för att se ditt avtal. Om du vill ha en kopia på avtalet kan du höra av dig till oss så skickar vi
                        en kopia till er. Notera att du måste göra klart hela registreringen för att komma igång med tjänsten.
                    </p>
                    {chosenAgreement.signatureOptions.persons && chosenAgreement.signatureOptions.persons.length > 0 ? (
                        <div className="signature">
                            <div className="signature__panel signature__panel--full">
                                <h3 className="title title--h3">Signerande parter</h3>
                                <table className="table">
                                    <thead className="table__head">
                                        <tr className="table__row">
                                            <th className="table__cell">Person</th>
                                            <th className="table__cell">Signeringsdatum</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chosenAgreement.signatureOptions.persons.map((person, index) => {
                                            return (
                                                <tr className="table__row" key={index}>
                                                    <td className="table__cell">
                                                        {person.name} <br />
                                                        {person.functions && person.functions.length ? (
                                                            <span className="caption">{person.functions.join(', ')}</span>
                                                        ) : null}
                                                    </td>
                                                    <td className="table__cell">
                                                        {person.signedAt ? Intl.DateTimeFormat('se-sv').format(new Date(person.signedAt)) : null}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="signature">
                            <div className="signature__panel signature__panel--full">
                                <h3 className="title title--h3">Signaturer saknas</h3>
                                <p className="paragraph">Ingen har signerat avtalet.</p>
                            </div>
                        </div>
                    )}
                    {tokens.filter((token) => token.visible).length > 0 ? (
                        <div className="offer">
                            <ul className="offer__list">
                                {tokens.map((token, index) => {
                                    if (token.visible) {
                                        return (
                                            <li className="offer__item" key={index}>
                                                <p className="caption">{token.label}</p>
                                                <p className="title title--h3">{token.value}</p>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                            </ul>
                        </div>
                    ) : null}
                    <div className={this.state.isTermsExpanded ? 'terms-conditions terms-conditions--expanded' : 'terms-conditions'} ref={this.termsView}>
                        <div className="terms-conditions__content">{text.__html ? <div dangerouslySetInnerHTML={text}></div> : null}</div>
                        <div className="terms-conditions__expand">
                            <button className="button button--small" onClick={this.expandAgreementView.bind(this)}>
                                {this.state.isTermsExpanded ? 'Minimera' : 'Expandera'}
                            </button>
                        </div>
                    </div>
                    {summary.__html ? (
                        <div>
                            <div dangerouslySetInnerHTML={summary}></div>
                        </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginInformation: state.loginInformation,
        oidc: state.oidc,
        router: state.router,
        backDoor: state.backDoor,
        agreement: state.agreement,
        tasks: state.tasks,
        organisation: state.organisation,
    };
};

Comfirmation = connect(mapStateToProps)(Comfirmation);

export default Comfirmation;
