import { fromEvent, empty, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MODAL_TRIGGER, modalTrigger } from '../actions';

export const modalEpic = (action$, store) =>
    action$.ofType(MODAL_TRIGGER).pipe(
        switchMap(() => {
            return fromEvent(document, 'keydown').pipe(
                switchMap((event) => {
                    if (!store.value.modal.persist && store.value.modal.isOpen && event.keyCode === 27 && event.target === document.body) {
                        return of(modalTrigger(false));
                    } else {
                        return empty();
                    }
                })
            );
        })
    );
