import React, { Component } from 'react';
import { connect } from 'react-redux';
import { kycChooseChips, kycDeselectChips } from '../actions';

import KycSelectMultiple from './KycSelectMultiple';

// Checkbox for KYC
class KycCheckbox extends Component {
    constructor(props) {
        super(props);

        let { inputObj } = props;
        let state = {
            isFreeText: false,
            inputObj: inputObj,
        };

        inputObj.meta.options.map((d) => {
            if (d.isFreeText) {
                state.isFreeText = true;
            }
            return d;
        });

        this.state = state;

        inputObj.meta.options.map((d) => (d.isChosen = false));
        // Dont manipulate options if it's a freetext option. Let KycSelectMutliple element handle it
        if (!this.state.isFreeText && inputObj.answer && inputObj.answer.length > 0) {
            for (let answer of inputObj.answer) {
                if (inputObj.meta && inputObj.meta.options) {
                    inputObj.meta.options = inputObj.meta.options.map((d) => {
                        if (d.id === answer.id) {
                            // Is match in answers is found, change to chosen
                            d.isChosen = true;
                        } else if (!d.isChosen) {
                            // Make sure there is a value
                            d.isChosen = false;
                        }
                        return d;
                    });
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isValid !== nextProps.inputObj.isValid) {
            this.setState((state) => {
                state.isValid = nextProps.inputObj.isValid;
                return state;
            });
        }
    }

    validateSelections() {
        if (this.state.inputObj.meta.model.validation.mandatory && this.state.inputObj.answer && this.state.inputObj.answer.length === 0) {
            this.setState((state) => {
                state.isValid = false;
                return state;
            });
        } else if (this.state.inputObj.meta.model.validation.mandatory && this.state.inputObj.answer && this.state.inputObj.answer.length > 0) {
            this.setState((state) => {
                state.isValid = true;
                return state;
            });
        }
    }

    handleSelction(option, index, event) {
        let { inputObj } = this.state;
        let isChosen = event.target.checked;
        option.isChosen = isChosen;
        this.setState(
            (state) => {
                state.inputObj.meta.options[index].isChosen = isChosen;
                return state;
            },
            () => {
                this.validateSelections();
            }
        );
        if (isChosen) {
            this.props.kycChooseChips(inputObj, option);
        } else {
            this.props.kycDeselectChips(inputObj, option);
        }
    }

    render() {
        let { inputObj, isValid } = this.state;
        let { kyc } = this.props;
        let inputClasses = () => {
            let className = 'input input--checkbox';
            if (typeof isValid !== 'undefined' && !isValid) {
                className += ' input--error';
            }
            if (kyc.completed) {
                className += ' input--disabled';
            }
            return className;
        };
        if (this.state.isFreeText) {
            return <KycSelectMultiple inputObj={inputObj}></KycSelectMultiple>;
        }
        return (
            <div className="kyc__radio">
                <div className="form">
                    <p className="kyc__label">{inputObj.question}</p>
                    {inputObj.meta.options.map((a, index) => {
                        return (
                            <div className={inputClasses()} key={a.id}>
                                <label htmlFor={a.id}>
                                    <input
                                        disabled={kyc.completed}
                                        value={a.isChosen}
                                        checked={a.isChosen}
                                        onChange={this.handleSelction.bind(this, a, index)}
                                        id={a.id}
                                        name={a.parentQuestionId}
                                        type="checkbox"
                                        className="input__checkbox"
                                    />
                                    <span className="input__box" />
                                    <span className="input__check" />
                                    <div className="input__label">{a.text}</div>
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        kyc: state.kyc,
    };
};

KycCheckbox = connect(mapStateToProps, {
    kycChooseChips,
    kycDeselectChips,
})(KycCheckbox);

export default KycCheckbox;
