import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fromEvent } from 'rxjs';

import { kycChooseChips, kycDeselectChips, kycInit } from '../actions';

// A component that uses autocomplete suggestions and allows the user to choose one or more suggestions.
// Sort of like a input chip picker from Material Design.
class KycSelectMultiple extends Component {
    constructor(props) {
        super(props);

        let { inputObj } = props;
        let state = {
            isFreeText: false,
            searchMatches: [],
            searchValue: '',
        };

        this.chipsWrapper = React.createRef();
        this.autocompleteWrapper = React.createRef();
        this.chosenChipsWrapper = React.createRef();
        this.inputField = React.createRef();
        this.rootEl = React.createRef();

        inputObj.meta.options.map((d) => {
            if (d.isFreeText) {
                state.isFreeText = true;
                state.freeTextField = d;
                d.text = '';
            }
            state.inputObj = inputObj;
            return d;
        });
        this.state = state;
    }

    componentDidUpdate(prevProps) {
        if (this.state.isValid !== this.props.inputObj.isValid) {
            this.setState((state) => {
                state.isValid = this.props.inputObj.isValid;
                return state;
            });
        }
    }

    componentWillUnmount() {
        if (this.clickSub) {
            this.clickSub.unsubscribe();
        }
    }

    handleChipClick(inputObj, option, event) {
        event.preventDefault();
        if (this.props.kyc.completed) {
            return;
        }
        if (option.isFreeText) {
            option.backgroundColor = window.getComputedStyle(event.target).backgroundColor;
            if (option.text) {
                option.text = option.text + ', ' + this.state.searchValue;
            } else {
                option.text = this.state.searchValue;
            }
        }
        this.props.kycChooseChips(inputObj, option);
        this.setState((state) => {
            state.searchValue = '';
            state.searchMatches = [];
            return state;
        });
        this.inputField.current.focus();
    }

    handleChipRemove(selectedValue, option, event) {
        event.preventDefault();
        if (this.props.kyc.completed) {
            return;
        }
        if (option.isFreeText) {
            option.text = '';
            this.setState((state) => {
                state.freeTextField.text = '';

                return state;
            });
            selectedValue.text = '';
        }

        this.props.kycDeselectChips(selectedValue, option);
        this.inputField.current.focus();
        this.validateAnswers();
    }

    validateAnswers() {
        if (this.state.inputObj.meta.model.validation.mandatory && this.state.inputObj.answer && this.state.inputObj.answer.length === 0) {
            this.setState((state) => {
                state.isValid = false;
                return state;
            });
        } else if (this.state.inputObj.meta.model.validation.mandatory && this.state.inputObj.answer.length > 0) {
            this.setState((state) => {
                state.isValid = true;
                return state;
            });
        }
    }

    handleInputKeyDown(event) {
        switch (event.keyCode) {
            case 8:
                // Backspace
                let { inputObj } = this.props;
                if (event.target.tagName.toLowerCase() === 'button') {
                    // Removes the last chosen chip
                    event.target.click();
                    return;
                }

                if (inputObj.answer && inputObj.answer.length > 0 && !this.state.searchValue) {
                    let nodeList = this.chosenChipsWrapper.current.querySelectorAll('.button');
                    let lastButton = nodeList[nodeList.length - 1];
                    lastButton.focus();
                }
                break;
            case 13:
                // Enter
                event.preventDefault();
                const isOnlyMatch = this.state.searchMatches && this.state.searchMatches.length === 1;
                const isFreeText = this.state.searchValue && this.state.searchValue.length > 3;

                if (isOnlyMatch || isFreeText) {
                    // Adds chosen chip
                    this.autocompleteWrapper.current.querySelector('.button').click();
                }
                break;
            default:
                break;
        }
    }

    handleFreeTextChange(event) {
        let { inputObj } = this.props;
        // Clear matches before finding new ones and update search value
        this.setState({
            searchMatches: [],
            searchValue: event.target.value,
        });
        // Search is whitespace or empty, don't do anything
        if (!event.target.value.trim()) {
            return;
        }
        // Find matches that aren't free text, or currently chosen
        let matches = inputObj.meta.options.filter((option) => {
            if (!option.isChosen && !option.isFreeText && option.text.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) {
                return option;
            }
            return false;
        });

        this.setState({
            searchMatches: matches,
        });
    }

    componentDidMount() {
        let node = this.chipsWrapper.current;
        if (node) {
            for (let button of node.getElementsByTagName('button')) {
                // Set background colors on objects in order to display correct colors when/if an answer is chosen
                let optionIndex = button.dataset['optionIndex'];
                let option = this.props.inputObj.meta.options[optionIndex];
                option.backgroundColor = window.getComputedStyle(button).backgroundColor;

                // Find and set proper background color on existing answer fields as well
                if (this.props.inputObj.answer && this.props.inputObj.answer.length > 0) {
                    this.props.inputObj.answer.map((a) => {
                        if (a.id === option.id) {
                            a.backgroundColor = option.backgroundColor;
                            option.isChosen = true; // Hide preexisting answer
                        }
                        return a;
                    });
                }
            }
            // Force update of kyc set
            this.props.kycInit(this.props.kyc);
        }
    }

    handleFocus(event) {
        event.preventDefault();
        this.clickObs = fromEvent(document, 'click');
        this.clickSub = this.clickObs.subscribe((e) => {
            if (this.rootEl.current && !this.rootEl.current.contains(e.target)) {
                this.clickSub.unsubscribe();
                if (this.autocompleteWrapper.current) {
                    let buttons = this.autocompleteWrapper.current.querySelectorAll('.button--chip');
                    if (buttons.length === 1) {
                        buttons[0].click();
                    }
                }
            }
        });
    }

    render() {
        let { inputObj } = this.props;
        let { isValid } = inputObj;
        let { kyc } = this.props;
        let inputClasses = () => {
            let className = 'input';
            if (typeof isValid !== 'undefined' && !isValid) {
                className += ' input--error';
            }
            if (kyc.completed) {
                className += ' input--disabled';
            }
            return className;
        };
        return (
            <div className="kyc__free-text" ref={this.rootEl}>
                <div className="form">
                    <p className="kyc__label">{inputObj.question}</p>
                    <div className={inputClasses()}>
                        <div className="input__field input__field--chip" ref={this.chosenChipsWrapper}>
                            {inputObj.answer
                                ? inputObj.answer.map((d, i) => {
                                      return (
                                          <button
                                              disabled={kyc.completed}
                                              type="button"
                                              onKeyDown={this.handleInputKeyDown.bind(this)}
                                              onClick={this.handleChipRemove.bind(this, inputObj, d)}
                                              style={{ backgroundColor: d.backgroundColor }}
                                              className="button button--chip button--active"
                                              key={i}
                                          >
                                              {d.text}
                                          </button>
                                      );
                                  })
                                : null}
                            <input
                                disabled={kyc.completed}
                                onBlur={this.validateAnswers.bind(this)}
                                onFocus={this.handleFocus.bind(this)}
                                ref={this.inputField}
                                value={this.state.searchValue}
                                onKeyDown={this.handleInputKeyDown.bind(this)}
                                onChange={this.handleFreeTextChange.bind(this)}
                                className="input__field input__field--invisible"
                                name="freeText"
                                placeholder="Fritext"
                            />
                        </div>
                        {this.state.searchMatches.length > 0 || (this.state.searchValue && this.state.searchValue.length > 3) ? (
                            <div className="input__autocomplete" ref={this.autocompleteWrapper}>
                                <ul className="input__suggestion-list">
                                    {this.state.searchMatches && this.state.searchMatches.length > 0 ? (
                                        <li className="input__suggestion-item">
                                            <span className="input__suggestion-text">Menar du </span>
                                            {this.state.searchMatches.map((match, i) => {
                                                return (
                                                    <button
                                                        type="button"
                                                        key={i}
                                                        onClick={this.handleChipClick.bind(this, inputObj, match)}
                                                        style={{ backgroundColor: match.backgroundColor }}
                                                        className="button button--chip"
                                                    >
                                                        {match.text}
                                                    </button>
                                                );
                                            })}
                                            <span className="input__suggestion-text">?</span>
                                        </li>
                                    ) : null}
                                    {!this.state.isFreeText && this.state.searchMatches.length === 0 ? (
                                        <li className="input__suggestion-item">
                                            <span className="input__suggestion-text">Ingen matchning hittades</span>
                                        </li>
                                    ) : null}
                                    {this.state.isFreeText && this.state.searchValue && this.state.searchValue.length > 3 ? (
                                        <li className="input__suggestion-item">
                                            <span className="input__suggestion-text">Lägg till</span>
                                            <button
                                                type="button"
                                                onClick={this.handleChipClick.bind(this, inputObj, this.state.freeTextField)}
                                                className="button button--chip button--chip-default"
                                            >
                                                {this.state.freeTextField && this.state.freeTextField.text
                                                    ? `${this.state.freeTextField.text}, ${this.state.searchValue}`
                                                    : this.state.searchValue}
                                            </button>
                                        </li>
                                    ) : null}
                                </ul>
                            </div>
                        ) : null}
                    </div>
                    {inputObj.meta.options.filter((option) => !option.isFreeText && !option.isChosen).length > 0 ? (
                        <div className="kyc__suggestion-header">Förslag</div>
                    ) : null}
                    <div ref={this.chipsWrapper}>
                        {inputObj.meta.options.map((option, index) => {
                            return option.isFreeText || option.isChosen ? null : (
                                <button
                                    type="button"
                                    disabled={kyc.completed}
                                    onClick={this.handleChipClick.bind(this, inputObj, option)}
                                    style={{ backgroundColor: option.backgroundColor ? option.backgroundColor : null }}
                                    className="button button--chip"
                                    data-option-index={index}
                                    key={index}
                                >
                                    {option.text}
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        kyc: state.kyc,
    };
};

KycSelectMultiple = connect(mapStateToProps, {
    kycChooseChips,
    kycDeselectChips,
    kycInit,
})(KycSelectMultiple);

export default KycSelectMultiple;
